// import { Auth } from "pai_library/clients";
// import * as Config from "pai_library/config";
import jwt_decode from "jwt-decode";
import React from "react";
import axios from "axios";

export const getUserFromOpenIDToken = async () => { //requestor, email
  console.log("auth getUserFromOpenIDToken");
  try {
    // console.log("api", requestor);
    const apiResponse = await Promise.all([
      axios.get(
        `${process.env.REACT_APP_MICROSERVICE_HOST}/auth/GetAPILogin`,
        {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        }
      ),
      axios.get(`${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetMetaData`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      }),
    ]);
    const token = apiResponse[0];
    const metadata = apiResponse[1];
    // console.log("auth getUserFromOpenIDToken", response.data);
    return {
      user: jwt_decode(token.data.token),
      token: token.data.token,
      format: metadata.data?.status_format?.format,
      status: metadata.data?.status_format?.status,
      country:metadata.data?.country,
      currency:metadata.data?.currency, 
    };
  } catch (ex) {
    console.log(ex, window.location.href);
    return {
      error: (
        <React.Fragment>
          Your session has expired! <br />
          <a
            href={window.location.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to login
          </a>
        </React.Fragment>
      ),
    };
  }
};
export const getMetaData = async (token) => { //requestor, email
  console.log("auth getMetaData");
  try {
    // console.log("api", requestor);
    const apiResponse = await Promise.all([
      axios.get(`${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetMetaData`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY ,
        Authorization: "Bearer " + token,},
       
      }),
    ]);
    const metadata = apiResponse[0];
    console.log("auth getMetaData", metadata);
    return {
      format: metadata.data?.status_format?.format,
      status: metadata.data?.status_format?.status,
      country:metadata.data?.country,
      currency:metadata.data?.currency, 
    };
  } catch (ex) {
    console.log(ex, window.location.href);
    return {
      error: (
        <React.Fragment>
          Your session has expired! <br />
          <a
            href={window.location.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to login
          </a>
        </React.Fragment>
      ),
    };
  }
};

export const getAccount = () => {
  return {
    accountIdentifier: "d13137ec-a8a2-4d03-b600-06975ff843b8",
    homeAccountIdentifier:
      "ZDEzMTM3ZWMtYThhMi00ZDAzLWI2MDAtMDY5NzVmZjg0M2I4.ZWMzYzdkZWUtZDU1Mi00OTRiLWEzOTMtN2Y5NDFhOTBiOTg1",
    userName: "john.doe@zs.com",
    name: "John Doe",
    idToken: {
      aud: "8ec1a8f5-2c92-4c20-be43-1675094b6da4",
      iss: "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
      iat: 1608214919,
      nbf: 1608214919,
      exp: 1608218819,
      aio: "ATQAy/8SAAAASWWAhdEHvWCFZop10VSRONoy/Z/IBi5tFPR0Bd58YJZ00VFVJ5PYAduNz8Id6x+N",
      email: "john.doe@zs.com",
      name: "John Doe",
      nonce: "77663aa4-6b68-409f-b71d-d9316a15f141",
      oid: "d13137ec-a8a2-4d03-b600-06975ff843b8",
      preferred_username: "john.doe@zs.com",
      rh: "0.AAAA7n087FLVS0mjk3-UGpC5hfWowY6SLCBMvkMWdQlLbaQOAAk.",
      sub: "WV1zIi1XpdKean8CrHACwdkoyxE75Eq0DELBzOuuIGQ",
      tid: "ec3c7dee-d552-494b-a393-7f941a90b985",
      uti: "TX6vwvCZnESg7_WMqvEsAA",
      ver: "2.0",
    },
    idTokenClaims: {
      aud: "8ec1a8f5-2c92-4c20-be43-1675094b6da4",
      iss: "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
      iat: 1608214919,
      nbf: 1608214919,
      exp: 1608218819,
      aio: "ATQAy/8SAAAASWWAhdEHvWCFZop10VSRONoy/Z/IBi5tFPR0Bd58YJZ00VFVJ5PYAduNz8Id6x+N",
      email: "john.doe@zs.com",
      name: "John Doe",
      nonce: "77663aa4-6b68-409f-b71d-d9316a15f141",
      oid: "d13137ec-a8a2-4d03-b600-06975ff843b8",
      preferred_username: "john.doe@zs.com",
      rh: "0.AAAA7n087FLVS0mjk3-UGpC5hfWowY6SLCBMvkMWdQlLbaQOAAk.",
      sub: "WV1zIi1XpdKean8CrHACwdkoyxE75Eq0DELBzOuuIGQ",
      tid: "ec3c7dee-d552-494b-a393-7f941a90b985",
      uti: "TX6vwvCZnESg7_WMqvEsAA",
      ver: "2.0",
    },
    environment:
      "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
  };
};
