/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { ScatterChart, Scatter, LabelList, Tooltip } from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  TertiaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
} from "../../components";

export default ({ id, dataset, settings }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    console.log("payload-->", payload);
    if (active) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.8)",
            padding: "5px 5px 5px 5px",
            borderRadius: 8,
          }}
        >
          <div
            style={{ fontSize: "12px" }}
          >{`${payload[0].payload.tooltipText}`}</div>
          <div style={{ fontSize: "12px" }}>{`${
            axes.secondary.label
          } : ${numeral(payload[0].payload.y).format(
            axes.secondary.format
          )}`}</div>
          <div style={{ fontSize: "12px" }}>{`${axes.primary.label} : ${numeral(
            payload[0].payload.x
          ).format(axes.primary.format)}`}</div>
        </div>
      );
    }

    return null;
  };
  const { legend, plot, axes, zeroReference, colorCodingColumnName } = settings;

  const orientation = "horizontal";

  let margin = { top: 0, bottom: 0, left: -10, right: 20 };
  // console.log("id and dataset", id, dataset);

  if (!plot.series.length) {
    return "";
  }

  return (
    <Wrapper>
      {({ width, height }) => (
        <ScatterChart width={width} height={height} margin={margin}>
          {Gradients({ chartId: id, orientation })}
          {axes.primary &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: "x",
              show: axes.primary.show,
              type: "number",
              label: axes.primary.label,
              unit: axes.primary.unit,
              squeeze: false,
              format: axes.primary.format,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 0,
              orientation,
              dataKey: "y",
              show: axes.secondary.show,
              type: "number",
              label: axes.secondary.label,
              unit: axes.secondary.unit,
              squeeze: false,
              format: axes.secondary.format,
            })}
          {axes.tertiary &&
            TertiaryAxis({
              index: 1,
              orientation,
              dataKey: "z",
              show: axes.tertiary.show,
              type: "number",
              label: axes.tertiary.label,
              range: [5, 50],
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => plot.series[index].label,
          })}
          <Tooltip
            cursor={{ stroke: "#616161", strokeDasharray: "3 3" }}
            content={<CustomTooltip />}
          />
          {zeroReference && ZeroLine({ orientation })}
          {/* {console.log('scatter', dataset.data,colorCodingColumnName)} */}
          {plot &&
            plot.series &&
            plot.series.map((series) => {
              const xAxisId = 0;
              const yAxisId = series.axis;
              const data = dataset.data.map((d) => ({
                x: d[dataset.fields[axes.primary.dim]],
                y: d[dataset.fields[series.dim]],
                z: 10,
                tooltipText: (d[dataset.fields[series.labelDim]] || "")
                  .toLocaleString()
                  .replace(/ /g, "\u00a0"),

                label: !series.hideLabelText
                  ? (d[dataset.fields[series.labelDim]] || "")
                      .toLocaleString()
                      .replace(/ /g, "\u00a0")
                  : "",
                fill: colorCodingColumnName
                  ? d[colorCodingColumnName] === 0
                    ? "rgba(255,0,0,0.7)"
                    : `${Color[series.color.value]}`
                  : `${Color[series.color.value]}`,
              }));
              return (
                <Scatter
                  name={series.name}
                  data={data}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  fill={`${Color[series.color.value]}`}
                >
                  <LabelList
                    dataKey={"label"}
                    position={series.labelPosition || "right"}
                  />
                </Scatter>
              );
            })}
          {plot.colorCodeLabels && (
            <g>
              {/* red label */}
              <text
                x="100%"
                y="0"
                style={{ fill: "rgba(255,0,0,0.85)", fontSize: "4em" }}
                dominantBaseline="top"
                textAnchor="end"
              >
                <tspan dx="-165" dy="13">
                  {/* display circle */}.
                </tspan>
              </text>
              <text
                x="100%"
                y="0"
                style={{ fill: "rgba(255,0,0,0.85)", fontSize: "13px" }} //Color[0]
                dominantBaseline="middle"
                textAnchor="end"
              >
                <tspan dx="-10" dy="10">
                  {plot.colorCodeLabels[0]}
                </tspan>
              </text>

              {/* blue lable */}
              <text
                x="100%"
                y="0"
                style={{ fill: Color[2], fontSize: "4em" }}
                dominantBaseline="top"
                textAnchor="end"
              >
                <tspan dx="-165" dy="32">
                  {/* display circle */}.
                </tspan>
              </text>
              <text
                x="100%"
                y="0"
                style={{ fill: Color[2], fontSize: "13px" }}
                dominantBaseline="middle"
                textAnchor="end"
              >
                <tspan dx="-17" dy="30">
                  {plot.colorCodeLabels[1]}
                </tspan>
              </text>
            </g>
          )}
        </ScatterChart>
      )}
    </Wrapper>
  );
};
