import React from "react";
// import ZsCard from '../../assets/generic-ant-d-components/zs-card.js';
import { ZsButton } from '../../assets/generic-ant-d-components/quick-access-ant-d';
import { useHistory, /* useLocation */ } from 'react-router-dom';

const ErrorFallback = ({ error, /* resetErrorBoundary */ }) => {
    console.log('ErrorFallback', error);

    const history = useHistory();
    // const location = useLocation();
    const redirectToHome = () => {
        // redirect to home page
        history.push('/');
    }

    const reload = () => {
        // reload current page
        // history.push(location.pathname)
        history.go(0)
    }

    return (
        <div role="alert"
            style={{
                textAlign: 'center'
            }}
        >
            {/* 
            Image ref : https://www.flaticon.com/free-icon/warning_159469
            */}
            <div className="flex items-end w-full justify-center h-screen/2">
                <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/error/error_monitor.png"} width="180" />
            </div>
            <br />
            {/* <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN+"}/images/error/warning_in_Triangle.png`} width="125" /> */}
            <div className="flex text-base w-full justify-center h-3vh text-white-50">
                <p>We are sorry! Something went wrong while accessing this page</p>
            </div>
            <div className="flex text-xs text-white-50 w-full justify-center height-5vh">
                <p>We request you to try after some time or contact support for help</p>
            </div>
            {/* <span>Error : <pre>{error.message}</pre></span> */}
            <ZsButton
                onClick={reload/* resetErrorBoundary */}
                type="primary"
            >Try Again</ZsButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ZsButton
                onClick={redirectToHome}
                type="primary"
            >Go to Home</ZsButton>
        </div>
    )
};

export default ErrorFallback;