import React from "react";
import numeral from "numeral";
import { XAxis, YAxis, ZAxis } from "recharts";

const CustomizedAxisTick = ({ format }) => ({
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill="#666">
          {payload.value &&
            payload.value
              .toString()
              .split(",")
              .map((t, i) => (
                <tspan key={i} textAnchor="middle" x="0" dy={10}>
                  {format ? numeral(t).format(format)?.toUpperCase() : t}
                </tspan>
              ))}
        </text>
      </g>
    );
  },
});

const CustomizedAxisTick2 = ({ format }) => ({
  render() {
    const { x, y, payload } = this.props;
    const translateY = payload.value
      ? (payload.value.toString().split(",").length / 2) * 11
      : 0;
    return (
      <g transform={`translate(${x},${y - translateY})`}>
        <text
          alignmentBaseline="center"
          dominantBaseline="center"
          x={0}
          y={0}
          dy={16}
          fill="#666"
        >
          {payload.value &&
            payload.value
              .toString()
              .split(",")
              .map((t, i) => (
                <tspan key={i} textAnchor="end" x="0" dy={10}>
                  {format ? numeral(t).format(format)?.toUpperCase() : t}
                </tspan>
              ))}
        </text>
      </g>
    );
  },
});

export const PrimaryAxis = ({
  index,
  orientation,
  dataKey,
  show,
  type,
  label,
  unit,
  squeeze,
  format,
  equalize,
  width,
  tickCount,
  padding,
}) => {
  console.log("eq", equalize);
  if (orientation === "horizontal") {
    return (
      <XAxis
        tickCount={tickCount}
        width={width}
        tick={<CustomizedAxisTick format={format} />}
        // tick={false}
        tickFormatter={(val) =>
          (format && numeral(val).format(format)?.toUpperCase()) || val
        }
        domain={[
          squeeze
            ? (dataMin) => dataMin - 0.1 * dataMin
            : equalize
            ? Array.isArray(equalize)
              ? (/*dataMin*/) => equalize[0]
              : (/*dataMin*/) => -equalize
            : "auto",
          squeeze
            ? (dataMax) => dataMax + 0.1 * dataMax
            : equalize
            ? Array.isArray(equalize)
              ? (/*dataMin*/) => equalize[1]
              : (/*dataMin*/) => equalize
            : "auto",
        ]}
        type={type || "category"}
        interval={tickCount ? undefined : 0}
        dataKey={dataKey}
        xAxisId={index}
        hide={!show}
        label={{ value: label, position: "insideBottomRight" }}
        padding={padding ? { left: 35, right: 35 } : 0}
        unit={unit}
      />
    );
  }
  return (
    <YAxis
      tickCount={tickCount}
      width={width}
      tick={<CustomizedAxisTick2 />}
      domain={[
        squeeze
          ? (dataMin) => dataMin - 0.05 * dataMin
          : equalize
          ? "-dataMax"
          : "auto",
        squeeze
          ? (dataMax) => dataMax + 0.05 * dataMax
          : equalize
          ? "dataMax"
          : "auto",
      ]}
      tickFormatter={(val) => val.toLocaleString().replace(/ /g, "\u00a0")}
      type={type || "category"}
      interval={tickCount ? undefined : 0}
      dataKey={dataKey}
      yAxisId={index}
      hide={!show}
      label={{ value: label, angle: -90, position: "insideTopLeft" }}
      unit={unit}
    />
  );
};

export const SecondaryAxis = ({
  index,
  orientation,
  dataKey,
  show,
  type,
  label,
  unit,
  squeeze,
  format,
  width,
  hideTicks,
  equalize,
  tickCount,
  useAbsoluteValues,
}) => {
  if (orientation === "horizontal") {
    // console.log("here 2x2-------");
    return (
      <YAxis
        tickCount={tickCount}
        tick={!hideTicks}
        width={width}
        tickFormatter={(val) =>
          (format && numeral(val).format(format)?.toUpperCase()) || val
        }
        domain={
          useAbsoluteValues
            ? [(dataMin) => dataMin, (dataMax) => dataMax]
            : [
                squeeze == true
                  ? (dataMin) => dataMin - 0.1 * dataMin
                  : equalize
                  ? Array.isArray(equalize)
                    ? (dataMin) =>
                        equalize[0] === "auto"
                          ? dataMin - 0.15 * dataMin
                          : equalize[0]
                    : (/*dataMin*/) => -equalize
                  : "auto",
                squeeze == true
                  ? (dataMax) => dataMax + 0.5 * dataMax
                  : equalize
                  ? Array.isArray(equalize)
                    ? (dataMax) =>
                        equalize[1] === "auto" ? dataMax : equalize[1]
                    : (/*dataMin*/) => equalize
                  : "auto",
              ]
        }
        type={type || "number"}
        dataKey={dataKey}
        yAxisId={index}
        hide={!show}
        orientation={index === 0 ? "left" : "right"}
        label={{ value: label, position: "insideTopRight", angle: -90 }}
        unit={unit}
      />
    );
  }
  return (
    <XAxis
      tickCount={tickCount}
      width={width}
      tickFormatter={(val) =>
        (format && numeral(val).format(format)?.toUpperCase()) || val
      }
      domain={
        useAbsoluteValues
          ? [(dataMin) => dataMin, (dataMax) => dataMax]
          : [
              squeeze == true
                ? (dataMin) => dataMin - 0.1 * dataMin
                : equalize
                ? Array.isArray(equalize)
                  ? (dataMin) =>
                      equalize[0] === "auto"
                        ? dataMin - 0.15 * dataMin
                        : equalize[0]
                  : () =>
                      // dataMin
                      -equalize
                : "auto",
              squeeze == true
                ? (dataMax) => dataMax + 0.5 * dataMax
                : equalize
                ? Array.isArray(equalize)
                  ? (dataMax) =>
                      equalize[1] === "auto" ? dataMax : equalize[1]
                  : () =>
                      // dataMin
                      equalize
                : "auto",
            ]
      }
      type={type || "number"}
      dataKey={dataKey}
      xAxisId={index}
      hide={!show}
      orientation={index === 0 ? "bottom" : "top"}
      label={{ value: label, position: "insideBottomRight" }}
      unit={unit}
    />
  );
};

export const TertiaryAxis = ({ dataKey, range }) => {
  return <ZAxis dataKey={dataKey} range={range} />;
};
