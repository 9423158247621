import styled from "styled-components";

export const VisualizationContainer = styled.div`
  background: ${(props) => (props.noBg ? "transparent" : "#181b20")};
  border-radius: 10px;
  padding: 15px 0px 0px 0px;
  min-height: ${(props) =>
    props.fullHeight ? "100%" : props.minHeight || "280px"};
  height: ${(props) => props.height || "auto"};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent || "center"};
  box-sizing: border-box;
`;

export const VisualizationTitle = styled.h3`
  font-family: SF Display;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  margin: 5px 0px;
  text-transform: capitalize;
`;

export const VisualizationFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  marginright: 1000px;
`;

export const VisualizationActions = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const VisualizationAction = styled.div`
  width: 22px;
  margin: 0px 2px;
  opacity: 1;
  cursor: pointer;
`;

export const VisualizationSettings = styled.ul`
  position: absolute;
  top: 10px;
  right: -20px;
  width: 165px;
  background: #000;
  list-style-type: none;
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 99;
`;

export const VisualizationSetting = styled.li`
  border-bottom: 2px solid #181b20;
  color: #fff;
  opacity: 0.75;
  padding: 7px 15px;
  font-family: SF Display;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const VisualizationModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const VisualizationModal = styled.div`
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  ${(props) => {
    if (props.small) {
      return `
                max-width: 767px;
                max-height: 480px;
            `;
    }
  }}
  background: #181b20;
  border-radius: 10px;
  padding: 15px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  animation: roadRunnerIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
`;

export const VisualizationBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction:${(props)=>props.flexDirection || "column"};
  justify-content:${(props)=>props.justifyContent || "center"};

  margin: 15px 0 5px;
  border-radius: 5px;
  width: 100%;

  zoom: ${(props) => (props.suppressZoom ? "1" : "1.1")};
`;

export const VisualizationHeader = styled.div`
  display: flex;
  padding: 0px 15px 0px 20px;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  ${(props) => {
    if (!props.center) {
      return `
                ${VisualizationFilter} {
                    flex: 1;
                }
            `;
    }

    if (props.center) {
      return `
                ${VisualizationTitle} {
                    flex: 1;
                }
            `;
    }
  }}
`;

export const VisualizationFilterOptions = styled.select`
  font-family: SF Text;
  margin: 0;
  border: 1px solid rgba(8, 150, 214, 0.33);
  border-radius: 5px;
  padding: 5px 7px;
  background: transparent;
  color: rgba(255, 255, 255, 0.65);
  text-align: right;
  font-weight: 200;
  font-size: 12px;
  outline: none !important;

  > option {
    font-family: SF Text;
    background: #000;
    color: rgba(255, 255, 255, 0.66);
    text-align: left;
    font-weight: 200;
  }
`;
