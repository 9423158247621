import React from "react";
import { ReferenceLine } from "recharts";

export const ZeroLine = ({ orientation }) => {
  let attr = {};
  if (orientation === "horizontal") {
    attr["y"] = 0;
  } else {
    attr["x"] = 0;
  }
  return <ReferenceLine {...attr} stroke="#666" />;
};
