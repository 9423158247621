export default {
    "filter":{
        "type": "count",
        "show": false,
        "enabled": false,
        "dim": 0,
        "sort": "DESC",
        "defaultValue": 0
    },
    "axes": {
        "primary": {
            "dim": 2,
            "show": false
        },
        "secondary": {
            "dim": 2,
            "show": false
        }
    },
    "zeroReference": false,
    "orientation": "horizontal",
    "legend": {
        "show": false,
        "verticalAlign": "top",
        "align": "left",
        "layout": "horizontal"
    },
    "primaryDim": 1,
    "plot": {
        "aggregation":"none",
        "series":[]
    }
}