import { makeVizPostCallWithConversion } from "./../../services/at-a-glance/overall";
import { UserContext } from "@pai/providers/with-auth-b2c";
import { useContext, useMemo } from "react";
class CommonServiceHelper {
  constructor(token) {
    this.authToken = token;
    this.getPostData = this.getPostData.bind(this);
  }

  async getPostData(dataConfig) {
    const data = await makeVizPostCallWithConversion(
      this.authToken,
      dataConfig
    );
    if (!data) return null;
    return data;
  }
}

export const useCommonServiceHelper = () => {
  const { token } = useContext(UserContext);
  const commonServiceHelper = useMemo(
    () => new CommonServiceHelper(token),
    [token]
  );
  return commonServiceHelper;
};

export default CommonServiceHelper;
