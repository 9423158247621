export default {
  axes: {
    row: {
      dim: 0,
    },
    column: {
      dim: 1,
    },
  },
  heatRange: {
    start: 0,
    end: 2,
  },
  primaryDim: 2,
  format: "0.0%",
};
