/* eslint-disable */
import React from "react";
import ColorSelect, { Color } from "../../../color-select";
import Toggle from "../../../toggle";
import {
  SettingsGroup,
  Setting,
  SettingLabel,
  SettingSelect,
  SettingText,
  SettingsGroupTitle,
  Button,
} from "../../../settings";

const FilterSettings = ({
  fields,
  show,
  enabled,
  dim,
  sort,
  type,
  defaultValue,
  count,
  onChange,
}) => (
  <SettingsGroup>
    <SettingsGroupTitle small>Filter</SettingsGroupTitle>
    <Setting>
      <SettingLabel>Enabled</SettingLabel>
      <Toggle
        checked={enabled}
        onChange={(e) => {
          onChange({
            enabled: !enabled,
            dim,
            show,
            sort,
            type,
            defaultValue,
            count,
          });
        }}
      />
    </Setting>
    <Setting>
      <SettingLabel>Filtering Type</SettingLabel>
      <SettingSelect
        value={type}
        onChange={(e) => {
          onChange({
            enabled,
            dim,
            show,
            sort: "DESC",
            type: e.target.value,
            defaultValue,
            count: 5,
          });
        }}
      >
        <option value="dimension">By Dimension</option>
        <option value="count">Top X</option>
      </SettingSelect>
    </Setting>
    <Setting>
      <SettingLabel>{type == "count" && "Sort by "}Dimension</SettingLabel>
      <SettingSelect
        value={dim}
        onChange={(e) => {
          onChange({
            enabled,
            dim: e.target.value,
            show,
            sort,
            type,
            defaultValue,
            count,
          });
        }}
      >
        {fields.map((field, index) => (
          <option value={index}>{field}</option>
        ))}
      </SettingSelect>
    </Setting>
    {type === "count" && (
      <Setting>
        <SettingLabel>Sort Order</SettingLabel>
        <SettingSelect
          value={sort}
          onChange={(e) => {
            onChange({
              enabled,
              dim,
              show,
              sort: e.target.value,
              type,
              defaultValue,
              count,
            });
          }}
        >
          <option value="ASC">Ascending</option>
          <option value="DESC">Descending</option>
        </SettingSelect>
      </Setting>
    )}
    {type === "count" && (
      <Setting>
        <SettingLabel>Count</SettingLabel>
        <SettingText
          value={count}
          onChange={(e) => {
            onChange({
              enabled,
              dim,
              show,
              sort,
              type,
              defaultValue,
              count: e.target.value,
            });
          }}
        />
      </Setting>
    )}
  </SettingsGroup>
);

const LegendSettings = ({ show, verticalAlign, align, layout, onChange }) => (
  <SettingsGroup>
    <SettingsGroupTitle small>Legend</SettingsGroupTitle>
    <Setting>
      <SettingLabel>Show Legend</SettingLabel>
      <Toggle
        checked={show}
        onChange={(e) => {
          onChange({
            show: !show,
            verticalAlign,
            align,
            layout,
          });
        }}
      />
    </Setting>
    <Setting>
      <SettingLabel>Vertical Position</SettingLabel>
      <SettingSelect
        value={verticalAlign}
        onChange={(e) => {
          onChange({
            show,
            verticalAlign: e.target.value,
            align,
            layout,
          });
        }}
      >
        <option value="middle">Middle</option>
        <option value="top">Top</option>
        <option value="bottom">Bottom</option>
      </SettingSelect>
    </Setting>
    <Setting>
      <SettingLabel>Horizontal Position</SettingLabel>
      <SettingSelect
        value={align}
        onChange={(e) => {
          onChange({
            show,
            verticalAlign,
            align: e.target.value,
            layout,
          });
        }}
      >
        <option value="center">Center</option>
        <option value="left">Left</option>
        <option value="right">Right</option>
      </SettingSelect>
    </Setting>
    <Setting>
      <SettingLabel>Layout</SettingLabel>
      <SettingSelect
        value={layout}
        onChange={(e) => {
          onChange({
            show,
            verticalAlign,
            align,
            layout: e.target.value,
          });
        }}
      >
        <option value="horizontal">Horizontal</option>
        <option value="vertical">Vertical</option>
      </SettingSelect>
    </Setting>
  </SettingsGroup>
);

export default ({
  dataConfigId,
  dataConfigs,
  fields,
  title,
  settings,
  onChange,
}) => {
  const generateUpdatedSettings = (newSettings) => ({
    dataConfigId,
    title,
    settings: newSettings,
  });

  const generateUpdatedSeries = (editIndex, updatedSeries) => ({
    ...generateUpdatedSettings(settings),
    settings: {
      ...settings,
      plot: {
        ...settings.plot,
        series: settings.plot.series.map((s, i) => {
          if (i === editIndex) {
            return {
              ...s,
              ...updatedSeries,
            };
          }
          return s;
        }),
      },
    },
  });

  const generateUpdatedTitle = (newTitle) => ({
    title: newTitle,
    dataConfigId,
    settings,
  });

  const generateUpdatedDataConfigId = (newDataConfigId) => ({
    title,
    dataConfigId: newDataConfigId,
    settings,
  });

  return (
    <div>
      <SettingsGroup>
        <SettingsGroupTitle small>Data</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Data Frame</SettingLabel>
          <SettingSelect
            value={dataConfigId}
            onChange={(e) => {
              onChange(generateUpdatedDataConfigId(e.target.value));
            }}
          >
            {dataConfigs &&
              dataConfigs.map((c) => <option value={c.key}>{c.key}</option>)}
          </SettingSelect>
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Basic</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Chart Title</SettingLabel>
          <SettingText
            value={title}
            onChange={(e) => {
              onChange(generateUpdatedTitle(e.target.value));
            }}
          />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Axes</SettingsGroupTitle>
        <Setting>
          <SettingLabel>X Axis Feature</SettingLabel>
          <SettingSelect
            value={settings.axes.primary.dim}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    primary: {
                      ...settings.axes.primary,
                      dim: e.target.value,
                      label: fields[e.target.value],
                    },
                  },
                })
              );
            }}
          >
            {fields.map((field, index) => (
              <option value={index}>{field}</option>
            ))}
          </SettingSelect>
        </Setting>
        <Setting>
          <SettingLabel>X Axis Format</SettingLabel>
          <SettingText
            value={settings.axes.primary.format}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    primary: {
                      ...settings.axes.primary,
                      format: e.target.value,
                    },
                  },
                })
              );
            }}
          />
        </Setting>
        <Setting>
          <SettingLabel>Y Axis Feature</SettingLabel>
          <SettingSelect
            value={settings.axes.secondary.dim}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    secondary: {
                      ...settings.axes.secondary,
                      dim: e.target.value,
                      label: fields[e.target.value],
                    },
                  },
                })
              );
            }}
          >
            {fields.map((field, index) => (
              <option value={index}>{field}</option>
            ))}
          </SettingSelect>
        </Setting>
        <Setting>
          <SettingLabel>Y Axis Format</SettingLabel>
          <SettingText
            value={settings.axes.secondary.format}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    secondary: {
                      ...settings.axes.secondary,
                      format: e.target.value,
                    },
                  },
                })
              );
            }}
          />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>
          Plot{" "}
          <Button
            onClick={() => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  plot: {
                    ...settings.plot,
                    series: [
                      ...settings.plot.series,
                      {
                        dim: 1,
                        color: {
                          type: "constant",
                          value: settings.plot.series.length,
                        },
                        axis: 0,
                        labelDim: 0,
                        format: "",
                        labelPosition: "right",
                      },
                    ],
                  },
                })
              );
            }}
          >
            Add Series
          </Button>
        </SettingsGroupTitle>
        {settings.plot.series.length > 1 && (
          <Setting>
            <SettingLabel>Group Type</SettingLabel>
            <SettingSelect
              value={settings.plot.aggregation}
              onChange={(e) => {
                onChange(
                  generateUpdatedSettings({
                    ...settings,
                    plot: {
                      ...settings.plot,
                      aggregation: e.target.value,
                    },
                  })
                );
              }}
            >
              <option value="None">None</option>
              <option value="Stacked">Stacked</option>
              <option value="Overlay">Overlay</option>
            </SettingSelect>
          </Setting>
        )}
        {settings.plot.series.map((series, index) => [
          <SettingsGroupTitle small>
            Series {index + 1}{" "}
            <Button
              onClick={() => {
                onChange(
                  generateUpdatedSettings({
                    ...settings,
                    plot: {
                      ...settings.plot,
                      series: settings.plot.series.filter(
                        (s, i) => i !== index
                      ),
                    },
                  })
                );
              }}
            >
              Remove
            </Button>
          </SettingsGroupTitle>,
          <Setting>
            <SettingLabel>Feature</SettingLabel>
            <SettingSelect
              value={series.dim}
              onChange={(e) => {
                onChange(generateUpdatedSeries(index, { dim: e.target.value }));
              }}
            >
              {fields.map((field, index) => (
                <option value={index}>{field}</option>
              ))}
            </SettingSelect>
          </Setting>,
          <Setting>
            <SettingLabel>Label</SettingLabel>
            <SettingSelect
              value={series.labelDim}
              onChange={(e) => {
                onChange(
                  generateUpdatedSeries(index, { labelDim: e.target.value })
                );
              }}
            >
              {fields.map((field, index) => (
                <option value={index}>{field}</option>
              ))}
            </SettingSelect>
          </Setting>,
          <Setting>
            <SettingLabel>Display Format</SettingLabel>
            <SettingText
              value={series.format}
              onChange={(e) => {
                onChange(
                  generateUpdatedSeries(index, { format: e.target.value })
                );
              }}
            />
          </Setting>,
          <Setting>
            <SettingLabel>Color Type</SettingLabel>
            <SettingSelect
              value={series.color.type}
              onChange={(e) => {
                onChange(
                  generateUpdatedSeries(index, {
                    color: {
                      type: e.target.value,
                      value: series.color.value,
                    },
                  })
                );
              }}
            >
              <option value="constant">Constant</option>
              <option value="gradient">Gradient</option>
            </SettingSelect>
          </Setting>,
          <Setting>
            <SettingLabel>Color Value</SettingLabel>
            <ColorSelect
              activeIndex={series.color.value}
              onSelect={(colorIndex) => {
                onChange(
                  generateUpdatedSeries(index, {
                    color: {
                      type: series.color.type,
                      value: colorIndex,
                    },
                  })
                );
              }}
            />
          </Setting>,
          <Setting>
            <SettingLabel>Axis</SettingLabel>
            <SettingSelect
              value={series.axis}
              onChange={(e) => {
                onChange(
                  generateUpdatedSeries(index, { axis: e.target.value })
                );
              }}
            >
              <option value={0}>Primary</option>
              {settings.axes.secondary.show && (
                <option value={1}>Secondary</option>
              )}
            </SettingSelect>
          </Setting>,
        ])}
      </SettingsGroup>
    </div>
  );
};
