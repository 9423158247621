import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #111419;
  min-height: 111vh;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 111vh;
  position: relative;
`;

export const Logo = styled.div`
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 1.188rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: all ease 500ms;
`;
export const LogoName = styled.div`
  display: flex;
  flex-direction: row;
  transition: transform ease 500ms;
`;

export const LogoStatic = styled.h2`
  color: #fff;
  font-family: "SF Display", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 0;
  letter-spacing: 0px;
  transition: all ease 500ms;
  line-height: 100%;
  position: relative;
  z-index: 2;
  background: #0a0c0f;

  > small {
    color: rgba(255, 255, 255, 0.5);
  }

  > span {
    color: #15afd0;
  }
`;

export const LogoDynamic = styled(LogoStatic)`
  transition: all ease 500ms;
  position: relative;
  z-index: 1;
`;

export const SubMenuItem = styled(NavLink)`
  padding: 0.8rem 1rem;
  margin: 0px 0.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 2px solid transparent;
  transition: background ease 500ms;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 2px;
  ${(props) => (props.firstSubGroup ? "margin-left:auto;" : "")}

  &:hover {
    > p {
      color: #15afd0;
    }
  }

  ${(props) => {
    if (props.active) {
      return `
                > p{
                    color: #15afd0;
                }
            `;
    }
  }}
`;

export const ScrollContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
`;

export const Sidebar = styled.div`
  width: 100%;
  background: #0a0c0f;
  display: flex;
  flex-direction: column;
  transition: all ease 500ms;
  max-height: 111vh;
  z-index: 99;
  max-width: 270px;
  position: relative;

  ${(props) => {
    if (props.collapsed) {
      return `
                max-width: 60px;

                ul > a{
                    padding-left: 1.188rem;
                }

                p {
                    opacity: 0;
                }

                ${Logo} {
                    transform: scale(0.90) translateX(-12px);
                }

                ${LogoDynamic} {
                    margin-left: -115px;
                    opacity: 0;
                }
            `;
    }
  }}
`;

export const PageContainer = styled.div`
  flex: 1;
  padding: 20px 1.188rem;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 1.888rem;
  flex-direction: column;
  position: relative;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
`;

export const MenuText = styled.p`
  font-family: "SF Text";
  font-size: 14px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
  flex: 1;
  margin: 0;
`;

export const ActiveMenuText = styled.p`
  font-family: "SF Text";
  font-size: 14px;
  font-weight: 200;
  color: #15afd0;
  flex: 1;
  margin: 0;
`;