import React from "react";
import LoadingCard from "./pages/loading-card";
import "./index.css";
import "./assets/generic-ant-d-components/style.less";
import { withAuth } from "./providers/with-auth-b2c";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider prefixCls="pai">
      <LoadingCard />
    </ConfigProvider>
  );
}

export default withAuth(App);
