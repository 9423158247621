/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { ScatterChart, Scatter, LabelList, Tooltip } from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  TertiaryAxis,
  Legend,
  Gradients,
  Wrapper,
} from "../../components";

const CustomizedLabel = () => ({
  render() {
    const { x, y, stroke, value } = this.props;
    const translateY = value
      ? (value.toString().split(",").length / 2) * 11
      : 0;
    return (
      <g transform={`translate(${x + 10},${y - translateY})`}>
        <text
          alignmentBaseline="center"
          dominantBaseline="center"
          x={0}
          y={0}
          dy={16}
          fill="#666"
        >
          {value &&
            value
              .toString()
              .split(",")
              .map((t, i) => (
                <tspan
                  x="0"
                  alignmentBaseline="center"
                  dominantBaseline="center"
                  dy={10}
                >
                  {t}
                </tspan>
              ))}
        </text>
      </g>
    );
  },
});

export default ({ id, dataset, settings }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.8)",
            padding: "5px 5px 5px 5px",
            borderRadius: 8,
          }}
        >
          <div
            style={{ fontSize: "12px" }}
          >{`${payload[0].payload.tooltipText}`}</div>
          <div style={{ fontSize: "12px" }}>{`${
            axes.secondary.label
          } : ${numeral(payload[0].payload.y)
            .format(axes.secondary.format)
            ?.toUpperCase()}`}</div>
          <div style={{ fontSize: "12px" }}>{`${axes.primary.label} : ${numeral(
            payload[0].payload.x
          )
            .format(axes.primary.format)
            ?.toUpperCase()}`}</div>
        </div>
      );
    }

    return null;
  };

  const { legend, plot, axes } = settings;

  const orientation = "horizontal";

  let margin = { top: 0, bottom: -30, left: -60, right: 0 };

  if (!plot.series.length) {
    return "";
  }

  return (
    <Wrapper className="twoByTwo">
      {({ width, height }) => (
        <ScatterChart width={width} height={height} margin={margin}>
          {Gradients({ chartId: id, orientation })}
          {axes.primary &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: "x",
              show: axes.primary.show,
              type: "number",
              label: axes.primary.label,
              unit: axes.primary.unit,
              squeeze: axes.primary.squeeze,
              format: axes.primary.format,
              useAbsoluteValues: axes.primary.useAbsoluteValues,
              equalize: axes.primary.equalize,
              tickCount: axes.primary.tickCount,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 0,
              orientation,
              dataKey: "y",
              show: axes.secondary.show,
              type: "number",
              label: axes.secondary.label,
              unit: axes.secondary.unit,
              squeeze: axes.secondary.squeeze,
              format: axes.secondary.format,
              useAbsoluteValues: axes.secondary.useAbsoluteValues,
              equalize: axes.secondary.equalize,
              tickCount: axes.secondary.tickCount,
            })}
          {axes.tertiary &&
            TertiaryAxis({
              index: 1,
              orientation,
              dataKey: "z",
              show: axes.tertiary.show,
              type: "number",
              label: axes.tertiary.label,
              range: [5, 50],
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => plot.series[index].label,
          })}
          <Tooltip
            cursor={{ stroke: "#616161", strokeDasharray: "5 5" }}
            content={<CustomTooltip />}
          />
          {plot &&
            plot.series &&
            plot.series.map((series) => {
              const xAxisId = 0;
              const yAxisId = series.axis;
              const data = dataset.data.map((d) => ({
                x: d[dataset.fields[axes.primary.dim]],
                y: d[dataset.fields[series.dim]],
                tooltipText: (d[dataset.fields[series.labelDim]] || "")
                  .toLocaleString()
                  .replace(/ /g, "\u00a0"),
                z: 10,
                label: !series.hideLabelText
                  ? (d[dataset.fields[series.labelDim]] || "")
                      .toLocaleString()
                      .replace(/ /g, "\u00a0")
                  : "",
              }));
              return (
                <Scatter
                  name={series.name}
                  data={data}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  fill={`url(${Color[series.color.value]}_${id})`}
                >
                  <LabelList
                    dataKey={"label"}
                    position={series.labelPosition || "left"}
                    content={<CustomizedLabel />}
                  />
                </Scatter>
              );
            })}
          {plot.quadLabels && (
            <g>
              <text x="0" y="0" style={{ fill: Color[2], fontSize: "11px" }}>
                <tspan dx="10" dy="20">
                  {/* //High Churn, Low Consumption{" "} */}
                  {plot.quadLabels[1]}
                </tspan>
              </text>
              <text
                x="100%"
                y="0"
                style={{ fill: Color[2], fontSize: "11px" }}
                dominantBaseline="middle"
                textAnchor="end"
              >
                {" "}
                <tspan dx="-10" dy="20">
                  {/* High Churn, High Consumption */}
                  {plot.quadLabels[0]}
                </tspan>
              </text>
              <text x="0" y="100%" style={{ fill: Color[2], fontSize: "11px" }}>
                <tspan dx="10" dy="-10">
                  {/* Low Churn, Low Consumption */}
                  {plot.quadLabels[2]}
                </tspan>
              </text>
              <text
                x="100%"
                y="100%"
                style={{ fill: Color[2], fontSize: "11px" }}
                dominantBaseline="middle"
                textAnchor="end"
              >
                <tspan dx="-10" dy="-10">
                  {/* Low Churn, High Consumption */}
                  {plot.quadLabels[3]}
                </tspan>
              </text>
            </g>
          )}
        </ScatterChart>
      )}
    </Wrapper>
  );
};
