import { message, notification } from '@pai-ui/core';

export const showToastMessage = (msgType, msg)=>{
 if(msgType==='success'){
   message.success(msg);
 } else if(msgType==='error'){
   message.error(msg);
 }  else if(msgType==='warning'){
  message.warning(msg);
}
};

export const showNotificationMessage = (placement, type, msg)=>{
    notification[type]({
      message: msg,
      placement,
    });
};

