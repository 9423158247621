/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { PieChart, Pie, Cell } from "recharts";
import { Color } from "../../../color-select";
import { Legend, Gradients, Wrapper, Tooltip } from "../../components";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // console.log("pie label called");
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={"center"}
      dominantBaseline="central"
      style={{ paddingBottom: "20px", fontWeight: "bold" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default ({ id, dataset, settings }) => {
  const {
    primaryDim,
    valueDim,
    legend,
    pctSelectionFieldName,
    pctInnerRadius,
    startAngle,
    endAngle,
  } = settings;

  //console.log("innerRadius", innerRadius);

  let margin = { top: 15, bottom: 0, left: 15, right: 15 };

  const outerRadius = (height, width) =>
    0.43 *
    (width > height ? height : width) *
    (360 / ((endAngle || 360) - (startAngle || 0)));
  const innerRadius = (height, width) =>
    pctInnerRadius ? pctInnerRadius * outerRadius(height, width) : 0;
  let cx = (width) => width / 2 - 10;
  let cy = (height) =>
    height / (((endAngle || 360) - (startAngle || 0)) / 180) - 40;

  return (
    <Wrapper>
      {({ width, height }) => (
        <PieChart width={width} height={height} margin={margin}>
          {Gradients({ chartId: id, orientation: "vertical" })}
          {Legend({ legendSettings: legend })}
          {Tooltip({
            formatter: (value, entry, props) => {
              return [numeral(value).format("0,0a"), props.name];
            },
          })}
          <Pie
            data={dataset.data.map((x) => ({
              ...x,
              [pctSelectionFieldName]: parseFloat(x[pctSelectionFieldName]),
            }))}
            dataKey={pctSelectionFieldName} //1
            nameKey={dataset.fields[primaryDim]} //0
            labelLine={false}
            label={renderCustomizedLabel}
            cx={cx(width)}
            cy={cy(height)}
            outerRadius={outerRadius(height, width)}
            innerRadius={innerRadius(height, width)}
            startAngle={startAngle || 0}
            endAngle={endAngle || 360}
            // isAnimationActive={false}
          >
            {dataset.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(${Color[index + 2]}_${id})`}
                stroke={"transparent"}
              />
            ))}
          </Pie>
        </PieChart>
      )}
    </Wrapper>
  );
};
