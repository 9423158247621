/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { BarChart, Bar, LabelList, Cell } from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
  Tooltip,
} from "../../components";
import millify from "millify";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

export default ({ id, dataset, settings }) => {
  const {
    orientation,
    primaryDim,
    primaryLookup,
    legend,
    axes,
    zeroReference,
    baseLineConfig,
    incrementConfig,
    primaryWidth,
    primaryType,
    tickCount,
  } = settings;

  let margin = { top: 15, bottom: 0, left: 15, right: 15 };

  if (orientation === "vertical") {
    margin = { top: 10, left: 25, right: 10, bottom: 20 };
  }

  if (legend.verticalAlign === "bottom") {
    margin.bottom = 15;
  }

  if (legend.additionalPadding) {
    margin.bottom = 40;
  }

  if (axes.primary && axes.primary.show) {
    margin.right = 25;
  }

  // const baseLine = dataset.data.map(d => d[dataset.fields[baseLineConfig.dim]]).reduce((a, b) => a + b, 0)
  const baseLine = 0;
  const finalValue =
    baseLine +
    dataset.data
      .map((d) => d[dataset.fields[incrementConfig.dim]])
      .reduce((a, b) => a + b, 0);
  const increments = dataset.data
    .map((d) => d[dataset.fields[incrementConfig.dim]])
    .filter((f) => f);
  const incrementLabels = dataset.data
    .filter((d) => d[dataset.fields[incrementConfig.dim]])
    .map((d) => d[dataset.fields[primaryDim]]);

  let finalDataset = [
    // { "label": baseLineConfig.label, "value": baseLine, "color": baseLineConfig.color } // FIXME make it optional
  ];

  let previousOffset = baseLine;
  increments.forEach((increment, i) => {
    finalDataset.push({
      label: incrementLabels[i],
      value: [previousOffset, increment + previousOffset, increment],
      color: incrementConfig.colorRule
        ? incrementConfig.colorRule(increment)
        : incrementConfig.color,
    });

    previousOffset = previousOffset + increment;
  });

  finalDataset.push({
    label: incrementConfig.label,
    value: finalValue,
    color: baseLineConfig.color,
  });

  return (
    <Wrapper>
      {({ width, height }) => (
        <BarChart
          width={width}
          height={height}
          layout={orientation}
          data={finalDataset}
          margin={margin}
          barCategoryGap={"17%"}
        >
          {Gradients({ chartId: id, orientation })}
          {PrimaryAxis({
            index: 0,
            orientation,
            dataKey: "label",
            show: true,
            width: primaryWidth,
            type: primaryType,
            tickCount,
          })}
          {axes.primary &&
            SecondaryAxis({
              index: 0,
              orientation,
              dataKey: "value",
              show: axes.primary.show,
              squeeze: axes.primary.squeeze,
              width: axes.width,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 1,
              orientation,
              dataKey: dataset.fields[axes.secondary.dim],
              show: axes.secondary.show,
              squeeze: axes.secondary.squeeze,
              width: axes.width,
            })}
          {Tooltip({
            formatter: (value, entry, props) => {
              const dim = dataset.fields.indexOf(props.dataKey);
              // console.log('waterfall hover',dim,value)
              if (dim === -1)
                return [
                  numeral(value[1] ? value[1] : value)
                    .format(incrementConfig.labelFormat)
                    ?.toUpperCase(),
                  incrementConfig.label,
                ];
              else {
                return [
                  value.length != undefined
                    ? numeral(value[1] - value[0])
                        .format(incrementConfig.labelFormat)
                        ?.toUpperCase()
                    : numeral(value)
                        .format(incrementConfig.labelFormat)
                        ?.toUpperCase(), //ss show absolute value for each bar
                  incrementConfig.label,
                ];
              }

              // const seriesElem = plot.series.find((s) => s.dim === dim);
              // if (!seriesElem) return value;
            },
          })}

          {orientation === "horizontal" && (
            <Bar dataKey={"value"} maxBarSize={60} isAnimationActive={false}>
              {!incrementConfig.hideLabel && (
                // <LabelList position={incrementConfig.labelPosition}
                // formatter={(value, i) =>  {
                //     console.log('pay',value,finalDataset, i );
                //     return numeral(value).format(incrementConfig.labelFormat)}} />
                <LabelList
                  valueAccessor={(e) => {
                    if (Array.isArray(e.payload.value)) {
                      return e.payload.value[1] - e.payload.value[0];
                    }
                    return e.payload.value;
                  }}
                  position={incrementConfig.labelPosition}
                  formatter={(value) =>
                    incrementConfig.labelFormat == "0.0a"
                      ? "$" + millify(value, { precision: 1, lowercase: false })
                      : numeral(value)
                          .format(incrementConfig.labelFormat)
                          ?.toUpperCase()
                  }
                />
              )}
              {finalDataset &&
                finalDataset.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(${Color[entry.color]}_${id})`}
                  />
                ))}
            </Bar>
          )}
          {orientation === "vertical" && (
            <Bar dataKey={"value"} maxBarSize={60} isAnimationActive={false}>
              {!incrementConfig.hideLabel && (
                <LabelList
                  valueAccessor={(e) => {
                    if (Array.isArray(e.payload.value)) {
                      return e.payload.value[1] - e.payload.value[0];
                    }
                    return e.payload.value;
                  }}
                  position={incrementConfig.labelPosition}
                  formatter={(value) => {
                    return incrementConfig.labelFormat == "0.0a"
                      ? 0
                      : numeral(value)
                          .format(incrementConfig.labelFormat)
                          ?.toUpperCase();
                  }}
                />
              )}
              {finalDataset &&
                finalDataset.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(${Color[entry.color]}_${id})`}
                  />
                ))}
            </Bar>
          )}
        </BarChart>
      )}
    </Wrapper>
  );
};
