//icons
// import { Monitor } from "@styled-icons/feather/Monitor";
// import { MakeGroup } from "@styled-icons/icomoon/MakeGroup";
// import { Input } from "@styled-icons/material/Input";
import { TestTube } from "@styled-icons/boxicons-regular/TestTube";
// import { UserLock } from "@styled-icons/fa-solid/UserLock";
// import { Coupon2 } from "@styled-icons/remix-line/Coupon2";
// import * as Dash from "@styled-icons/remix-line/Dashboard";
// import { Link } from "@styled-icons/boxicons-regular/Link";
// import { Users } from "@styled-icons/fa-solid/Users";

export const MenuConfig = {
  groups: [
    // {
    //   name: "Executive",
    //   items: [
    //     {
    //       text: "At a Glance",
    //       icon: Dash.Dashboard,
    //       linked: "/dashboard",
    //       defaultRoute: "/results",
    //       subMenu: [
    //         {
    //           name: "Results",
    //           linked: "/results",
    //           key: "AAGResults",
    //         },
    //         {
    //           name: "Opportunities", //Tab name
    //           linked: "/opportunities", //link that will be shown in the URL
    //           key: "AAGOpportunity", //Componenet Name
    //         },
    //         // {
    //         //   name: "Bundles Deep Dive ", //Tab name
    //         //   linked: "/bundles", //link that will be shown in the URL
    //         //   key: "BundlesDeepDive", //Component Name
    //         // },
    //         // { name: "Cohorts", linked: "/cohorts", key: "PersonasAtAGlance" },
    //       ],
    //     },
    //   ],
    // },

    {
      name: "Data Science",
      items: [
        // {
        //   text: "Customer Lifecycle",
        //   icon: Users,
        //   linked: "/diagnose",
        //   // defaultRoute: "/Hypothesis",
        //   defaultRoute: "/hypothesis",
        //   subMenu: [
        //     {
        //       name: "Hypothesis",
        //       linked: "/hypothesis",
        //       key: "Hypothesis",
        //     },
        //     {
        //       name: "Results",
        //       linked: "/results",
        //       key: "HypothesisResults",
        //       hidden: true,
        //     },
        //     {
        //       name: "Customer Journey",
        //       linked: "/cust-journey",
        //       key: "CustomerJournery",
        //     },
        //     { name: "", linked: "/build", key: "BuildCustomerJournery" },
        //     { name: "", linked: "/edit", key: "EditCustomerJourney" },
        //   ],
        //   actions: [],
        // },

        // {
        //   text: "Hypothesis & Diagnosis",
        //   icon: Input,
        //   linked: "/diagnose",
        //   defaultRoute: "/Hypothesis",
        //   subMenu: [
        //     {
        //       name: "Hypothesis",
        //       linked: "/hypothesis",
        //       key: "Hypothesis",
        //     },
        //     {
        //       name: "Results",
        //       linked: "/results",
        //       key: "HypothesisResults",
        //       hidden: true,
        //     },
        //   ],
        //   actions: [],
        // },
        // {
        //   text: "Business & Insights",
        //   icon: Input,
        //   linked: "/businessinputs",
        //   defaultRoute: "/objective",
        //   subMenu: [
        //     {
        //       name: "Objectives",
        //       linked: "/objective",
        //       key: "Objective",
        //       type: "Component",
        //       componentKey: "ObjectiveMenu",
        //     },
        //     {
        //       name: "Feature Engineering",
        //       linked: "/feature-engineering",
        //       key: "FeatureEngineering",
        //     },
        //     {
        //       name: "Univariate Analysis",
        //       linked: "/univariate-analysis",
        //       key: "Univariate",
        //     },
        //     {
        //       name: "Bivariate & Correlation",
        //       linked: "/bivariate-correlation",
        //       key: "Bivariate",
        //     },
        //     {
        //       name: "Auto Dashboard",
        //       linked: "/auto-dashboard",
        //       key: "AutoDashboard",
        //     },
        //     {
        //       name: "Campaigns",
        //       linked: "/campaign",
        //       key: "Campaigns",
        //     },
        //     // {
        //     //   name: "MockPage",
        //     //   linked: "/mock",
        //     //   key: "MockPage",

        //     //   // componentKey: "ObjectiveMenu",
        //     // },
        //   ],
        //   actions: [],
        // },
        // {
        //   text: "Classification",
        //   icon: MakeGroup,
        //   linked: "/classification",
        //   defaultRoute: "/hierarchy",
        //   subMenu: [
        //     { name: "Hierarchy", linked: "/hierarchy", key: "Hierarchy" },

        //     // { name: "Renewals & Retentions", linked: "/renewals-retentions", key:"RenewalsRetentions" }, // to be removed
        //     //{ name: "Elasticity", linked: "/elasticity", key:"Elasticity" },
        //     {
        //       name: "Explore Segment",
        //       linked: "/segments",
        //       key: "ExploreSegments",
        //     },
        //     {
        //       name: "Explore Customers",
        //       linked: "/customers",
        //       key: "ExploreCustomers",
        //     },
        //   ],
        // },
        // {
        //   text: "Classification",
        //   icon: MakeGroup,
        //   linked: "/classification",
        //   defaultRoute: "/hierarchy",
        //   subMenu: [
        //     { name: "Hierarchy", linked: "/hierarchy", key: "Hierarchy" },
        //     { name: "HierarchyNew", linked: "/hierarchynew", key: "HierarchyNew" },

        //     // { name: "Renewals & Retentions", linked: "/renewals-retentions", key:"RenewalsRetentions" }, // to be removed
        //     //{ name: "Elasticity", linked: "/elasticity", key:"Elasticity" },
        //     {
        //       name: "Explore Segment",
        //       linked: "/segments",
        //       key: "ExploreSegments",
        //     },
        //     {
        //       name: "Explore Customers",
        //       linked: "/customers",
        //       key: "ExploreCustomersNew",
        //     },
        //   ],
        // },
        // {
        //   text: "Recommendation",
        //   icon: Coupon2,
        //   linked: "/recommendation",
        //   defaultRoute: "/summary",
        //   subMenu: [
        //     {
        //       name: "Summary",
        //       linked: "/summary",
        //       key: "RecommendationSummary",
        //     },
        //     {
        //       name: "Items & Offers",
        //       linked: "/items-offers",
        //       key: "ItemOfferRecom",
        //       dummy: true,
        //     },

        //     // { name: "Items & Offers", linked: "/items-offers", key:"ItemOffer" },
        //     { name: "Diagnostics", linked: "/diagnostics", key: "Diagnostics" },
        //   ],
        // },

        {
          text: "A/B Testing",
          icon: TestTube,
          linked: "/testing",
          defaultRoute: "/tests",
          subMenu: [
            { name: "Tests", linked: "/tests", key: "ABTestList", dummy: true },
            {
              name: "Measure",
              linked: "/measure",
              key: "AbTestResult",
              dummy: true,
            },
            {
              name: "",
              linked: "/analyze/:id?",
              key: "ABTestAnalyse",
              dummy: true,
            },
            {
              name: "",
              linked: "/form",
              key: "ABTestForm",
            },
            {
              name: "", //Tab name
              linked: "/build", //link that will be shown in the URL
              key: "NewAbTest", //Component Name
            },
          ],
        },
        // {
        //   text: "Recommendation",
        //   icon: Coupon2,
        //   linked: "/recommendation",
        //   defaultRoute: "/overview",
        //   subMenu: [
        //     {
        //       name: "Overview",
        //       linked: "/overview",
        //       url: `${legacyHost}/recommendation/summary`,
        //     }
        //   ],
        // },
        // {
        //   text: "A/B Testing",
        //   icon: TestTube,
        //   linked: "/testing",
        //   defaultRoute: "/tests",
        //   subMenu: [
        //     {
        //       name: "Tests",
        //       linked: "/tests",
        //       url: `${legacyHost}/testing/tests`,
        //     },
        //     {
        //       name: "Measure",
        //       linked: "/measure/:id?",
        //       url: `${legacyHost}/testing/measure/:id?`,
        //     },
        //     {
        //       name: "",
        //       linked: "/build/:id?",
        //       url: `${legacyHost}/testing/build/:id?`,
        //     },
        //     {
        //       name: "",
        //       linked: "/analyze/:id?",
        //       url: `${legacyHost}/testing/analyze/:id?`,
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   name: "Operationalization",
    //   items: [
    //     {
    //       text: "Integrations",
    //       icon: Link,
    //       linked: "/integrations",
    //       defaultRoute: "/all",
    //       subMenu: [
    //         {
    //           name: "Integrations",
    //           linked: "/all",
    //           url: `${legacyHost}/integrations/all`,
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   name: "Operationalization",
    //   items: [
    //     {
    //       text: "Integrations",
    //       icon: Link,
    //       linked: "/integrations",
    //       defaultRoute: "/all",
    //       subMenu: [
    //         { name: "Integrations", linked: "/all", key: "Integrations" },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   name: "Administration",
    //   last: true,
    //   items: [
    //     // {
    //     //   text: "Monitoring",
    //     //   icon: Monitor,
    //     //   linked: "/monitoring",
    //     //   defaultRoute: "/pipelines",
    //     //   subMenu: [
    //     //     {
    //     //       name: "Execution Pipelines",
    //     //       linked: "/pipelines",
    //     //       key: "PipelineManagement",
    //     //     },
    //     //   ],
    //     // },
    //     {
    //       text: "Access Control",
    //       icon: UserLock,
    //       linked: "/accesscontrol",
    //       defaultRoute: "/AccessControl",
    //       subMenu: [
    //         {
    //           name: "Access Control",
    //           linked: "/accesscontrol",
    //           key: "AccessControl",
    //         },
    //       ],
    //     },
    //     // {
    //     //   text: "Pipeline Monitoring",
    //     //   icon: Monitor,
    //     //   linked: "/monitoring",
    //     //   defaultRoute: "/pipelines",
    //     //   subMenu: [
    //     //     {
    //     //       name: "Execution Pipelines",
    //     //       linked: "/pipelines",
    //     //       key: "PipelineManagement",
    //     //     },
    //     //   ],
    //     // },
    //     // {
    //     //   text: "Infrastructure Monitoring",
    //     //   icon: Admin,
    //     //   linked: "/infrastructure",
    //     //   defaultRoute: "/pipeline",
    //     //   subMenu: [{ name: "Pipeline", linked: "/pipeline" }],
    //     // },
    //   ],
    // },
  ],
};

export const FeedbackProps = {
  mailId: "pai.support@zs.com",
  defaultSubj: "Feedback for Personalize.AI",
};
