import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Gear } from "@styled-icons/evil/Gear";
import { Expand } from "@styled-icons/boxicons-regular/Expand";
import { Minimize } from "@styled-icons/feather/Minimize";
import { loadConfig } from "@pai/services/config";
import { loadDataset, convertToPng } from "@pai/services/visualization";
import { ZsRadioButton, ZsSelect1 } from "@pai/assets/generic-ant-d-components/quick-access-ant-d";
import OutsideAlerter from "@pai/library/containers/outside-alerter/index";
import { UserContext } from "@pai/providers/with-auth";
import { removeDelimiterAndToCamelCase } from '@pai/pages/transformer';
import WaveLoader from "@pai/assets/generic-components/wave-loader/WaveLoader.jsx"
import {
  VisualizationContainer,
  VisualizationTitle,
  VisualizationActions,
  VisualizationAction,
  VisualizationSettings,
  VisualizationSetting,
  VisualizationModalContainer,
  VisualizationModal,
  VisualizationBody,
  VisualizationHeader,
  VisualizationFilter,
} from "./styles";

import Charts from "../../charts";
import ChartSettings from "../settings";


const VisualizationSettingsComponent = ({ options, onSelect }) => {
  const [showSettings, setShowSettings] = useState(false);
  return (
    <VisualizationAction>
      <Gear color="#FFF" onClick={() => setShowSettings(true)} />
      {showSettings && (
        <OutsideAlerter
          action={() => {
            setShowSettings(false);
          }}>
          <VisualizationSettings>
            {options.map((option) => (
              <VisualizationSetting
                key={option.value}
                onClick={() => {
                  setShowSettings(false);
                  onSelect(option.value);
                }}>
                {option.label}
              </VisualizationSetting>
            ))}
          </VisualizationSettings>
        </OutsideAlerter>
      )}
    </VisualizationAction>
  );
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

const Visualization = ({
  id,
  defaultConfig,
  defaultData,
  fullHeight,
  minHeight,
  height,
  noBg,
  defaultTitle,
  template,
  hideTitle,
  onClick,
  hideSettings,
  hideActions,
  vizContainerStyle,
  vizBodyStyle,
  // justifyContent,
}) => {
  const [config, setConfig] = useState(defaultConfig);
  const [dataset, setDataset] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [showAsModal, setShowAsModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  //so that default value is true
  const [showChartSettings, setShowChartSettings] = useState(true);
  const userState = React.useContext(UserContext);
  useEffect(() => {
    if (hideSettings) {
      setShowChartSettings(false);
    }
    console.log("defaultData useeffetc")
    setDataset(defaultData);
  }, [defaultData]);

  useEffect(() => {
    if (config.chart_config.settings.filter) {
      const filterC = config.chart_config.settings.filter;
      if (filterC.enabled & (filterC.type === "dimension")) {
        setFilterData({
          skipFormatting: filterC?.skipFormatting,
          show: filterC.show,
          dropdownDisable: filterC?.dropdownDisable,
          values: [
            ...new Set(
              dataset?.data?.map((row) => row[dataset.fields[filterC.dim]])
            ),
          ],
          filterType: filterC.filterType,

          currentValue:
            filterC.defaultValue === "last"
              ? [
                ...new Set(
                  dataset.data.map((row) => row[dataset.fields[filterC.dim]])
                ),
              ].length - 2
              : filterC.defaultValue,
        });
      }
    }
    console.log("dataset useeffetc")
  }, [dataset, config]);

  useEffect(() => {
    if (hideSettings) {
      setShowChartSettings(false);
    }
    if (!defaultConfig) {
      setLoading(true);
      loadConfig(id).then((configuration) => {
        setConfig(configuration);
      });
    }
  }, [id]);

  useEffect(() => {
    if (hideSettings) {
      setShowChartSettings(false);
    }
    if (defaultConfig) {
      setConfig(defaultConfig);
    }
  }, [defaultConfig]);

  useEffect(() => {
    if (hideSettings) {
      setShowChartSettings(false);
    }
    if (!defaultData) {
      if (config) {
        setLoading(true);
        let newConfig = config;

        if (template) {
          let test = JSON.stringify(config);
          Object.keys(template).forEach((key) => {
            test = replaceAll(test, key, template[key]);
          });
          newConfig = JSON.parse(test);
        }

        loadDataset(
          newConfig.data_config_id,
          newConfig.data_config,
          newConfig.chart_config.yoy,
          newConfig.chart_config.agg,
          newConfig.chart_config.newColumns,
          userState.token,
          newConfig.postFetchOperation
        ).then((datasetFromServer) => {
          setDataset(datasetFromServer);

          console.log("Data API--------->", newConfig.data_config_id, datasetFromServer);

          setLoading(false);
        });
      }
    }
  }, [config, template]);

  // const objToCsv = (data) => {
  //   const csvRows = [];
  //   //get the headers
  //   const headers = Object.keys(data[0]);
  //   csvRows.push(headers.join(","));

  //   //loop rows
  //   for (const row of data) {
  //     const values = headers.map((header) => {
  //       const escaped = row[header].replace(/"/g, '\\"');
  //       return `"${escaped}"`;
  //     });
  //     csvRows.push(values.join(","));
  //   }
  //   return csvRows.join("\n");
  // };

  const handleSelect = (option) => {
    switch (option) {
      case "settings":
        setShowSettingsModal(true);
        break;
      case "export_png":
        convertToPng(id);
        break;
      case "export_csv":
      default:
        break;
    }
  };

  let filteredDataset = dataset;

  if (config && config.chart_config && config.chart_config.settings.filter && dataset) {
    const filterConfig = config.chart_config.settings.filter;
    if (filterConfig && filterConfig.enabled) {
      if (filterConfig.type === "dimension" && filterData) {
        filteredDataset = {
          ...dataset,
          data: dataset.data.filter(
            (row) => row[dataset.fields[filterConfig.dim]] === filterData.values[filterData.currentValue]
          ),
        };
      }
      if (filterConfig.type === "count") {
        const sortValues = {
          ASC: [1, -1],
          DESC: [-1, 1],
        };
        const sortedData = dataset.data.sort((a, b) =>
          a[dataset.fields[filterConfig.dim]] > b[dataset.fields[filterConfig.dim]]
            ? sortValues[filterConfig.sort][0]
            : sortValues[filterConfig.sort][1]
        );
        filteredDataset = {
          ...dataset,
          data: sortedData.slice(0, filterConfig.count),
        };
      }
    }
  }

  const Chart = config && !loading ? Charts[config.chart_config.type].Chart : null;
  return (
    <VisualizationContainer
      fullHeight={fullHeight}
      height={height}
      noBg={noBg}
      minHeight={minHeight}
      style={vizContainerStyle}>
      {!loading && config && !hideTitle && (
        <VisualizationHeader style={{ marginBottom: noBg ? -50 : 0 }}>
          <VisualizationTitle>{defaultTitle || config.chart_config.title}</VisualizationTitle>

          <VisualizationFilter
            style={{
              justifyContent:
                filterData && filterData.show && filterData.filterType === "radio_button"
                  ? config.chart_config.settings.filter.justifyContent || "center"
                  : "flex-end",
              marginRight:
                filterData && filterData.show && filterData.filterType == "left"
                  ? "200px"
                  : "auto",
            }}
          >
            {/* {filterData && filterData.show && (
              <VisualizationFilterOptions
                value={filterData.currentValue}
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    currentValue: e.target.value,
                  });
                }}
              >
                {filterData.values.map((v, i) => (
                  <option value={i}>{v}</option>
                ))}
              </VisualizationFilterOptions>
            )} */}
            {console.log("viz filterData", filterData, dataset)}
            {
              filterData &&
              filterData.show &&
              (filterData.filterType === "radio_button" ? (
                <ZsRadioButton
                  value={filterData.currentValue}
                  defaultValue={0}
                  optionType={"button"}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      currentValue: e.target.value,
                    });
                    // use this prop if we wwantt to do more operations on rb change from parent
                    config?.chart_config?.settings?.filter.useCustomOnChange &&
                      config?.chart_config?.settings?.filter.customFunc({
                        currValue: e.target.value,
                        filterData: filterData,
                      });
                  }}
                  options={filterData.values.map((v, i) => ({
                    label: removeDelimiterAndToCamelCase(v),
                    value: i,
                  }))}
                />
              ) : (
                <ZsSelect1
                  style={{ minWidth: "200px" }}
                  values={filterData.values.map((v, i) => ({
                    id: i,
                    name: filterData?.skipFormatting
                      ? v
                      : removeDelimiterAndToCamelCase(v),
                  }))}
                  value={filterData.currentValue}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      currentValue: e,
                    });
                  }}
                  disabled={filterData.dropdownDisable}
                />
              ))
              // <VisualizationFilterOptions
              //   value={filterData.currentValue}
              //   onChange={(e) => {

              //     setFilterData({
              //       ...filterData,
              //       currentValue: e.target.value,
              //     });
              //   }}
              // >
              //   {filterData.values.map((v, i) => (
              //     <option value={i}>{v}</option>
              //   ))}
              // </VisualizationFilterOptions>
            }
          </VisualizationFilter>
          {!hideActions && (
            <VisualizationActions>
              {showChartSettings && (
                <React.Fragment>
                  <VisualizationAction
                    onClick={() => {
                      setShowAsModal(true);
                    }}>
                    <Expand color="#FFF" />
                  </VisualizationAction>

                  <VisualizationSettingsComponent
                    options={[
                      { value: "export_png", label: "Export Chart (.png)" },
                      {
                        value: "export_csv",
                        label: (
                          <CSVLink
                            filename={id + ".csv"}
                            data={filteredDataset && filteredDataset.data}
                            style={{
                              color: "#FFF",
                              padding: "",
                              fontfamily: "SF Display",
                              fontsize: "12px",
                              cursor: "pointer",
                            }}
                            id="test">
                            Export Chart (.csv)
                          </CSVLink>
                        ),
                      },
                      // {
                      //   value: "settings",
                      //   label: "Settings",
                      // },
                    ]}
                    onSelect={handleSelect}
                  />
                </React.Fragment>
              )}
            </VisualizationActions>
          )}
        </VisualizationHeader>
      )}
      {/* Normal size */}
      <VisualizationBody id={id} style={vizBodyStyle}>
        {loading && <WaveLoader />}
        { }
        {!loading && dataset && (
          <Chart
            id={id}
            dataset={filteredDataset}
            settings={{ ...config.chart_config.settings, ModalView: false }}
            filterData={filterData}
            onClick={onClick}
          />
        )}
      </VisualizationBody>

      {/* Fullscreen */}
      {showAsModal && !showSettingsModal && (
        <VisualizationModalContainer>
          <VisualizationModal>
            <VisualizationHeader>
              <VisualizationTitle>{defaultTitle || config.chart_config.title}</VisualizationTitle>
              <VisualizationFilter />
              <VisualizationActions>
                <VisualizationAction onClick={() => setShowAsModal(false)}>
                  <Minimize color="#FFF" />
                </VisualizationAction>
                <VisualizationSettingsComponent
                  options={[
                    { value: "export_png", label: "Export Chart (.png)" },
                    {
                      value: "export_csv",
                      label: (
                        <CSVLink
                          filename={id + ".csv"}
                          data={filteredDataset && filteredDataset.data}
                          style={{
                            color: "#FFF",
                            padding: "",
                            fontfamily: "SF Display",
                            fontsize: "12px",
                            cursor: "pointer",
                          }}
                          id="test">
                          Export Chart (.csv)
                        </CSVLink>
                      ),
                    },
                    // {
                    //   value: "settings",
                    //   label: "Settings",
                    // },
                  ]}
                  onSelect={handleSelect}
                />
              </VisualizationActions>
            </VisualizationHeader>

            <VisualizationBody style={vizBodyStyle}>
              {!loading && dataset && (
                <Chart
                  dataset={filteredDataset}
                  settings={{
                    ...config.chart_config.settings,
                    ModalView: true,
                  }}
                />
              )}
            </VisualizationBody>
          </VisualizationModal>
        </VisualizationModalContainer>
      )}

      {showSettingsModal && (
        <ChartSettings
          id={id}
          onClose={(configuration) => {
            if (configuration) {
              setConfig({
                ...config,
                data_config_id: configuration.configId,
                chart_config: {
                  ...config.chart_config,
                  settings: configuration.settings,
                  title: configuration.title,
                  type: configuration.type,
                },
              });
            }
            setShowSettingsModal(false);
          }}
          {...config.chart_config}
          dataConfigId={config.data_config_id}
          dataset={filteredDataset}
          yoy={config.chart_config.yoy}
          agg={config.chart_config.agg}
          newColumns={config.chart_config.newColumns}
          template={template}
        />
      )}
    </VisualizationContainer>
  );
};

export default Visualization;
