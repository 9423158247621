import React, { useState, useEffect, useRef } from "react";
import {
  VisualizationFilterOptions,
  Button,
  VisualizationModalContainer,
  VisualizationModal,
  VisualizationBody,
  VisualizationTitle,
  VisualizationActions,
  SettingsWrapper,
  SettingsContainer,
  ChartTypesContainer,
  ChartSettingsContainer,
  PreviewContainer,
  ScrollContainer,
  VisualizationHeader,
} from "./styles";
import chartTypes from "./config";
import Charts from "../../charts";
import Menu from "../../../menu";
import { getAllConfigs } from "../../../../../services/config";
import { loadDataset } from "../../../../../services/visualization";
import { UserContext } from "@pai/providers/with-auth";

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

const ChartSettings = ({
  id,
  dataConfigId,
  title,
  type,
  settings,
  onClose,
  fData,
  yoy,
  template,
  agg,
  newColumns,
}) => {
  const [cAllConfigs, setCAllConfigs] = useState(null);
  const [cDataConfigId, setCDataConfigId] = useState(dataConfigId);
  const [cTitle, setCTitle] = useState(title);
  const [cType, setCType] = useState(type);
  const [cSettings, setCSettings] = useState(settings);
  const [cDataset, setCDataset] = useState(null);
  const [filterData, setFilterData] = useState(fData);
  const userState = React.useContext(UserContext);
  useEffect(() => {
    getAllConfigs().then((configs) => {
      setCAllConfigs(configs);
    });
  }, []);

  useEffect(() => {
    if (!cAllConfigs) return;

    let test = JSON.stringify(
      (cAllConfigs.filter((c) => c.key == cDataConfigId)[0] || { value: {} })
        .value
    );
    if (template) {
      Object.keys(template).forEach((key) => {
        test = test.replace(key, template[key]);
      });
    }

    loadDataset(
      cDataConfigId,
      JSON.parse(test),
      yoy,
      agg,
      newColumns,
      userState.token
    ).then((newData) => {
      setCDataset(newData);
      if (cSettings.filter) {
        const filterC = cSettings.filter;
        if (filterC.show && filterC.enabled & (filterC.type === "dimension")) {
          setFilterData({
            values: [
              ...new Set(
                newData.data.map((row) => row[newData.fields[filterC.dim]])
              ),
            ],
            currentValue: filterC.defaultValue,
          });
        }
      }
    });
  }, [cAllConfigs, cDataConfigId]);

  useDidMountEffect(() => {
    if (cSettings.filter) {
      const filterC = cSettings.filter;
      if (filterC.show && filterC.enabled & (filterC.type === "dimension")) {
        setFilterData({
          values: [
            ...new Set(
              cDataset.data.map((row) => row[cDataset.fields[filterC.dim]])
            ),
          ],
          currentValue: filterC.defaultValue,
        });
      }
    }
  }, [cSettings]);

  if (!cDataset) {
    return "";
  }

  let filteredDataset = { ...cDataset };

  if (cSettings && cSettings.filter && cDataset) {
    const filterConfig = cSettings.filter;

    if (filterConfig && filterConfig.enabled) {
      if (filterConfig.type === "dimension" && filterData) {
        filteredDataset = {
          ...cDataset,
          data: cDataset.data.filter(
            (row) =>
              row[cDataset.fields[filterConfig.dim]] ===
              filterData.values[filterData.currentValue]
          ),
        };
      }
      if (filterConfig.type === "count") {
        const sortValues = {
          ASC: [1, -1],
          DESC: [-1, 1],
        };
        const sortedData = cDataset.data.sort((a, b) =>
          a[cDataset.fields[filterConfig.dim]] >
          b[cDataset.fields[filterConfig.dim]]
            ? sortValues[filterConfig.sort][0]
            : sortValues[filterConfig.sort][1]
        );
        filteredDataset = {
          ...cDataset,
          data: sortedData.slice(0, filterConfig.count),
        };
      }
    }
  }

  const SettingsComponent = cType ? Charts[cType].Settings : "";
  const ChartComponent = cType ? Charts[cType].Chart : "";

  return (
    <VisualizationModalContainer>
      <VisualizationModal>
        <VisualizationBody>
          <SettingsWrapper>
            <SettingsContainer>
              <ChartTypesContainer>
                <VisualizationTitle>Chart Settings</VisualizationTitle>
                <ScrollContainer>
                  <Menu
                    highlighted
                    groups={chartTypes}
                    onClick={(key, menuItem) => {
                      if (Charts[key]) {
                        if (
                          !menuItem.compatible ||
                          menuItem.compatible.indexOf(cType) == -1
                        ) {
                          setCSettings(Charts[key].Config);
                        }
                        setCType(key);
                      }
                    }}
                    currentKey={cType}
                  />
                </ScrollContainer>
              </ChartTypesContainer>
              <ChartSettingsContainer>
                <ScrollContainer full>
                  {SettingsComponent && (
                    <SettingsComponent
                      dataConfigs={cAllConfigs}
                      dataConfigId={cDataConfigId}
                      fields={cDataset.fields}
                      settings={cSettings}
                      title={cTitle}
                      onChange={(data) => {
                        setCSettings(data.settings);
                        setCTitle(data.title);
                        setCDataConfigId(data.dataConfigId);
                      }}
                    />
                  )}
                </ScrollContainer>
              </ChartSettingsContainer>
            </SettingsContainer>
            <PreviewContainer>
              <VisualizationHeader>
                <VisualizationTitle>{cTitle}</VisualizationTitle>
                {filterData && cSettings.filter && cSettings.filter.show && (
                  <VisualizationFilterOptions
                    value={filterData.currentValue}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        currentValue: e.target.value,
                      });
                    }}
                  >
                    {filterData.values.map((v, i) => (
                      <option key={i} value={i}>
                        {v}
                      </option>
                    ))}
                  </VisualizationFilterOptions>
                )}
                <VisualizationActions>
                  <Button
                    primary
                    onClick={() => {
                      onClose &&
                        onClose({
                          title: cTitle,
                          type: cType,
                          settings: cSettings,
                          configId: cDataConfigId,
                        });
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    cancel
                    onClick={() => {
                      onClose && onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </VisualizationActions>
              </VisualizationHeader>
              <VisualizationBody>
                {ChartComponent && (
                  <ChartComponent
                    id={id}
                    dataset={filteredDataset}
                    settings={cSettings}
                  />
                )}
              </VisualizationBody>
            </PreviewContainer>
          </SettingsWrapper>
        </VisualizationBody>
      </VisualizationModal>
    </VisualizationModalContainer>
  );
};

export default ChartSettings;
