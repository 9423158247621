import React, { useRef, useLayoutEffect } from "react";
import { Typography, Box, Panel } from "@pai/library/base";
import { Grid, GridItem } from "./../../library/containers/grid";

//import { ReactComponent as ArrowRight } from "../../../assets/arrow.svg";

//components
import { Color } from "../../assets/generic-components/color-select/styles";
//import Visualization from "./../../assets/generic-components/custom-components/visualization";

//icons
import { Offer } from "@styled-icons/boxicons-solid/Offer";
import { LineChart } from "@styled-icons/boxicons-regular/LineChart";
import { Sitemap as NetworkChart } from "@styled-icons/boxicons-regular/Sitemap";
import { Mail } from "@styled-icons/feather/Mail";
import { Salesforce } from "@styled-icons/fa-brands/Salesforce";
import { Sync } from "@styled-icons/material/Sync";
import { ListCheck } from "@styled-icons/boxicons-regular/ListCheck";
import { Settings } from "@styled-icons/feather/Settings";
import { Target } from "@styled-icons/feather/Target";
import { DollarCircle } from "@styled-icons/boxicons-regular/DollarCircle";

const Animatron = ({ style, position }) => {
  const ref1 = useRef();

  const canvasRender = (ref) => {
    if (!ref) return;

    const c = ref.current;
    var w = (c.width = c.clientWidth),
      h = (c.height = c.clientHeight),
      ctx = c.getContext("2d"),
      opts = {
        len: 40,
        count: 10,
        baseTime: 10,
        addedTime: 10,
        dieChance: 0.2,
        spawnChance: 1,
        sparkChance: 0.1,
        sparkDist: 10,
        sparkSize: 2,
        color: "hsl(hue,100%,light%)",
        baseLight: 50,
        addedLight: 1, // [50-10,50+10]
        shadowToTimePropMult: 0,
        baseLightInputMultiplier: 0.005,
        addedLightInputMultiplier: 0.0,

        cx: w / position.x,
        cy: h / position.y,
        repaintAlpha: 0.04,
        hueChange: 0.1,
      },
      tick = 0,
      lines = [],
      dieX = w / 2 / opts.len,
      dieY = h / 2 / opts.len,
      baseRad = (Math.PI * 2) / 6;

    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, w, h);

    function loop() {
      window.requestAnimationFrame(loop);

      ++tick;

      ctx.globalCompositeOperation = "source-over";
      ctx.shadowBlur = 0;
      ctx.fillStyle = "rgba(0,0,0,alp)".replace("alp", opts.repaintAlpha);
      ctx.fillRect(0, 0, w, h);
      ctx.globalCompositeOperation = "lighter";

      if (lines.length < opts.count && Math.random() < opts.spawnChance)
        lines.push(new Line());

      lines.map(function (line) {
        line.step();
      });
    }
    function Line() {
      this.reset();
    }

    Line.prototype.reset = function () {
      this.x = 0;
      this.y = 0;
      this.addedX = 0;
      this.addedY = 0;

      this.rad = 0;

      this.lightInputMultiplier =
        opts.baseLightInputMultiplier +
        opts.addedLightInputMultiplier * Math.random();

      this.color = opts.color.replace("hue", tick * opts.hueChange);
      this.cumulativeTime = 0;

      this.beginPhase();
    };
    Line.prototype.beginPhase = function () {
      this.x += this.addedX;
      this.y += this.addedY;

      this.time = 0;
      this.targetTime = (opts.baseTime + opts.addedTime * Math.random()) | 0;

      this.rad += baseRad * (Math.random() < 0.5 ? 1 : -1);
      this.addedX = Math.cos(this.rad);
      this.addedY = Math.sin(this.rad);

      if (
        Math.random() < opts.dieChance ||
        this.x > dieX ||
        this.x < -dieX ||
        this.y > dieY ||
        this.y < -dieY
      )
        this.reset();
    };

    Line.prototype.step = function () {
      ++this.time;
      ++this.cumulativeTime;

      if (this.time >= this.targetTime) this.beginPhase();

      var prop = this.time / this.targetTime,
        wave = Math.sin((prop * Math.PI) / 2),
        x = this.addedX * wave,
        y = this.addedY * wave;

      ctx.shadowBlur = prop * opts.shadowToTimePropMult;
      ctx.fillStyle = ctx.shadowColor = this.color.replace(
        "light",
        opts.baseLight +
          opts.addedLight *
            Math.sin(this.cumulativeTime * this.lightInputMultiplier)
      );
      ctx.fillRect(
        opts.cx + (this.x + x) * opts.len,
        opts.cy + (this.y + y) * opts.len,
        2,
        2
      );

      if (Math.random() < opts.sparkChance)
        ctx.fillRect(
          opts.cx +
            (this.x + x) * opts.len +
            Math.random() * opts.sparkDist * (Math.random() < 0.5 ? 1 : -1) -
            opts.sparkSize / 2,
          opts.cy +
            (this.y + y) * opts.len +
            Math.random() * opts.sparkDist * (Math.random() < 0.5 ? 1 : -1) -
            opts.sparkSize / 2,
          opts.sparkSize,
          opts.sparkSize
        );
    };

    loop();
  };

  useLayoutEffect(() => {
    canvasRender(ref1);
  }, [ref1]);

  return <canvas ref={ref1} style={style} />;
};

const CenterBubble = ({ size, top, left, text, Icon }) => (
  <div
    style={{
      position: "absolute",
      width: size,
      height: size,
      top: top,
      left: left,
      borderRadius: "50%",
      background: "#111419",
      border: "2px solid #15afd075",
      boxShadow: "0px 0px 25px transparent",
    }}
  >
    <Box
      style={{ width: "100%", height: "100%" }}
      direction="v"
      align="center"
      justify="center"
    >
      <Panel grow paddingTop>
        <Box
          direction="v"
          style={{ width: "100%", height: "100%" }}
          justify="center"
          align="center"
        >
          <Icon color="#15afd0" width={30} />
        </Box>
      </Panel>
      <Panel
        paddingBottom
        style={{ width: size - 30, opacity: 0.75, marginTop: 5 }}
      >
        <Typography.Small
          muted
          align="center"
          block
          dangerouslySetInnerHTML={{ __html: text }}
        ></Typography.Small>
      </Panel>
    </Box>
  </div>
);

const CenterPiece = ({ size }) => (
  <Panel
    style={{
      width: size,
      height: size,
      borderRadius: "50%",
      background: "rgba(255,255,255,0.0)",
      position: "relative",
      border: "2px solid #15afd075",
      boxShadow: "0px 0px 25px transparent",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 40,
    }}
  >
    <div
      style={{
        position: "absolute",
        zIndex: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "cover",
        borderRadius: "50%",
        mixBlendMode: "screen",
        opacity: 0.66,
        overflow: "hidden",
        filter: "sepia(1) saturate(500%) hue-rotate(150deg)",
        display: "none",
      }}
    >
      <Animatron
        style={{ width: "100%", height: "100%" }}
        position={{ x: 2, y: size }}
      />
    </div>
    <div
      style={{
        position: "absolute",
        zIndex: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "cover",
        borderRadius: "50%",
        mixBlendMode: "screen",
        opacity: 0.66,
        overflow: "hidden",
        filter: "sepia(1) saturate(500%) hue-rotate(150deg)",
        display: "none",
      }}
    >
      <Animatron
        style={{ width: "100%", height: "100%" }}
        position={{ x: 1, y: 1 }}
      />
    </div>
    <div
      style={{
        position: "absolute",
        zIndex: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "cover",
        borderRadius: "50%",
        mixBlendMode: "screen",
        opacity: 0.66,
        overflow: "hidden",
        filter: "sepia(1) saturate(500%) hue-rotate(150deg)",
        display: "none",
      }}
    >
      <Animatron
        style={{ width: "100%", height: "100%" }}
        position={{ x: size, y: 1 }}
      />
    </div>
    <Box
      style={{ width: "100%", height: "100%", position: "relative", zIndex: 1 }}
      direction="v"
      align="center"
      justify="center"
    >
      <Typography.Heading1
        style={{ fontWeight: "bold", fontSize: 42, lineHeight: "42px" }}
      >
        Personalize.
        <Typography.Span colorStyle="primary" style={{ fontWeight: "bold" }}>
          AI
        </Typography.Span>
      </Typography.Heading1>
    </Box>
    <CenterBubble
      size={size / 4}
      top={-size / 8}
      left={(size - size / 4) / 2}
      text="Personalized messages & rewards"
      Icon={Offer}
    />
    <CenterBubble
      size={size / 4}
      top={size * 0.7}
      left={-size * 0.02}
      text="Marketing programming by tiers"
      Icon={NetworkChart}
    />
    <CenterBubble
      size={size / 4}
      top={size * 0.7}
      left={size * 0.78}
      text="Deliver measurable <br /> value"
      Icon={LineChart}
    />
  </Panel>
);

// const Menu = () => (
//   <Panel
//     padding
//     style={{
//       background: "#1a1e27",
//       position: "fixed",
//       top: 0,
//       left: 0,
//       right: 0,
//       zIndex: 9999,
//     }}
//   >
//     <Box
//       style={{ width: "100%", height: "100%" }}
//       direction="h"
//       justify="start"
//       align="center"
//     >
//       <Panel style={{ width: "33%" }}>
//         <Typography.Heading2>
//           P.
//           <Typography.Span colorStyle="primary" style={{ fontWeight: "bold" }}>
//             AI
//           </Typography.Span>
//         </Typography.Heading2>
//       </Panel>
//       <Panel grow>
//         <Box
//           style={{ width: "100%", height: "100%" }}
//           direction="h"
//           justify="center"
//           align="center"
//         >
//           {["Home", "About", "Features", "Platform"].map((link) => (
//             <Panel paddingHorizontal>
//               <Typography.Paragraph block muted>
//                 {link}
//               </Typography.Paragraph>
//             </Panel>
//           ))}
//         </Box>
//       </Panel>
//       <Panel style={{ width: "33%" }}>
//         <Box
//           style={{ width: "100%", height: "100%" }}
//           direction="h"
//           justify="end"
//           align="center"
//         >
//           {["Dashboard"].map((link) => (
//             <Panel paddingLeft>
//               <Typography.Paragraph block muted>
//                 {link}
//               </Typography.Paragraph>
//             </Panel>
//           ))}
//         </Box>
//       </Panel>
//     </Box>
//   </Panel>
// );

const IconWithText = ({ Icon, text, image }) => (
  <Panel style={{ maxWidth: 180, marginBottom: 10 }} padding>
    {!image && (
      <Icon
        width={40}
        color="#15afd0"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      />
    )}
    {image && (
      <img
        src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/landing/${Icon}.png`}
        width={40}
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      />
    )}
    <Panel paddingTop>
      <Typography.Small
        block
        muted
        align="center"
        style={{ lineHeight: "150%", opacity: 0.5 }}
      >
        {text}
      </Typography.Small>
    </Panel>
  </Panel>
);

const IconWithHeadingText = ({ Icon, heading, description, image }) => (
  <Panel paddingHorizontal style={{ marginBottom: 10 }}>
    {!image && (
      <Icon
        width={60}
        color="#15afd0"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
        }}
      />
    )}
    {image && (
      <img
        src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/landing/${Icon}.png`}
        width={60}
        style={{
          marginBottom: 20,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    )}
    <Panel>
      <Typography.Heading3
        align="center"
        style={{
          lineHeight: "150%",
          fontSize: 18,
          fontWeight: "bold",
          paddingBottom: 5,
        }}
      >
        {heading}
      </Typography.Heading3>
      {description && (
        <Typography.Paragraph
          muted
          style={{ lineHeight: "150%" }}
          align="center"
        >
          {description}
        </Typography.Paragraph>
      )}
    </Panel>
  </Panel>
);

const IconWithTextHorizontal = ({ Icon, text, image }) => (
  <Panel padding>
    <Box style={{ width: "100%", height: "100%" }} direction="h" align="center">
      {!image && (
        <Icon
          width={30}
          color="#15afd0"
          style={{ display: "block", flexShrink: 0 }}
        />
      )}
      {image && (
        <img
          src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/landing/${Icon}.png`}
          width={30}
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        />
      )}
      <Panel paddingLeft grow>
        <Typography.Paragraph
          muted
          style={{ lineHeight: "120%", fontSize: 12 }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></Typography.Paragraph>
      </Panel>
    </Box>
  </Panel>
);

const Container = ({ children, large, style }) => (
  <Panel
    style={{
      ...style,
      width: large ? "auto" : 1390,
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
    {children}
  </Panel>
);

const TakeAway = ({ children }) => (
  <Panel style={{ marginTop: 5 }}>
    <Typography.Heading3
      style={{ lineHeight: "120%", fontSize: 16.5 }}
      align="center"
    >
      {children}
    </Typography.Heading3>
  </Panel>
);

const TitleDescription = ({ title, description, large }) => (
  <Panel
    style={{
      maxWidth: large ? 979 : 767,
      marginRight: "auto",
      marginLeft: "auto",
    }}
  >
    {title && [
      <Typography.Heading2
        key={0}
        style={{
          lineHeight: "150%",
          fontWeight: "bold",
          fontSize: 22,
          textTransform: "uppercase",
        }}
        colorStyle="primary"
        align="center"
      >
        {title}
      </Typography.Heading2>,
      <div key={1} style={{ height: 5 }} />,
    ]}
    {description && (
      <Typography.Heading3
        muted
        style={{ lineHeight: "120%", fontSize: 18 }}
        align="center"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </Panel>
);

const Section = ({ children, dark, style }) => (
  <Panel
    style={{
      ...style,
      minHeight: "calc(111vh - 55px)",
      backgroundColor: dark ? "rgba(0,0,0,0.5)" : "transparent",
      marginLeft: "-20px",
      marginRight: "-20px",
      padding: "75px 0",
    }}
  >
    {children}
  </Panel>
);

const LandingPage = () => {
  return (
    <React.Fragment>
      <Section style={{ marginTop: -25, marginBottom: 0 }}>
        <Container>
          <TitleDescription
            title="AI is reshaping the world"
            description="Enterprise decision making is rapidly changing to exponentially faster and competitive solutions driven by modular AI"
          />
          <Panel style={{ margin: "20vh 0px 0px", width: "100%" }}>
            <Box
              style={{ width: "100%" }}
              direction="h"
              align="start"
              justify="between"
            >
              {/* <button style={{color:'black'}} onClick={()=> {throw new Error("News error!")}}>Throw Error</button> */}
              <Panel
                paddingVertical
                style={{
                  height: "100%",
                  position: "relative",
                  width: 260,
                  maxWidth: 260,
                }}
              >
                <IconWithHeadingText
                  Icon={"brain"}
                  image
                  heading="ML & AI WORLD"
                  description="Machine Learning (ML) and Artificial Intelligence (AI) systems are reshaping the world"
                />
              </Panel>
              <Panel
                paddingVertical
                style={{
                  height: "100%",
                  position: "relative",
                  width: 260,
                  maxWidth: 260,
                }}
              >
                <IconWithHeadingText
                  Icon={"road"}
                  image
                  heading="SMARTER, FASTER"
                  description="ML / AI systems are fast outpacing traditional analytics and decision making models"
                />
              </Panel>
              <Panel
                paddingVertical
                style={{ height: "100%", width: 260, maxWidth: 260 }}
              >
                <IconWithHeadingText
                  Icon={"modular"}
                  image
                  heading="MODULAR AI"
                  description="One-size does not fit all, Tailored ML / AI solutions are needed as generic engines are not solving the problem at hand"
                />
              </Panel>
            </Box>
          </Panel>
        </Container>
      </Section>
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      <Section>
        <Container>
          <TitleDescription
            title="Differentiator"
            description="Personalization is a key strategic lever on which brands can compete rather than purely on delivery time and price"
          />
          <Panel style={{ marginTop: "3vh" }}>
            <img
              src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/competition-3.svg"}
              width={320}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Panel>
          <Panel style={{ marginTop: "2vh" }}>
            <Box
              style={{ width: "100%" }}
              direction="h"
              align="start"
              justify="between"
            >
              <Panel
                paddingVertical
                style={{ height: "100%", width: 260, maxWidth: 260 }}
              >
                <IconWithHeadingText
                  Icon={"personalization"}
                  image
                  heading="PERSONALIZATION"
                  description="AI driven recommendations delivering 1:1 experiences, content and products"
                />
              </Panel>
              <Typography.Heading1
                muted
                colorStyle="primary"
                style={{ fontWeight: "bold", fontSize: 60, marginTop: 90 }}
              >
                &
              </Typography.Heading1>
              <Panel
                paddingVertical
                style={{ height: "100%", width: 260, maxWidth: 260 }}
              >
                <IconWithHeadingText
                  Icon={"reward"}
                  image
                  heading="RELEVANT REWARDS"
                  description="Personalized rewards and offers on items/ experiences that customers value"
                />
              </Panel>
              <img
                src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/arrow.png"}
                width="70"
                style={{ opacity: 0.75, marginTop: 90, display: "block" }}
              />
              <Panel
                paddingVertical
                style={{ height: "100%", width: 260, maxWidth: 260 }}
              >
                <IconWithHeadingText
                  Icon={"heart"}
                  image
                  heading="IMPROVED LOYALTY"
                  description="Better and personalized experiences and rewards help increase year on year customer engagement and loyalty"
                />
              </Panel>
            </Box>
          </Panel>
          <TakeAway>
            In a world where physical operations of many brands are going to be
            limited at least partially, personalized 1:1 interactions will drive
            value
          </TakeAway>
          <TakeAway>
            In the current economic situation, building these direct 1:1
            customer connections through personalization will likely determine
            which brands emerge stronger from the crisis
          </TakeAway>
        </Container>
      </Section>
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      <Section>
        <Container>
          <TitleDescription
            large
            title="Living in the era of ‘evolved customer’"
            description="Personalization is now an expectation from customers rather than nice to have"
          />
          <div style={{ height: 50 }} />
          {/* to be added Visualization data */}
          {/* <Panel paddingHorizontal>
        <Grid template={[["col-1", "col-2"]]}>
          <GridItem area="col-1">
            <Visualization
              id={"recommendation_bins"}
              height="320px"
              defaultConfig={{
                data_config: {},
                chart_config: {
                  title: "Consumers expect personalized digital experience",
                  type: "Bar",
                  settings: {
                    filter: {
                      type: "count",
                      show: false,
                      enabled: true,
                      sort: "DESC",
                      dim: 2,
                      count: 10,
                    },
                    axes: {
                      primary: {
                        show: false,
                      },
                      secondary: {
                        dim: 2,
                        show: false,
                      },
                    },
                    zeroReference: false,
                    orientation: "horizontal",
                    legend: {
                      show: true,
                      verticalAlign: "bottom",
                      align: "center",
                      layout: "horizontal",
                      additionalPadding: true,
                    },
                    primaryDim: 0,
                    plot: {
                      aggregation: "none",
                      series: [
                        {
                          dim: 1,
                          color: {
                            type: "constant",
                            value: 1,
                          },
                          axis: 0,
                          label: "B2C",
                          format: "0.0%",
                          labelPosition: "top",
                        },
                        {
                          dim: 2,
                          color: {
                            type: "constant",
                            value: 2,
                          },
                          axis: 0,
                          label: "B2B",
                          format: "0.0%",
                          labelPosition: "top",
                        },
                      ],
                    },
                  },
                },
              }}
              defaultData={{
                fields: ["product", "b2c", "b2b"],
                data: [
                  {
                    product:
                      "Expect companies to send, them personalized offers",
                    b2c: 0.57,
                    b2b: 0.78,
                  },
                  {
                    product:
                      "Say its absolutely critical, or very important for companies to, provide a personalized experience",
                    b2c: 0.59,






                    b2b: 0.7,
                  },
                  {
                    product:
                      "Are likely to switch, brands if a company doesn't, make an effort to personalize, communications to them",
                    b2c: 0.56,
                    b2b: 0.69,
                  },
                ],
              }}
            />
          </GridItem>
          <GridItem area="col-2">
            <Visualization
              id={"recommendation_bins"}
              height="320px"
              defaultConfig={{
                data_config: {},
                chart_config: {
                  title: "The value of personalization",
                  type: "Bar",
                  settings: {
                    filter: {
                      type: "count",
                      show: false,
                      enabled: true,
                      sort: "DESC",
                      dim: 2,
                      count: 10,
                    },
                    axes: {
                      primary: {
                        show: false,
                      },
                      secondary: {
                        dim: 2,
                        show: false,
                      },
                    },
                    primaryWidth: 125,
                    zeroReference: false,
                    orientation: "vertical",
                    legend: {
                      show: true,
                      verticalAlign: "bottom",
                      align: "center",
                      layout: "horizontal",
                      additionalPadding: true,
                    },
                    primaryDim: 0,
                    plot: {
                      aggregation: "none",
                      series: [
                        {
                          dim: 1,
                          color: {
                            type: "constant",
                            value: 1,
                          },
                          axis: 0,
                          label:
                            "consumers willing to pay 10% above price listed",
                          format: "0.0%",
                          labelPosition: "right",
                        },
                        {
                          dim: 2,
                          color: {
                            type: "constant",
                            value: 2,
                          },
                          axis: 0,
                          label:
                            "consumers willing to pay 20% above price listed",
                          format: "0.0%",
                          labelPosition: "right",
                        },
                      ],
                    },
                  },
                },
              }}
              defaultData={{
                fields: ["product", "purchase", "recommended"],
                data: [
                  { product: "Home goods", purchase: 0.76, recommended: 0.48 },
                  { product: "Fashion", purchase: 0.8, recommended: 0.48 },
                  { product: "Automotive", purchase: 0.69, recommended: 0.33 },
                  {
                    product: "Toys & Games",
                    purchase: 0.61,
                    recommended: 0.27,
                  },
                  { product: "Beauty", purchase: 0.71, recommended: 0.38 },
                  {
                    product: "Food & Beverages",
                    purchase: 0.64,
                    recommended: 0.3,
                  },
                  { product: "CPG", purchase: 0.72, recommended: 0.34 },
                  {
                    product: "Consumer Health",
                    purchase: 0.72,
                    recommended: 0.39,
                  },
                ],
              }}
            />
          </GridItem>
        </Grid>
      </Panel> */}
          <div style={{ height: 30 }} />
          <Panel>
            <Box
              style={{ width: "100%" }}
              direction="h"
              align="start"
              justify="between"
            >
              <Panel style={{ height: "100%", width: 260, maxWidth: 260 }}>
                <IconWithHeadingText
                  Icon={Target}
                  heading="Drive YoY Engagement"
                />
              </Panel>
              <Panel style={{ height: "100%", width: 260, maxWidth: 260 }}>
                <IconWithHeadingText
                  Icon={LineChart}
                  heading="Drive YoY Revenue"
                />
              </Panel>
              <Panel style={{ height: "100%", width: 260, maxWidth: 260 }}>
                <IconWithHeadingText Icon={DollarCircle} heading="Higher LTV" />
              </Panel>
            </Box>
          </Panel>
          <div style={{ height: 25 }} />
          <TakeAway>
            Personalization done right can drive double digit improvement in
            conversion &amp; can significantly improve year on year customer
            growth, incremental spend &amp; life time value across demographics
          </TakeAway>
        </Container>
      </Section>
      ,
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      ,
      <Section>
        <Container>
          <TitleDescription
            large
            title="What to watch out for"
            description="To get personalization right firms need to watch out for ease of use of the solution and whether the AI is purpose built to deliver the outcome of creating better 1:1 customer connections"
          />
          <div style={{ height: 20 }}></div>
          <Panel
            paddingHorizontal
            style={{ width: "100%", position: "relative" }}
          >
            <Box
              style={{ width: "100%" }}
              direction="h"
              align="center"
              justify="between"
            >
              <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/twoByTwo-3.svg"} width={560} />
              <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/branch-2.svg"} width={640} />
            </Box>
            <div style={{ height: 30 }}></div>
            <TakeAway>
              An AI first solution built with AI as an architecture approach is
              going to be more cost effective and not more expensive
            </TakeAway>
          </Panel>
        </Container>
      </Section>
      ,
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      ,
      <Section>
        <Container>
          <Panel
            padding
            background
            style={{
              width: "100%",
              position: "relative",
              background: "rgba(0,0,0,0)",
            }}
          >
            <Box
              style={{ width: "100%", height: "100%" }}
              direction="v"
              align="stretch"
              justify="between"
            >
              <TitleDescription
                large
                title="AI First Solution"
                description="A world class AI solution that provides member level recommendations optimized for rewards that can significantly improve YoY customer conversion, growth, revenue & LTV"
              />
              <Panel grow paddingBottom>
                <div
                  style={{
                    pointerEvents: "none",
                    position: "absolute",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    mixBlendMode: "screen",
                    opacity: 0.66,
                    overflow: "hidden",
                    filter: "sepia(1) saturate(500%) hue-rotate(150deg)",
                    display: "none",
                  }}
                >
                  <Animatron
                    style={{ width: "100%", height: "100%" }}
                    position={{ x: 1, y: 2 }}
                  />
                </div>
                <div
                  style={{
                    pointerEvents: "none",
                    position: "absolute",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    mixBlendMode: "screen",
                    opacity: 0.66,
                    overflow: "hidden",
                    filter: "sepia(1) saturate(500%) hue-rotate(150deg)",
                    display: "none",
                  }}
                >
                  <Animatron
                    style={{ width: "100%", height: "100%" }}
                    position={{ x: 1280, y: 2 }}
                  />
                </div>
                <Box
                  style={{ width: "100%", height: "100%" }}
                  direction="h"
                  align="center"
                  justify="between"
                >
                  <Panel style={{ marginLeft: 20 }} paddingHorizontal>
                    <IconWithText
                      Icon={"cloud-gather"}
                      image
                      text="Gather customer interactions and inventory from Cloud"
                    />
                    <IconWithText
                      Icon={"loyalty-rewards"}
                      image
                      text="Business protocols, marketing calendar and loyalty rewards"
                    />
                    <IconWithText
                      Icon={"stream"}
                      image
                      text="Stream user Digital activities -  App, Mobile, Web"
                    />
                  </Panel>
                  <Panel style={{ width: 100 }}>
                    {/* <ArrowRight
                  width={"30px"}
                  fill="#15afd0"
                  strokeWidth={1}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    opacity: 0.75,
                  }}
                /> */}
                  </Panel>
                  <Panel
                    paddingHorizontal
                    style={{ width: 540, height: 500, marginTop: 50 }}
                  >
                    <CenterPiece size={420} />
                  </Panel>
                  <Panel style={{ width: 100 }}>
                    {/* <ArrowRight
                  width={"30px"}
                  fill="#15afd0"
                  strokeWidth={1}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    opacity: 0.75,
                  }}
                /> */}
                  </Panel>
                  <Panel style={{ marginRight: 20 }} paddingHorizontal>
                    <IconWithText
                      Icon={"recommended"}
                      image
                      text="Recommended for you on Mobile App"
                    />
                    <IconWithText Icon={Mail} text="Delivered on Email" />
                    <IconWithText
                      Icon={Salesforce}
                      text="Direct integration with Client CRM like salesforce, Adobe marketing cloud"
                    />
                  </Panel>
                </Box>
              </Panel>
              <Panel>
                <Container large>
                  <Grid
                    template={[
                      [
                        "area-1",
                        "area-2",
                        "area-3",
                        "area-4",
                        "area-5",
                        "area-6",
                      ],
                    ]}
                  >
                    <GridItem area="area-1">
                      <IconWithTextHorizontal
                        Icon={"cloud"}
                        image
                        text="Cloud Native <br /> Solution"
                      />
                    </GridItem>
                    <GridItem area="area-2">
                      <IconWithTextHorizontal
                        Icon={Sync}
                        text="End-to-end <br /> Solutioning"
                      />
                    </GridItem>
                    <GridItem area="area-3">
                      <IconWithTextHorizontal
                        Icon={ListCheck}
                        text="Micro <br /> Experimentation"
                      />
                    </GridItem>
                    <GridItem area="area-4">
                      <IconWithTextHorizontal
                        Icon={NetworkChart}
                        text="Cross-functional  <br />Team Enablement"
                      />
                    </GridItem>
                    <GridItem area="area-5">
                      <IconWithTextHorizontal
                        Icon={"explainable-ai"}
                        image
                        text="Explainable <br /> AI"
                      />
                    </GridItem>
                    <GridItem area="area-6">
                      <IconWithTextHorizontal
                        Icon={Settings}
                        text="Auto-feature <br /> Engineering"
                      />
                    </GridItem>
                  </Grid>
                </Container>
              </Panel>
            </Box>
          </Panel>
        </Container>
      </Section>
      ,
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      ,
      <Section>
        <Container>
          <Panel>
            <Grid template={[["col-0"]]}>
              <GridItem area="col-0">
                <TitleDescription
                  large
                  title="Integrated data science, operationalization & executive views"
                  description="Allows for seamless collaboration & better adoption across functions."
                />
                <div style={{ height: 20 }} />
              </GridItem>
            </Grid>
            <Panel paddingTop style={{ display: "none" }}>
              <Box
                direction="h"
                style={{ width: "100%", height: "100%" }}
                justify="center"
                align="stretch"
              >
                <Panel style={{ position: "relative", flexGrow: 1 }}>
                  <Panel
                    background
                    padding
                    style={{
                      width: 480,
                      position: "absolute",
                      right: 30,
                      top: 360,
                      background: "transparent",
                      border: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    <ul
                      className="line-list"
                      style={{ margin: 0, listStyleType: "none" }}
                    >
                      <li>State of the art machine / deep learning models</li>
                      <li>Configure models as per changing business need</li>
                      <li>
                        Modules: LifeStage models, @Risk, Item recommendation,
                        offer optimization, A/B Testing
                      </li>
                    </ul>
                  </Panel>
                </Panel>
                <Panel
                  style={{
                    width: 500,
                    height: 500,
                    position: "relative",
                    flexShrink: 0,
                  }}
                >
                  <Triangle size={500} position={{ top: "50%", left: 0 }}>
                    <Typography.Heading3
                      style={{ fontWeight: "bold" }}
                      align="center"
                    >
                      Data Science
                    </Typography.Heading3>
                  </Triangle>
                  <Triangle size={500} position={{ top: "50%", right: 0 }}>
                    <Typography.Heading3
                      style={{
                        fontWeight: "bold",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                      align="center"
                    >
                      Operationalization
                    </Typography.Heading3>
                  </Triangle>
                  <Triangle size={500} position={{ top: 0, left: "25%" }}>
                    <Typography.Heading3
                      style={{
                        fontWeight: "bold",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                      align="center"
                    >
                      Executive <br />
                      Views
                    </Typography.Heading3>
                  </Triangle>
                  <Triangle
                    size={500}
                    position={{ top: "50%", left: "25%" }}
                    invert
                    background={Color[2]}
                  >
                    <Typography.Heading3
                      style={{ fontWeight: "bold" }}
                      align="center"
                    >
                      Personalize.AI
                    </Typography.Heading3>
                  </Triangle>
                </Panel>
                <Panel
                  style={{ position: "relative", flexGrow: 1, maxWidth: 480 }}
                >
                  <Panel
                    background
                    padding
                    style={{
                      width: 480,
                      position: "absolute",
                      left: 10,
                      top: 100,
                      background: "transparent",
                      border: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    <ul
                      className="line-list"
                      style={{ margin: 0, listStyleType: "none" }}
                    >
                      <li>
                        Quick summary of overall business, upcoming opportunity
                        at a customer level
                      </li>
                      <li>Results from prior actions/recommendations</li>
                      <li>Compelling visualization of business trends</li>
                    </ul>
                  </Panel>
                  <Panel
                    background
                    padding
                    style={{
                      maxWidth: 380,
                      position: "absolute",
                      left: 10,
                      top: 375,
                      background: "transparent",
                      border: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    <ul
                      className="line-list"
                      style={{ margin: 0, listStyleType: "none" }}
                    >
                      <li>Integrate with existing CRM systems</li>
                      <li>Reporting/analytics</li>
                      <li>Personalized messaging</li>
                    </ul>
                  </Panel>
                </Panel>
              </Box>
            </Panel>
            <div style={{ height: 20 }} />
            <Panel>
              <img
                src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/petal-2.svg"}
                width={1024}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Panel>
          </Panel>
        </Container>
      </Section>
      ,
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      ,
      <Section>
        <Container>
          <Panel paddingVertical>
            <TitleDescription
              large
              title="Leaders like Amazon, Spotify, Netflix & Starbucks"
              description="Have majority of their transactions powered through personalization"
            />
            <div style={{ height: 20 }} />
            <Panel paddingTop>
              <Grid
                template={[
                  [
                    "col-0",
                    "col-0",
                    "col-1",
                    "col-1",
                    "col-1",
                    "col-1",
                    "col-1",
                  ],
                ]}
              >
                <GridItem area="col-0">
                  <Panel>
                    <Panel padding background style={{ marginBottom: 20 }}>
                      <Typography.Heading3
                        style={{ lineHeight: "125%" }}
                        colorStyle="primary"
                      >
                        Amazon
                      </Typography.Heading3>
                      <Typography.Paragraph
                        muted
                        style={{ lineHeight: "125%" }}
                      >
                        Recommendation engine powering 35% of conversions
                      </Typography.Paragraph>
                    </Panel>
                    <Panel padding background style={{ marginBottom: 20 }}>
                      <Typography.Heading3
                        style={{ lineHeight: "125%" }}
                        colorStyle="primary"
                      >
                        Starbucks
                      </Typography.Heading3>
                      <Typography.Paragraph
                        muted
                        style={{ lineHeight: "125%" }}
                      >
                        Incredibly successful loyalty program (campaign
                        effectiveness 3x, 2x email redemptions, 3x increase in
                        spends)
                      </Typography.Paragraph>
                    </Panel>
                    <Panel padding background style={{ marginBottom: 20 }}>
                      <Typography.Heading3
                        style={{ lineHeight: "125%" }}
                        colorStyle="primary"
                      >
                        Spotify
                      </Typography.Heading3>
                      <Typography.Paragraph
                        muted
                        style={{ lineHeight: "125%" }}
                      >
                        {">"} 140 million active users, over 5 billion streams
                        ‘Discover Weekly’ feature is a massive hit
                      </Typography.Paragraph>
                    </Panel>
                    <Panel padding background>
                      <Typography.Heading3
                        style={{ lineHeight: "125%" }}
                        colorStyle="primary"
                      >
                        Netflix
                      </Typography.Heading3>
                      <Typography.Paragraph
                        muted
                        style={{ lineHeight: "125%" }}
                      >
                        {">"} 100 million users on its platform, more than 75%
                        of site activity driven by their personalization engine
                      </Typography.Paragraph>
                    </Panel>
                  </Panel>
                </GridItem>
                <GridItem area="col-1">
                  <Panel
                    style={{ height: "100%", position: "relative" }}
                    background
                    paddingBottom
                  >
                    <Typography.Paragraph
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        transform: "rotate(-90deg)",
                      }}
                    >
                      Revenue
                    </Typography.Paragraph>
                    {/* to be changed */}
                    {/* <Visualization
                  id={"recommendation_bins"}
                  fullHeight
                  defaultConfig={{
                    data_config: {},
                    chart_config: {
                      title: "",
                      type: "Line",
                      settings: {
                        filter: {
                          type: "count",
                          show: false,
                          enabled: true,
                          sort: "DESC",
                          dim: 2,
                          count: 10,
                        },
                        axes: {
                          primary: {
                            show: true,
                            hideTicks: true,
                            label: "Revenue",
                          },
                          secondary: {
                            dim: 2,
                            show: false,
                          },
                        },
                        zeroReference: false,
                        orientation: "horizontal",
                        legend: {
                          show: false,
                          verticalAlign: "bottom",
                          align: "center",
                          layout: "horizontal",
                          additionalPadding: true,
                        },
                        primaryDim: 0,
                        plot: {
                          aggregation: "none",
                          series: [
                            {
                              dim: 1,
                              color: {
                                type: "constant",
                                value: 2,
                              },
                              axis: 0,
                              label: "B2C",
                              format: "",
                              labelPosition: "top",
                              hideLabel: true,
                            },
                          ],
                        },
                      },
                    },
                  }}
                  defaultData={{
                    fields: ["maturity", "revenue"],
                    data: [
                      { maturity: " ", revenue: 40 },
                      { maturity: "Single Message, Mailing", revenue: 43 },
                      { maturity: "Field Insertion", revenue: 55 },
                      { maturity: "Segmentation Rules, Based", revenue: 80 },
                      { maturity: "Behavioral, Recommendations", revenue: 150 },
                      { maturity: "Omni-channel, optimized", revenue: 300 },
                      { maturity: "Predictive, Personalization", revenue: 600 },
                      { maturity: "", revenue: null },
                    ],
                  }}
                /> */}
                    <div
                      style={{ position: "absolute", right: "23%", top: "20%" }}
                    >
                      <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/amazon.png"} width="75" />
                    </div>
                    <div
                      style={{ position: "absolute", right: "10%", top: "30%" }}
                    >
                      <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/spotify.png"} width="75" />
                    </div>
                    <div
                      style={{ position: "absolute", right: "8%", top: "14%" }}
                    >
                      <img src={process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/starbucks.png"} width="40" />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        width: 1,
                        height: "35%",
                        top: "50%",
                        left: "46.7%",
                        borderLeft: "1px dashed rgba(255,255,255,0.25)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        width: "38.2%",
                        height: 2,
                        top: "49.3%",
                        left: "8.6%",
                        borderBottom: "1px dashed rgba(255,255,255,0.25)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        width: "38.2%",
                        height: 18,
                        top: "calc(49.3% - 18px)",
                        left: "8.6%",
                      }}
                    >
                      <Typography.Small align="center" block>
                        This is where most businesses lie
                      </Typography.Small>
                    </div>
                    <Typography.Paragraph align="center">
                      Personalization Maturity
                    </Typography.Paragraph>
                  </Panel>
                </GridItem>
              </Grid>
            </Panel>
          </Panel>
        </Container>
      </Section>
      ,
      <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
      ></div>
      ,
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          backgroundImage: "url("+process.env.REACT_APP_CLOUDFRONT_CDN+"/images/landing/contact.jpg)",
          backgroundSize: "contain",
          backgroundPosition: "center right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Panel>
            <Panel>
              <Grid
                template={[
                  [
                    "col-0",
                    "col-0",
                    "col-0",
                    "col-1",
                    "col-1",
                    "col-1",
                    "col-1",
                  ],
                ]}
              >
                <GridItem area="col-0">
                  <Panel>
                    <Typography.Heading1 style={{ lineHeight: "120%" }}>
                      Build better 1:1 customer connections with Personalize.AI
                      and drive double digit conversion &amp; revenue growth.
                      Talk to us for a 90 day pilot.
                    </Typography.Heading1>
                  </Panel>
                </GridItem>
              </Grid>
            </Panel>
          </Panel>
        </Container>
      </Section>
      ,
    </React.Fragment>
  );
};

const Triangle = ({ position, invert, size, background, children }) => (
  <Panel
    style={{
      ...position,
      position: "absolute",
      width: size / 2,
      height: size / 2,
    }}
  >
    <svg
      viewBox={`0 0 ${size / 2} ${size / 2}`}
      width={size / 2}
      height={size / 2}
      style={{
        position: "absolute",
        zIndex: 0,
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
    >
      {!invert && (
        <polygon
          points={`${size / 4},0 ${size / 2},${size / 2} 0,${size / 2}`}
          strokeWidth={2}
          stroke="#15afd0"
          fill={background || "transparent"}
        ></polygon>
      )}
      {invert && (
        <polygon
          points={`0,0 ${size / 2},0 ${size / 4},${size / 2}`}
          strokeWidth={2}
          stroke="#15afd0"
          fill={background || "transparent"}
        ></polygon>
      )}
    </svg>
    <Box
      direction="v"
      style={{
        width: "100%",
        height: invert ? "80%" : "100%",
        marginTop: invert ? 0 : "15%",
        position: "relative",
        zIndex: 1,
      }}
      justify="center"
      align="center"
    >
      {children}
    </Box>
  </Panel>
);

export default LandingPage;
