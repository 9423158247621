/* eslint-disable */
import React from "react";
import ColorSelect, { Color } from "../../../color-select";
import Toggle from "../../../toggle";
import {
  SettingsGroup,
  Setting,
  SettingLabel,
  SettingSelect,
  SettingText,
  SettingsGroupTitle,
  Button,
} from "../../../settings";

const FilterSettings = ({
  fields,
  show,
  enabled,
  dim,
  sort,
  type,
  defaultValue,
  count,
  onChange,
}) => (
  <SettingsGroup>
    <SettingsGroupTitle small>Filter</SettingsGroupTitle>
    <Setting>
      <SettingLabel>Enabled</SettingLabel>
      <Toggle
        checked={enabled}
        onChange={(e) => {
          onChange({
            enabled: !enabled,
            dim,
            show,
            sort,
            type,
            defaultValue,
            count,
          });
        }}
      />
    </Setting>
    <Setting>
      <SettingLabel>Filtering Type</SettingLabel>
      <SettingSelect
        value={type}
        onChange={(e) => {
          onChange({
            enabled,
            dim,
            show,
            sort: "DESC",
            type: e.target.value,
            defaultValue,
            count: 5,
          });
        }}
      >
        <option value="dimension">By Dimension</option>
        <option value="count">Top X</option>
      </SettingSelect>
    </Setting>
    <Setting>
      <SettingLabel>{type == "count" && "Sort by "}Dimension</SettingLabel>
      <SettingSelect
        value={dim}
        onChange={(e) => {
          onChange({
            enabled,
            dim: e.target.value,
            show,
            sort,
            type,
            defaultValue,
            count,
          });
        }}
      >
        {fields.map((field, index) => (
          <option value={index}>{field}</option>
        ))}
      </SettingSelect>
    </Setting>
    {type === "count" && (
      <Setting>
        <SettingLabel>Sort Order</SettingLabel>
        <SettingSelect
          value={sort}
          onChange={(e) => {
            onChange({
              enabled,
              dim,
              show,
              sort: e.target.value,
              type,
              defaultValue,
              count,
            });
          }}
        >
          <option value="ASC">Ascending</option>
          <option value="DESC">Descending</option>
        </SettingSelect>
      </Setting>
    )}
    {type === "count" && (
      <Setting>
        <SettingLabel>Count</SettingLabel>
        <SettingText
          value={count}
          onChange={(e) => {
            onChange({
              enabled,
              dim,
              show,
              sort,
              type,
              defaultValue,
              count: e.target.value,
            });
          }}
        />
      </Setting>
    )}
  </SettingsGroup>
);

export default ({
  dataConfigId,
  dataConfigs,
  fields,
  title,
  settings,
  onChange,
}) => {
  const generateUpdatedSettings = (newSettings) => ({
    dataConfigId,
    title,
    settings: newSettings,
  });

  const generateUpdatedTitle = (newTitle) => ({
    title: newTitle,
    dataConfigId,
    settings,
  });

  const generateUpdatedDataConfigId = (newDataConfigId) => ({
    title,
    dataConfigId: newDataConfigId,
    settings,
  });

  return (
    <div>
      <SettingsGroup>
        <SettingsGroupTitle small>Data</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Data Frame</SettingLabel>
          <SettingSelect
            value={dataConfigId}
            onChange={(e) => {
              onChange(generateUpdatedDataConfigId(e.target.value));
            }}
          >
            {dataConfigs &&
              dataConfigs.map((c) => <option value={c.key}>{c.key}</option>)}
          </SettingSelect>
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Basic</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Chart Title</SettingLabel>
          <SettingText
            value={title}
            onChange={(e) => {
              onChange(generateUpdatedTitle(e.target.value));
            }}
          />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Axes</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Rows</SettingLabel>
          <SettingSelect
            value={settings.axes.row.dim}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    row: {
                      dim: e.target.value,
                    },
                  },
                })
              );
            }}
          >
            {fields.map((field, index) => (
              <option value={index}>{field}</option>
            ))}
          </SettingSelect>
        </Setting>
        <Setting>
          <SettingLabel>Columns</SettingLabel>
          <SettingSelect
            value={settings.axes.column.dim}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  axes: {
                    ...settings.axes,
                    column: {
                      dim: e.target.value,
                    },
                  },
                })
              );
            }}
          >
            {fields.map((field, index) => (
              <option value={index}>{field}</option>
            ))}
          </SettingSelect>
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Display</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Format</SettingLabel>
          <SettingText
            value={settings.format}
            onChange={(e) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  format: e.target.value,
                })
              );
            }}
          />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <SettingsGroupTitle small>Heat Map</SettingsGroupTitle>
        <Setting>
          <SettingLabel>Min</SettingLabel>
          <ColorSelect
            activeIndex={settings.heatRange.start}
            onSelect={(colorIndex) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  heatRange: {
                    ...settings.heatRange,
                    start: colorIndex,
                  },
                })
              );
            }}
          />
        </Setting>
        <Setting>
          <SettingLabel>Max</SettingLabel>
          <ColorSelect
            activeIndex={settings.heatRange.end}
            onSelect={(colorIndex) => {
              onChange(
                generateUpdatedSettings({
                  ...settings,
                  heatRange: {
                    ...settings.heatRange,
                    end: colorIndex,
                  },
                })
              );
            }}
          />
        </Setting>
      </SettingsGroup>
    </div>
  );
};
