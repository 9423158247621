/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { Color } from "../../../color-select";
import { Wrapper } from "../../components";
// import { ConvertToVizData } from "./../../../../../library/utils/yoy-transformer";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

function pickHex(weight, c1, c2) {
  var w1 = weight;
  var w2 = 1 - w1;
  var color2 = hexToRgb(c2);
  var color1 = hexToRgb(c1);
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export default ({ id, dataset, settings }) => {
  const {
    primaryDim,
    axes,
    format,
    heatRange,
    highlight,
    zoom,
    labelSettings,
    wrapperHeight,
    ModalView,
  } = settings;

  const xField = dataset.fields[axes.column.dim];
  const xFieldToolTip = dataset.fields[axes.column.tooltipDim];

  const yField = dataset.fields[axes.row.dim];
  const yFieldToolTip = dataset.fields[axes.row.tooltipDim];

  const plotField = dataset.fields[primaryDim];

  const uniqueXValues = [...new Set(dataset.data.map((r) => r[xField]))];
  const uniqueXToolTipValues = [
    ...new Set(dataset.data.map((r) => r[xFieldToolTip])),
  ];

  const uniqueYValues = [...new Set(dataset.data.map((r) => r[yField]))];
  const uniqueYToolTipValues = [
    ...new Set(dataset.data.map((r) => r[yFieldToolTip])),
  ];

  const plotRange = [...new Set(dataset.data.map((r) => r[plotField]))];

  const minPlot = Math.min(...plotRange);
  const maxPlot = Math.max(...plotRange);

  return (
    <Wrapper>
      {({ width, height }) => (
        <div
          style={{
            padding: "0px 20px 15px",
            width,
            overflowX: "auto",
            height: ModalView ? height : wrapperHeight ? wrapperHeight : height,
            overflowX: "auto",
          }}
        >
          {/* {console.log('heat height', height)} */}
          <table
            width="100%"
            cellSpacing={0}
            cellPadding={0}
            style={{
              // fontFamily: "SF Text",
              //fontWeight: 200,
              color: "rgba(255,255,255,0.66)",
              zoom: zoom ? zoom : 0.8,
            }}
          >
            <thead>
              <tr>
                <th
                  style={{ borderRight: "1px solid rgba(255,255,255,0.1)" }}
                ></th>
                {uniqueXValues.map((c, colIndex) => (
                  <th
                    style={{
                      padding: "10px",

                      border: "1px solid rgba(255,255,255,0.1)",
                      borderLeft:
                        colIndex === highlight ? "3px solid #FFF" : "0px",
                      borderRight:
                        colIndex === highlight ? "3px solid #FFF" : undefined,
                      borderTop:
                        colIndex === highlight ? "3px solid #FFF" : undefined,
                      fontSize:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.fontSize
                          ? labelSettings.xAxis.fontSize
                          : 11,
                      textAlign:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.textAlign
                          ? labelSettings.xAxis.textAlign
                          : "left",

                      zoom:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.zoom,
                    }}
                  >
                    {labelSettings &&
                    labelSettings.xAxis &&
                    labelSettings.xAxis.labelFunct
                      ? labelSettings.xAxis.labelFunct(
                          c,
                          uniqueXToolTipValues[colIndex]
                        )
                      : c}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {uniqueYValues.map((r, rowIndex) => (
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid rgba(255,255,255,0.1)",
                      height: 50,
                      fontSize:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.fontSize
                          ? labelSettings.yAxis.fontSize
                          : 11,
                      textAlign:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.textAlign
                          ? labelSettings.yAxis.textAlign
                          : "left",

                      zoom:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.zoom,

                      fontWeight:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.fontWeight,

                      worBreak:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.worBreak,

                      width:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.tdWidth,
                    }}
                  >
                    {labelSettings &&
                    labelSettings.yAxis &&
                    labelSettings.yAxis.labelFunct
                      ? labelSettings.yAxis.labelFunct(
                          r,
                          uniqueYToolTipValues[rowIndex]
                        )
                      : r}
                  </td>

                  {uniqueXValues.map((c, colIndex) => {
                    const accessor = dataset.data.filter(
                      (d) => d[yField] === r && d[xField] === c
                    )[0];
                    if (!accessor || accessor[plotField] === null) {
                      return <td style={{ textAlign: "center" }}>-</td>;
                    }

                    const value = accessor ? accessor[plotField] : 0;

                    const weight = (value - minPlot) / (maxPlot - minPlot);
                    const color = pickHex(
                      weight,
                      Color[heatRange.end],
                      Color[heatRange.start]
                    );

                    return (
                      <td
                        style={{
                          background: !accessor
                            ? `rgba(255,255,255,0.01)`
                            : `rgba(${color[0]},${color[1]},${color[2]},0.7)`,
                          padding: "10px",
                          textAlign: "center",
                          fontSize: 13,
                          border: "0px solid rgba(255,255,255,0.1)",
                          borderLeft:
                            colIndex === highlight ? "3px solid #FFF" : "0px",
                          borderRight:
                            colIndex === highlight
                              ? "3px solid #FFF"
                              : undefined,
                          borderBottom:
                            rowIndex == uniqueYValues.length - 1 &&
                            colIndex === highlight
                              ? "3px solid #FFF"
                              : undefined,
                          borderTop: "0px",
                          height: 50,
                        }}
                      >
                        {!accessor
                          ? "-"
                          : numeral(value).format(format)?.toUpperCase()}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Wrapper>
  );
};
