import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WaveLoader from "@pai/assets/generic-components/wave-loader/WaveLoader.jsx"

const ChartContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ChartWrapper = ({ children, className, style }) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    if (!dimensions)
      setTimeout(() => {
        if (containerRef.current) {
          setDimensions({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
          });
        }
      }, 600);
  }, [dimensions]);

  const resizeHandler = () => {
    setDimensions(null);
  };

  useEffect(() => {
    // resizeHandler();
    window.addEventListener("customResize", resizeHandler, false);
    return () => {
      window.removeEventListener("customResize", resizeHandler);
    };
  }, [containerRef.current]);

  return (
    <ChartContainer ref={containerRef} className={className} style={style}>
      {dimensions && children(dimensions)}
      {!dimensions && <WaveLoader />}
    </ChartContainer>
  );
};

export default ChartWrapper;
