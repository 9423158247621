/* eslint-disable react/display-name */
import React from "react";
import { msalApp, GRAPH_REQUESTS } from "./auth-provider";
import * as AuthService from "@pai/services/auth";
import * as Config from "@pai/library/config";
export const UserContext = React.createContext();

const initialState = {
  user: undefined,
  identity: undefined,
  loading: true,
  error: undefined,
  token: undefined,
  role: undefined,
  format: undefined,
  status: undefined,
  country:undefined,
  currency:undefined,
  objectiveModule: {
    objectiveMenuList: null,
    selectedObjective: null,
  },
};

const getPermissions = (role) => {
  let permissions = role.permissions;

  return permissions;
};

const selectRole = (user, roleId) => {
  const allRoles = user.access;
  const role = allRoles.find((r) => r.id === roleId);
  return {
    role: { id: role.id, name: role.name },
    permissions: getPermissions(role),
  };
};


const userReducer = (state, action) => {
  switch (action.type) {
    case "identity":
      return {
        ...state,
        identity: action.payload,
      };
    case "role":
      return {
        ...state,
        role: selectRole(state.user, action.payload),
      };
    case "user":
      return {
        ...state,
        user: action.payload.user,
        format: action.payload.format,
        status: action.payload.status,
        country:action.payload.country,
        currency:action.payload.currency,
        loading: false,
        error: undefined,
        token: action.payload.token,
        role: selectRole(action.payload.user, action.payload.user.access[0].id),
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };
    case "logout":
      msalApp.logout();
      return {
        ...state,
        user: undefined,
      };
    case "format":
      return {
        ...state,
        format: action.payload,
      };
    case "objectiveModule":
      return {
        ...state,
        objectiveModule: {
          ...state.objectiveModule,
          ...action.payload,
        },
      };
  }
};

export default (App) => {
  return (props) => {
    const [state, dispatch] = React.useReducer(userReducer, initialState);

    const onSignIn = async () => {
      return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
    };

    const onMount = async () => {
      if (!localStorage.getItem("currUrl", window.location.pathname)) {
        localStorage.setItem("currUrl", window.location.pathname);
      }

      msalApp.handleRedirectCallback(async (error, response) => {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : "Unable to access token";
          dispatch({ type: "error", payload: errorMessage });

          return;
        }
        if (response.tokenType === "id_token") {
          window.sessionStorage.setItem(
            "tempAccount",
            JSON.stringify(msalApp.getAccount())
          );
          dispatch({ type: "identity", payload: response.account });
          const user = await AuthService.getUserFromOpenIDToken(
            Config.AUTHORIZATION_MODE === "email"
              ? null
              : response.idToken.rawIdToken,
            response.account.userName
          );

          if (user.error) {
            dispatch({ type: "error", payload: user.error });
          } else {
            dispatch({
              type: "user",
              payload: user,
            });
          }
        }
      });

      setTimeout(async () => {
        if (process.env.REACT_APP_IS_AD_REQUIRED.toLocaleLowerCase() === "true") {
          let account = null;

          console.log(
            "auth local storage",
            window.sessionStorage.getItem("tempAccount")
          );
          if (
            !window.sessionStorage.getItem("tempAccount") ||
            window.sessionStorage.getItem("tempAccount") == null
          ) {
            account = msalApp.getAccount();
            console.log("auth local storage saving", account);
            window.sessionStorage.setItem(
              "tempAccount",
              JSON.stringify(account)
            );
          } else {
            account = JSON.parse(window.sessionStorage.getItem("tempAccount"));
          }
          if (!account) {
            onSignIn();
          } else {
            dispatch({ type: "identity", payload: account });
            const user = await AuthService.getUserFromOpenIDToken(
              Config.AUTHORIZATION_MODE === "email"
                ? null
                : window.sessionStorage.getItem("msal.idtoken"),
              account.userName
            );

            if (user.error) {
              dispatch({ type: "error", payload: user.error });
            } else {
              dispatch({
                type: "user",
                payload: user,
              });
            }
          }
        } else {
          dispatch({ type: "identity", payload: AuthService.getAccount() });
          const user = await AuthService.getUserFromOpenIDToken();
          if (user.error) {
            dispatch({ type: "error", payload: user.error });
          } else {
            dispatch({
              type: "user",
              payload: user,
            });
          }
        }
      }, 100);
    };

    React.useEffect(() => {
      onMount();
      document.body.style.setProperty(
        "--cdn-url",
        process.env.REACT_APP_CLOUDFRONT_CDN
      );
    }, []);

    return (
      <UserContext.Provider value={{ ...state, dispatch }}>
        <App {...props} />
      </UserContext.Provider>
    );
  };
};
