import styled from "styled-components";

export const SettingsGroup = styled.div`
  margin-bottom: 15px;
`;

export const Setting = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SettingLabel = styled.p`
  margin: 0;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.66);
  font-family: SF Display;
  font-size: 12px;
`;

export const SettingSelect = styled.select`
  font-family: SF Display;
  margin: 0;
  border: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.66);
  text-align: right;

  > option {
    font-family: SF Display;
    background: #000;
    color: rgba(255, 255, 255, 0.66);
    text-align: left;
  }
`;

export const SettingText = styled.input`
  font-family: SF Display;
  margin: 0;
  border: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.66);
  text-align: right;
`;

export const SettingsGroupTitle = styled.h4`
  font-family: SF Display;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  padding: 22px 18px 12px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  font-family: SF Display;
  font-size: 10px;
  line-height: 100%;
  border: 0px;
  color: #000;
  background: #15afd0;
  padding: 5px 8px;
  border-radius: 10px;
`;
