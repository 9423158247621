import React from "react";
import { Legend } from "recharts";

const CustomLegend = ({ legendSettings, formatter, orientation }) => {
  if (!legendSettings || !legendSettings.show) {
    return "";
  }
  return (
    <Legend
      verticalAlign={legendSettings.verticalAlign}
      align={legendSettings.align}
      layout={legendSettings.layout}
      formatter={formatter}
      wrapperStyle={
        legendSettings.top
          ? {
              marginTop:
                orientation === "none"
                  ? 0
                  : orientation === "vertical"
                  ? 0
                  : -15,
              marginLeft: 5,
              top: legendSettings.top,
              width: "calc(100% - 2px)",
              bottom: legendSettings.additionalPadding ? 15 : undefined,
            }
          : {
              marginTop:
                orientation === "none"
                  ? 0
                  : orientation === "vertical"
                  ? 0
                  : -25,
              marginLeft: 5,
              width: "calc(100% - 2px)",
              bottom: legendSettings.additionalPadding ? 15 : undefined,
            }
      }
    />
  );
};

export default CustomLegend;
