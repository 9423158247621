export default {
  axes: {
    primary: {
      dim: 1,
      show: true,
      label: "",
      unit: "",
    },
    secondary: {
      show: true,
      label: "",
      unit: "",
    },
  },
  legend: {
    show: false,
    verticalAlign: "top",
    align: "left",
    layout: "horizontal",
  },
  plot: {
    series: [],
  },
};
