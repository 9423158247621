import { useMemo } from "react";
import { isNumber, toNumber } from "lodash";

const useCalculatedAxisDim = (primaryDim, dataset) => {
  const calculatedPrimaryDim = useMemo(() => {
    // return primaryDim;

    const { fields, data } = dataset;
    let maxFieldVal = 0;
    let maxFieldKey;

    for (let d of data) {
      let maxVal = 0;
      let maxKey;
      Object.entries(d).forEach(([key, val]) => {
        console.log("PAI LINE useCalculatedPrimaryDim ", { key, val });
        if (isNumber(val) && toNumber(val) > maxVal) {
          maxVal = val;
          maxKey = key;
        }
      });

      console.log("PAI LINE useCalculatedPrimaryDim ", { maxVal, maxKey });

      if (maxVal > maxFieldVal) {
        maxFieldVal = maxVal;
        maxFieldKey = maxKey;
      }
    }

    console.log("PAI LINE useCalculatedPrimaryDim ", {
      maxFieldVal,
      maxFieldKey,
    });
    return fields.indexOf(maxFieldKey);
  }, [dataset]);

  return calculatedPrimaryDim;
};

export default useCalculatedAxisDim;
