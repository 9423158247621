import axios from "axios";
import domtoimage from "dom-to-image";
import fileDownload from "js-file-download";

import {
  AddYoYColumns,
  createCustomBins,
  addColumn,
} from "@pai/library/utils/yoy-transformer";

export const loadDataset = async (
  id,
  config,
  yoy,
  agg,
  newColumns,
  authToken,
  postFetchOperation
) => {
  return await axios.post(
    `${process.env.REACT_APP_MICROSERVICE_HOST}/viz/GetData`,
    {
      inputjson: config?.inputjson ? config.inputjson : config,
    },
    {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + authToken,
      },
    }
  )
  .then((response) => {
    const { data } = response;
    let responseData = data.queryResponse; //ss

    if (yoy) {
      responseData = AddYoYColumns(
        data,
        undefined,
        undefined,
        undefined,
        yoy
      );
      responseData.data = responseData.data.filter((d) => {
        return d;
      });
    }
    if (agg) {
      const responseDataTransformed = createCustomBins(responseData, agg);
      responseData = responseDataTransformed
        ? responseDataTransformed.transformedData
        : responseData;
    }
    if (newColumns) {
      responseData = addColumn(responseData, newColumns);
    }
    const output = responseData.data.map((el) => {
      let element = {};

      el.forEach((fieldValue, fieldIndex) => {
        element[
          responseData.fields[
          responseData.fields.length < responseData.data[0].length
            ? fieldIndex - 1
            : fieldIndex
          ]
        ] = fieldValue;
      });
      return element;
    });

    let finalModifiedData = postFetchOperation
      ? postFetchOperation(output, responseData.fields)
      : { modifiedData: output, fields: responseData.fields };

    return {
      fields: finalModifiedData.fields,
      data: finalModifiedData.modifiedData,
    };
  })
  .catch((err) => {
    throw new err;
  });
}

export const convertToPng = (id) => {
  domtoimage.toBlob(document.getElementById(id)).then(function (blob) {
    fileDownload(blob, `${id}.png`);
  });
};
