/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Body,
  Logo,
  LogoName,
  SubMenuItem,
  ScrollContainer,
  Sidebar,
  PageContainer,
  MenuText,
  LogoStatic,
  LogoDynamic,
} from "./styles";
import { MenuConfig, FeedbackProps } from "./config";
import Menu from "../../../assets/generic-components/menu";
import { Typography } from "@pai/library/base";
import { useCommonServiceHelper } from "@pai/library/utils/common-service-helper";
import { Link } from "react-router-dom";
import LandingPage from "../../../pages/landing-page";
import * as Components from "./../../../assets/generic-components/index";
import { CaretDownOutlined } from "@ant-design/icons";
import { Menu as Hamburger } from "@styled-icons/boxicons-regular/Menu";
import { Result, Select, Popover, Space, Avatar } from "antd";
import { Wrapper } from "../../../assets/generic-components/visualization/components";
import { UserContext } from "@pai/providers/with-auth-b2c";
import Loading from "@pai/assets/generic-components/Loading";
import LoadingSkeleton from "../../../assets/generic-components/loading-skeleton/index";

const merge = (array1, array2) => {
  if (!array1) {
    return array2 || [];
  }
  if (!array2) {
    return array1 || [];
  }
  return array1.concat(array2);
};

const RouterListener = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { getPostData } = useCommonServiceHelper();

  console.log("RouterListener");

  useEffect(() => {
    if (!navigator?.serviceWorker?.controller?.postMessage) {
      setIsLoading(false);
      return;
    }

    navigator.serviceWorker.controller.postMessage({
      type: "ROUTE_CHANGE",
      payload: location.pathname,
    });

    getPostData({
      inputjson: {
        id: "fetch-cache",
        select: {
          fields: [
            {
              name: "*",
              as: "",
              table: "data_last_updated_timestamp",
            },
          ],
          distinct: false,
        },
        limit: 0,
        offset: 0,
        orderby: [],
        where: {
          filter: [],
          expression: "",
        },
        nodeSequence: [],
      },
    }).finally(() => {
      setIsLoading(false);
      console.log("update cache api called.");
    });
    console.log("Route changed");
  }, [location.pathname, getPostData]);

  if (isLoading) {
    return <Loading />;
  }
  return children;
};

const AuthLayout = ({ children }) => {
  const user = React.useContext(UserContext);
  const storageValue = window.localStorage.getItem("pai_collapsed");
  const [defaultUrl, setDefaultUrl] = useState("");
  const [useDefaultUrl, setUseDefaulturl] = useState(true);

  const [collapsed, setCollapsed] = useState(
    !storageValue || storageValue === "true"
  );
  useEffect(() => {
    var event = new window.Event("customResize");
    window.dispatchEvent(event);
    window.localStorage.setItem("pai_collapsed", collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (useDefaultUrl) {
      setDefaultUrl(window.localStorage.getItem("currUrl"));
      window.localStorage.setItem("currUrl", "");
      setUseDefaulturl(false);
    }
  }, []);
  // let names = user.role.permissions.map((x) => x.name);
  let menuConfig = MenuConfig.groups.map((g) => ({
    ...g,
    items: g.items.filter((g) => g.text == "A/B Testing"),
  }));

  menuConfig = menuConfig.filter((g) => g.items.length);
  console.log("user", MenuConfig, menuConfig);

  if (!menuConfig.length) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Result
          status="403"
          title="Access Denied"
          subTitle="You don't have access to this application. Try switching to a different role."
        />
        <Select
          value={user && user?.role && user?.role?.role?.id}
          style={{ width: 240 }}
          onChange={(e) => {
            user.dispatch({
              type: "role",
              payload: e,
            });
          }}
        >
          {user?.user?.accesses &&
            user?.user?.accesses.map((r, i) => (
              <Select.Option key={i} value={r.role.id}>
                {r.role.name}
              </Select.Option>
            ))}
        </Select>
      </div>
    );
  }

  const feedbackHRef = `mailto:${FeedbackProps.mailId}?subject=${FeedbackProps.defaultSubj}
    &body=Page: ${window.location.pathname}%0DFeedback:%0D%0D%0DScreenShot:`;

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {defaultUrl !== "" ? <Redirect to={defaultUrl} /> : <Redirect to="/" />}
      <Container>
        <Switch>
          <Route path="/">
            <Sidebar collapsed={collapsed}>
              <Link to="/">
                <Logo>
                  <LogoName>
                    <LogoStatic>P</LogoStatic>
                    <LogoDynamic>ersonalize</LogoDynamic>
                    <LogoStatic>
                      <small>.</small>
                      <span>AI</span>
                    </LogoStatic>
                  </LogoName>
                </Logo>
              </Link>
              <ScrollContainer>
                <Menu groups={menuConfig} linked />
              </ScrollContainer>
            </Sidebar>
            <RouterListener>
              <Switch>
                <Route exact path="/">
                  <Body>
                    <PageContainer>
                      <Logo
                        faded
                        style={{
                          backgroundColor: "rgba(0,0,0,0.25)",
                          margin: "-20px -20px 0",
                        }}
                      >
                        <div
                          onClick={() => setCollapsed(!collapsed)}
                          style={{
                            padding: "0.8rem 0rem",
                            margin: "0px 0.188rem",
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            opacity: 0.75,
                          }}
                        >
                          <Hamburger
                            color="#FFF"
                            style={{ width: 20, height: 20 }}
                          />
                        </div>
                      </Logo>
                      <LandingPage />
                    </PageContainer>
                  </Body>
                </Route>
                {menuConfig.map((g) =>
                  g.items.map((i, ind) => [
                    <Route key={ind} exact path={i.linked}>
                      <Redirect to={i.linked + i.defaultRoute} />
                    </Route>,
                    ...merge(i.subMenu, i.actions).map((s, sIndex) => (
                      <Route key={sIndex} path={i.linked + s.linked}>
                        <Body>
                          {true && (
                            <Logo
                              faded
                              style={{ backgroundColor: "rgba(0,0,0,0.25)" }}
                            >
                              <div
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                  padding: "0.8rem 0rem",
                                  margin: "0px 0.188rem",
                                  marginBottom: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  opacity: 0.75,
                                }}
                              >
                                <Hamburger
                                  color="#FFF"
                                  style={{ width: 20, height: 20 }}
                                />
                              </div>
                              {i.subMenu &&
                                i.subMenu.map((s, subMenuIndex) => {
                                  const ActionComponent =
                                    Components[s.componentKey];
                                  if (
                                    s.type === "Component" &&
                                    ActionComponent
                                  ) {
                                    return (
                                      <ActionComponent key={subMenuIndex} />
                                    );
                                  }

                                  return (
                                    s.name !== "" && ( // condition to display route on header nav
                                      <SubMenuItem
                                        key={subMenuIndex}
                                        activeClassName="activeSubMenu"
                                        to={
                                          s.linked
                                            ? i.linked + s.linked
                                            : undefined
                                        }
                                      >
                                        <MenuText>{s.name}</MenuText>
                                      </SubMenuItem>
                                    )
                                  );
                                })}
                              <div style={{ marginLeft: "auto" }}>
                                {i.actions &&
                                  i.actions.map((s, index) => {
                                    const ActionComponent =
                                      Components[s.componentKey];
                                    if (!s.linked) {
                                      return <ActionComponent key={index} />;
                                    }
                                    return (
                                      s.name !== "" && ( // condition to display route on side nav
                                        <SubMenuItem
                                          key={index}
                                          firstSubGroup={index == 0}
                                          activeClassName="activeSubMenu"
                                          to={
                                            s.linked
                                              ? i.linked + s.linked
                                              : undefined
                                          }
                                        >
                                          {s.type === "Component" &&
                                            ActionComponent && (
                                              <ActionComponent />
                                            )}
                                          {s.type !== "Component" &&
                                            s.name !== "" && (
                                              <MenuText>{s.name}</MenuText>
                                            )}{" "}
                                          {/* s.name !=="" --> condition to display route on side nav */}
                                        </SubMenuItem>
                                      )
                                    );
                                  })}
                              </div>
                              <Space direction="horizontal" align="center">
                                <a href={feedbackHRef}>
                                  <img
                                    src={
                                      process.env.REACT_APP_CLOUDFRONT_CDN +
                                      "/images/landing/feedback.png"
                                    }
                                    width={30}
                                    alt={"icon"}
                                  />
                                </a>
                              </Space>
                              <div style={{ marginLeft: 20 }}>
                                {/* <Popover
                                  trigger={"click"}
                                  title="Switch Role"
                                  content={
                                    <Select
                                      value={
                                        user &&
                                        user?.role &&
                                        user?.role?.role?.id
                                      }
                                      onChange={(e) => {
                                        user.dispatch({
                                          type: "role",
                                          payload: e,
                                        });
                                      }}
                                    >
                                      {user?.user?.accesses &&
                                        user?.user?.accesses.map(
                                          (r, rIndex) => (
                                            <Select.Option
                                              key={rIndex}
                                              value={r.role.id}
                                            >
                                              {r.role.name}
                                            </Select.Option>
                                          )
                                        )}
                                    </Select>
                                  }
                                  placement="bottomRight"
                                > */}
                                  <MenuText>
                                    <Space
                                      direction="horizontal"
                                      align="center"
                                    >
                                      <Avatar>
                                        {user?.identity?.name
                                          .split(" ")
                                          .map((s) => s[0])}
                                      </Avatar>
                                      <div>
                                        {/* <Typography.Paragraph>
                                          {user?.identity?.name}
                                        </Typography.Paragraph> */}
                                        {/* <Typography.Paragraph colorStyle="primary">
                                          {user?.role?.role?.name}
                                        </Typography.Paragraph> */}
                                      </div>
                                      {/* <CaretDownOutlined /> */}
                                    </Space>
                                  </MenuText>
                                {/* </Popover> */}
                              </div>
                            </Logo>
                          )}
                          {s.key && (
                            <PageContainer id="page-container">
                              <React.Suspense
                                fallback={
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LoadingSkeleton />
                                  </div>
                                }
                              >
                                {children && children({ pageName: s.key })}
                              </React.Suspense>
                            </PageContainer>
                          )}

                          {s.url && (
                            <Wrapper>
                              {({ width, height }) => (
                                <React.Fragment>
                                  <iframe
                                    title="abc"
                                    style={{ border: 0, zIndex: -10000 }}
                                    src={s.url}
                                    width={width}
                                    height={height}
                                  ></iframe>
                                  <div
                                    style={{
                                      border: "solid 1px #14afd0",
                                      background: "#111419",
                                      fontSize: 20,
                                      borderRadius: "5px",
                                      color: "#14afd0",
                                      padding: "10px 15px",
                                      textAlign: "middle",
                                      position: "absolute",
                                      top: "90%",
                                      right: "20px",
                                    }}
                                  >
                                    WIP Screen. Coming soon...
                                  </div>
                                </React.Fragment>
                              )}
                            </Wrapper>
                          )}
                        </Body>
                      </Route>
                    )),
                  ])
                )}
              </Switch>
            </RouterListener>
          </Route>
        </Switch>
      </Container>
    </Router>
  );
};

export default AuthLayout;
