import React from "react";

import { GlobalStyle, SpecialModalBackground } from "./loading-custom-style";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import { Typography } from "@pai/library/base";
import { ThemeProvider as PaiUiThemeProvider } from "@pai-ui/core/theme/theme-provider";
import { ConfigProvider as PaiUiConfigProvider } from "@pai-ui/core/config/config-provider";
import { ThemeType } from "@pai-ui/core/theme/types";
import { ErrorBoundary } from "react-error-boundary";
import { Card, Skeleton, Avatar, CardMeta } from "@pai-ui/core"; //
import { LoadingOutlined } from "@ant-design/icons";
import AuthLayout from "./../../library/layouts/auth-layout/index";
import "@pai/assets/style.less";

import ErrorFallback from "../ErrorFallback";

const AccessControl = React.lazy(() => import("../access-control"));
const MockPage = React.lazy(() => import("../mockpage"));
// const ExploreCustomersNew = React.lazy(() => import("../explore-customer"));
// const ExploreSegments = React.lazy(() => import("../explore-segment/index"));
// const Objective = React.lazy(() => import("../business-inputs/objective/index"));
// const FeatureEngineering = React.lazy(() => import("../business-inputs/feature-engg/index"));
// const Bivariate = React.lazy(() => import("../business-inputs/bivariate/index"));
// const Univariate = React.lazy(() => import("../business-inputs/univariate/index"));
// const AutoDashboard = React.lazy(() => import("../business-inputs/auto-dashboard"));
const ABTestForm = React.lazy(() => import("../ab-test/ab-test-form/index"));
const ABTestList = React.lazy(() => import("../ab-test/ab-test-list"));
const AbTestResult = React.lazy(() =>
  import("../ab-test/ab-test-results/index")
);
// const Diagnostics = React.lazy(() => import("../diagnostics"));
// const AAGOpportunity = React.lazy(() => import("../at-a-galnce-opportunity/index").then((module) => ({ default: module.AAGOpportunity })));
// const RecommendationSummary = React.lazy(() => import("../recommendation-summary").then((module) => ({ default: module.RecommendationSummary })));
// const ItemOfferRecom = React.lazy(() => import("../item_offer_recom").then((module) => ({ default: module.ItemOfferRecom })));
// const AAGResults = React.lazy(() => import("../at-a-glance-results/index").then((module) => ({ default: module.AAGResults })));
// const BundlesDeepDive = React.lazy(() => import("../bundles-deep-dive/index"));
// const Hypothesis = React.lazy(() => import("../hypothesis/demo"));
// const HypothesisResults = React.lazy(() => import("../hypothesis-results/"));
// const Hierarchy = React.lazy(() => import("../hierarchy/index"));
// // const HierarchyNew = React.lazy(() => import("../Hierarchy-N/index"));
// const Campaigns = React.lazy(() => import("../business-inputs/campaigns/index"));

const Pages = {
  AccessControl,
  MockPage,
  // ExploreCustomersNew,
  // ExploreSegments,
  // Objective,
  // FeatureEngineering,
  // Bivariate,
  // Univariate,
  // AutoDashboard,
  ABTestForm,
  ABTestList,
  AbTestResult,
  // Diagnostics,
  // AAGOpportunity,
  // RecommendationSummary,
  // ItemOfferRecom,
  // AAGResults,
  // BundlesDeepDive,
  // Hypothesis,
  // HypothesisResults,
  // Hierarchy,
  // Campaigns,
  // HierarchyNew
};

import { UserContext } from "../../providers/with-auth-b2c";

const LoadingCard = () => {
  const userState = React.useContext(UserContext);
  console.log("LoadingCard user state", userState, UserContext);
  const authToken = userState.token;

  return [
    <GlobalStyle key={4} />,
    <ThemeProvider
      key={5}
      theme={{
        gutter: {
          inner: 20,
        },
        colors: {
          primary: "#15afd0",
          secondary: "#15afd0",
          default: "#ffffff",
          success: "#00FF00",
          background: "#111419",
          muted: "#FFFFFF",
          error: "red",
        },
        borderRadius: 10,
        background: "#000",
        fontSizes: {
          xlarge: 36,
          large: 21,
          medium: 16,
          default: 14,
          small: 11,
        },
        modal: {
          background: "#181b20",
        },
      }}
    >
      {(!userState || userState.loading) && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Card
              size="large"
              style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
              bordered={false}
              bodyStyle={{ backgroundColor: "transparent" }}
              loading={!userState.identity}
              actions={
                userState.error ? (
                  [
                    <div key={0} style={{ padding: "0px 10px" }}>
                      <Typography.Paragraph align="center" colorStyle="error">
                        {userState.error}
                      </Typography.Paragraph>
                    </div>,
                  ]
                ) : !userState.identity ? (
                  [
                    <Typography.Paragraph
                      key={1}
                      align="center"
                      colorStyle="primary"
                    >
                      Identifying
                    </Typography.Paragraph>,
                  ]
                ) : !userState.user ? (
                  [
                    <Typography.Paragraph
                      key={2}
                      align="center"
                      colorStyle="primary"
                    >
                      Fetching Tokens
                    </Typography.Paragraph>,
                  ]
                ) : (
                  <Typography.Paragraph align="center" colorStyle="primary">
                    Login Successful
                  </Typography.Paragraph>
                )
              }
            >
              <Skeleton loading={!userState.identity} avatar active>
                {userState.identity && (
                  <React.Fragment>
                    <Avatar
                      size={65}
                      style={{ marginBottom: 20, backgroundColor: "#15afd0" }}
                      icon={<LoadingOutlined />}
                    ></Avatar>
                    <CardMeta
                      title={userState.identity.name}
                      description={userState.identity.userName}
                    />
                  </React.Fragment>
                )}
              </Skeleton>
            </Card>
          </div>
        </div>
      )}
      {userState && !userState.loading && (
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          <AuthLayout>
            {({ pageName }) => {
              const Page = Pages[pageName];
              if (!Page) return "Page Not found";

              return (
                <PaiUiConfigProvider
                  visualization={{
                    resizing: {
                      eventName: "customResize",
                      debounceMs: 500,
                    },
                  }}
                  http={() => ({
                    commonRequestConfig: {
                      url: "/viz/GetData",
                      baseURL: process.env.REACT_APP_MICROSERVICE_HOST,
                      headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        Authorization: "Bearer " + authToken,
                      },
                    },
                  })}
                  components={{
                    select:{
                      props:{
                        getPopupContainer:(triggerNode) => triggerNode.parentElement,
                      }
                    }
                  }}
                >
                  <PaiUiThemeProvider
                    type={ThemeType.dark}
                    componentsStyles={{
                      visualization: {
                        root: {
                          zoom: 1.1111111,
                        },
                        header: {
                          zoom: 0.9,
                        },
                      },
                    }}
                  >
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <Page />
                    </ErrorBoundary>
                  </PaiUiThemeProvider>
                </PaiUiConfigProvider>
              );
            }}
          </AuthLayout>
        </ModalProvider>
      )}
    </ThemeProvider>,
  ];
};

export default LoadingCard;
