import { LogLevel } from "@azure/msal-browser";
export const b2cPolicies = {
  names: { signIn: "B2C_1_signup_signin" },
  authorities: {
    signIn: {
      authority:
        "https://bhcpaitriumph.b2clogin.com/bhcpaitriumph.onmicrosoft.com/B2C_1_signup_signin",
    },
  },
  authorityDomain: "bhcpaitriumph.b2clogin.com",
};
export const msalConfig = {
  auth: {
    clientId: "09091f73-9ef0-4abc-ade5-3706ab1b5173",
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
    state:"test"
  },
  cache: { cacheLocation: "sessionStorage", storeAuthStateInCookie: false },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return null;
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: [
    "https://bhcpaitriumph.onmicrosoft.com/bhc_pai_triumph_node/pai.read",
  ],
  state: "page_url"
};
export const graphConfig = {
  graphMeEndpoint:
    "https://bhcpaitriumph.b2clogin.com/910ba9c4-2d5b-4e1d-8e86-22ebd743358e/v2.0/",
};
