export const columnProps = {
  align: "left",
  className: undefined,
  colSpan: undefined,
  defaultFilteredValue: undefined,
  defaultSortOrder: undefined,
  editable: false,
  ellipsis: false,
  filterDropdown: undefined,
  filterDropdownVisible: undefined,
  filtered: false,
  filteredValue: undefined,
  filterIcon: undefined,
  filterMultiple: true,
  filters: undefined,
  fixed: false,
  key: undefined,
  render: undefined,
  responsive: undefined,
  shouldCellUpdate: undefined,
  showSorterTooltip: true,
  sortDirections: undefined,
  sorter: undefined,
  sortOrder: undefined,
  width: undefined,
  onCell: undefined,
  onFilter: undefined,
  onFilterDropdownVisibleChange: undefined,
  onHeaderCell: undefined,
  expanded: undefined,
};
