/* eslint-disable no-unused-vars */
import axios from "axios";

export const getAllConfigs = () =>
  new Promise((resolve, reject) => {
    // const isMock = process.env.REACT_APP_MOCK;
    // if (isMock) {
    //   axios.get("./configs/GetAllVizConfigs.json").then((response) => {
    //     resolve(response.data);
    //   });
    // } else {
    axios
      .post(
        `${process.env.REACT_APP_MICROSERVICE_HOST}/viz/GetAllVizConfigs`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        resolve([]);
      });
    // }
  });

export const loadConfigFromPath = (path) =>
  new Promise((resolve) => {
    axios.get(path).then((response) => {
      resolve(response.data);
    });
  });

export const loadConfig = (chartId) =>
  new Promise((resolve, reject) => {
    // const isMock = process.env.REACT_APP_MOCK;
    // if (isMock) {
    //   axios.get(`./configs/${chartId}.json`).then((response) => {
    //     resolve({
    //       data_config: response.data.vizConfig,
    //       chart_config: response.data.chartConfig,
    //       data_config_id: response.data.vizId,
    //       chart_config_id: response.data.chartId,
    //     });
    //   });
    // } else {
    axios
      .post(
        `${process.env.REACT_APP_MICROSERVICE_HOST}/viz/GetChartAndVizConfigsByChartIds`,
        { chartIds: [chartId] },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then((response) => {
        resolve({
          data_config: JSON.parse(response.data.charts[0].vizConfig),
          chart_config: JSON.parse(response.data.charts[0].chartConfig),
          data_config_id: response.data.charts[0].vizId,
          chart_config_id: response.data.charts[0].chartId,
        });
      });
    // }

    /*
    
    */
  });
