import React from "react";
import { Color } from "../../color-select";

const Gradients = ({ chartId, orientation }) => {
  let direction = { x1: "100%", y1: "0", x2: "0", y2: "0" };
  if (orientation === "horizontal") {
    direction = { x1: "0", y1: "0", x2: "0", y2: "100%" };
  }
  return (
    <defs>
      {Color.map((color, i) => (
        <linearGradient
          key={i}
          id={color.replace("#", "") + `_${chartId}`}
          {...direction}
          spreadMethod="pad"
        >
          <stop offset="0" stopColor={`${color}BF`} />
          <stop offset="1" stopColor={`${color}66`} />
        </linearGradient>
      ))}
    </defs>
  );
};

export default Gradients;
