/* eslint-disable */
import React, { useMemo } from "react";
import ZsTable from "../../../../generic-ant-d-components/zs-table";
import { columnProps } from "./settings";
import { Wrapper } from "../../components";

const Table = ({ id, dataset, settings }) => {
  const columnAssembled = useMemo(() => {
    let columnData = [];
    dataset.fields.forEach((x) => {
      if (settings.skipColumns && settings.skipColumns.includes(x)) {
      } else {
        let props = {};
        let customColumnProps = settings.columns;

        Object.entries(columnProps).forEach(([key, value]) => {
          if (
            customColumnProps &&
            customColumnProps[x] &&
            customColumnProps[x][key]
          ) {
            props[key] = customColumnProps[x][key];
          }
        });

        props.title =
          (customColumnProps && customColumnProps[x]
            ? customColumnProps[x].title
            : undefined) || x;
        props.dataIndex = x;
        props.key = x;
        columnData.push(props);
      }
    });

    columnData.sort(
      (a, b) =>
        Object.keys(settings.columns).indexOf(a.dataIndex) -
        Object.keys(settings.columns).indexOf(b.dataIndex)
    );
    return columnData;
  }, [dataset, settings.columns, settings.skipColumns]);

  const data = useMemo(() => {
    return [...dataset.data.map((x) => ({ ...x, key: x[settings.keyColumn] }))];
  }, [dataset.data, settings.keyColumn]);

  return (
    <Wrapper id={"wrapper"} style={settings.tableWrapperStyle} className={settings.wrapperClassName}>
      {() => (
        <div
          style={{
            width: "99%",
            overflow: "hidden",
            paddingRight: "20px",
            ...(settings.tableWrapperStyle || {}),
          }}
        >
          <ZsTable
            columns={columnAssembled}
            dataSource={data}
            {...settings.tableSettings}
            scroll={settings.ModalView?{} :settings.tableSettings?.scroll }
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Table;
