/* eslint-disable no-undef */
import { UserAgentApplication } from "msal";

const hostname = window && window.location && window.location.origin;
let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
let scopes = process.env.REACT_APP_AUTH_SCOPES.split(",");

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: scopes,
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId,
    authority: process.env.REACT_APP_AUTH_TENANT,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
    redirectUri: process.env.REACT_APP_AUTH_RETURN_URL || hostname,
  },
});
