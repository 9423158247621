import styled from "styled-components";

export const VisualizationContainer = styled.div`
  background: #181b20;
  border-radius: 10px;
  padding: 15px 20px 15px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VisualizationModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const VisualizationModal = styled.div`
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  ${(props) => {
    if (props.small) {
      return `
                max-width: 767px;
                max-height: 480px;
            `;
    }
  }}
  background: #181b20;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const VisualizationBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? "0px 20px 10px" : "0px")};
`;

export const VisualizationTitle = styled.h3`
  font-family: SF Display;
  font-size: ${(props) => (props.small ? 16 : 20)}px;
  line-height: 100%;
  color: #fff;
  padding: 22px 18px ${(props) => (props.small ? "12px" : "")};
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const VisualizationActions = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: auto;
  margin-right: 15px;
`;

export const VisualizationAction = styled.div`
  width: 22px;
  margin: 0px 2px;
  opacity: 0.75;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const Button = styled.button`
  font-family: SF Text;
  font-size: 12px;
  line-height: 100%;
  border: 1px solid #15afd0;
  color: ${(props) => (props.primary ? "#000" : "#FFF")};
  background: ${(props) => (props.primary ? "#15afd0" : "#181b20")};
  padding: 7px 15px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const SettingsContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
`;

export const ChartTypesContainer = styled.div`
  background: rgba(0, 0, 0, 0.25);
  width: 40%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ChartSettingsContainer = styled.div`
  background: rgba(0, 0, 0, 0.15);
  width: 60%;
  box-sizing: border-box;
`;

export const PreviewContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
`;

export const ConfigBody = styled.div`
  flex: 1;
`;

export const ConfigSection = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 15px;
`;

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 15px;
`;

export const MenuItem = styled.li`
  padding: 0.8rem 1.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => (props.active ? "#15afd0" : "transparent")};
  transition: background ease 500ms;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background: #15afd0;

    > p {
      color: #fff;
    }
  }

  ${(props) => {
    if (props.active) {
      return `
                > p{
                    color: #FFF;
                }
            `;
    }
  }}
`;

export const MenuText = styled.p`
  font-family: "SF Display";
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  flex: 1;
  margin: 0;
`;

export const MenuIcon = styled.div`
  width: 20px;
  flex-shrink: 0;
  margin-right: 15px;
`;

export const MenuGroup = styled.p`
  color: rgba(255, 255, 255, 0.25);
  font-family: SF Display;
  font-size: 13px;
  padding: 0.025rem 1.188rem;
  margin-top: ${(props) => (props.bottom ? "auto" : "0px")};
`;

export const ScrollContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - ${(props) => (props.full ? 40 : 104)}px);
`;

export const VisualizationHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9;
`;

export const VisualizationFilterOptions = styled.select`
  font-family: SF Text;
  margin: 0;
  border: 1px solid rgba(8, 150, 214, 0.33);
  border-radius: 5px;
  padding: 7px 10px;
  background: transparent;
  color: rgba(255, 255, 255, 0.65);
  text-align: right;
  font-weight: 200;
  font-size: 12px;
  outline: none !important;

  > option {
    font-family: SF Text;
    background: #000;
    color: rgba(255, 255, 255, 0.66);
    text-align: left;
    font-weight: 200;
  }
`;
