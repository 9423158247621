import React, { useState, useEffect } from "react";
import { startCase } from "lodash";
import axios from "axios";
import WaveLoader from "@pai/assets/generic-components/wave-loader/WaveLoader.jsx"

// const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"];
export const getSubRoute = () => {
  return window.location.href.split("/")[4];
};

export const removeDelimiterAndToCamelCase = (text, delimiter = "_") => {
  return text
    ? text
      .split(delimiter)
      .map(
        (eachText) =>
          eachText.charAt(0).toUpperCase() +
          eachText.slice(1, eachText.length).toLowerCase()
      )
      .join(" ")
    : "";
};

export const splitByDelimiterAndToStartCase = (text, delimiter = "-") => {
  if (!text?.length) {
    return "";
  }
  return text
    .toLowerCase()
    .split(delimiter)
    .map(startCase)
    .join(` ${delimiter} `);
};

export const DelayCompRender = ({ children, delay }) => {
  const [delayed, setDelayed] = useState(true);

  console.log("inside delayed render");

  useEffect(() => {
    let delayID = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(delayID);
  }, []);

  return delayed ? <WaveLoader /> : children();
};

export const convertData = (response) => {
  var k = JSON.parse(JSON.stringify(response));
  var output = response.data.map((el) => {
    let element = {};

    el &&
      el.forEach((fieldValue, fieldIndex) => {
        element[
          response.fields[
          response.fields.length < response.data[0].length
            ? fieldIndex - 1
            : fieldIndex
          ]
        ] = fieldValue;
      });
    return element;
  });
  console.log("aaaaaaaaaaaaaaaaaaa", { fields: k.fields, data: output });

  return { fields: k.fields, data: output };
};

export const getS3Data = (json) =>
  new Promise((resolve) => {
    console.log("getS3Data service");
    let subRout = getSubRoute();
    console.log(
      "getS3Data service",
      subRout,
      json,
      `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`
    );
    let fetchUrl = `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`;
    // let fetchUrl =  `./sample_data/custom/${json}`

    axios({
      method: "GET",
      url: fetchUrl,
    })
      .then((response) => {
        // console.log("hypothesis ddn from s3", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log("error in API call", err);
        resolve({
          data: [],
        });
      });
  });
