import React from "react";
import {Card,Skeleton,CardMeta,Avatar } from '@pai-ui/core'; //
import { LoadingOutlined } from "@ant-design/icons";
import { Typography } from "@pai/library/base";


const LoadingSkeleton = ()=>{
    return (
        <Card
        size="large"
        style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
        bordered={false}
        bodyStyle={{ backgroundColor: "transparent" }}
       //  loading={!userState.identity}
        actions={
         <Typography.Paragraph
         key={1}
         align="center"
         colorStyle="primary"
       >
         Identifying
       </Typography.Paragraph>
        }               
      >
        <Skeleton>
          {/* {userState.identity && ( */}
            <React.Fragment>
              <Avatar
                size={65}
                style={{ marginBottom: 20, backgroundColor: "#15afd0" }}
                icon={<LoadingOutlined />}
              ></Avatar>
              <CardMeta
                title={'loading'}
               //  description={userState.identity.userName}
              />
            </React.Fragment>
          {/* )} */}
        </Skeleton>
      </Card>
    )
}




export default LoadingSkeleton;