/* eslint-disable */
import React from "react";
import numeral from "numeral";
import {
  ComposedChart,
  Bar,
  Line,
  LabelList,
  Cell,
  Area,
  Tooltip,
} from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
} from "../../components";
import { area } from "d3-shape";
import millify from "millify";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

export default ({ id, dataset, settings }) => {
  const {
    orientation,
    primaryDim,
    tooltipConfig,
    toolTipDim,
    primaryLookup,
    legend,
    plot,
    axes,
    zeroReference,
    primaryWidth,
    primaryType,
    tickCount,
  } = settings;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.8)",
            padding: "5px 5px 5px 5px",
            borderRadius: 8,
          }}
        >
          <div style={{ fontSize: "12px" }}>{`${
            payload[0].payload[
              toolTipDim
                ? dataset?.fields[toolTipDim]
                : dataset?.fields[primaryDim]
            ]
          }`}</div>
          {plot?.series?.map((z) => {
            return (
              <div style={{ fontSize: "12px" }}>{`${z.label} : ${numeral(
                payload[0].payload[dataset?.fields[z?.dim]]
              )
                .format(z?.format)
                ?.toUpperCase()}`}</div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  let margin = { top: 15, bottom: 0, left: 15, right: 15 };

  if (orientation === "vertical") {
    margin = { top: 10, left: 25, right: 60, bottom: 20 };
  }

  if (legend.verticalAlign === "bottom") {
    margin.bottom = 15;
  }

  if (legend.additionalPadding) {
    margin.bottom = 40;
  }

  if (axes.primary && axes.primary.show) {
    margin.right = 25;
  }

  const newDataset = {
    ...dataset,
    fields: [...dataset.fields],
    data: [...dataset.data],
  };

  newDataset.data.forEach((e) => {
    Object.keys(e).forEach((f) => {
      if (e[f] === null || e[f] === "") {
        delete e[f];
      }
    });
  });

  plot.series.forEach((series) => {
    if (series.merge) {
      const field = newDataset.fields[series.dim];
      const mergeField = newDataset.fields[series.merge.series];
      let firstIndexMatch = -1;
      for (let i = 0; i < newDataset.data.length; i++) {
        if (
          newDataset.data[i][field] !== null &&
          newDataset.data[i][field] !== undefined
        ) {
          firstIndexMatch = i;
          break;
        }
      }
      if (firstIndexMatch >= 1) {
        if (
          newDataset.data[firstIndexMatch - 1][field] === null ||
          newDataset.data[firstIndexMatch - 1][field] === undefined
        ) {
          newDataset.data[firstIndexMatch - 1][field] =
            newDataset.data[firstIndexMatch - 1][mergeField];
        }
      }
    }
  });

  return (
    <Wrapper>
      {({ width, height }) => (
        <ComposedChart
          width={width}
          height={height}
          layout={orientation}
          data={newDataset.data.map((d) => {
            let dOld = d[newDataset.fields[primaryDim]];
            d[newDataset.fields[primaryDim]] =
              primaryLookup && primaryLookup[dOld] ? primaryLookup[dOld] : dOld;
            return d;
          })}
          margin={margin}
          barCategoryGap={"17%"}
        >
          {Gradients({ chartId: id, orientation })}
          {plot.aggregation === "Overlay" &&
            plot.series.map((series, sIndex) =>
              PrimaryAxis({
                index: sIndex,
                orientation,
                dataKey: newDataset.fields[primaryDim],
                show: sIndex === 0,
                width: primaryWidth,
                type: primaryType,
                tickCount,
              })
            )}
          {plot.aggregation !== "Overlay" &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: newDataset.fields[primaryDim],
              show: true,
              width: primaryWidth,
              type: primaryType,
              tickCount,
              padding: true,
            })}
          {axes.primary &&
            SecondaryAxis({
              index: 0,
              equalize: axes.primary.equalize,
              orientation,
              dataKey: newDataset.fields[axes.primary.dim],
              show: axes.primary.show,
              squeeze: axes.primary.squeeze,
              width: axes.width,
              format: axes.primary.format,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 1,
              equalize: axes.primary.equalize,
              orientation,
              dataKey: newDataset.fields[axes.secondary.dim],
              show: axes.secondary.show,
              squeeze: axes.secondary.squeeze,
              width: axes.width,
              format: axes.secondary.format,
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => (
              <span style={{ color: "rgba(255, 255, 255, 0.66)" }}>
                {plot.series[index].label}
              </span>
            ),
          })}
          <Tooltip
            cursor={{ stroke: "#616161", strokeDasharray: "5 5" }}
            content={<CustomTooltip />}
          />
          {zeroReference && ZeroLine({ orientation })}
          {orientation === "horizontal" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const xAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const yAxisId = series.axis;
              var Component = Bar; //default value
              switch (series.type) {
                case "bar":
                  Component = Bar;
                  break;
                case "line":
                  Component = Line;
                  break;
                case "area":
                  Component = Area;
                  break;
              }

              return (
                <Component
                  dataKey={newDataset.fields[series.dim]}
                  tooltipKey={newDataset.fields[series.tooltipDim]}
                  stackId={series.stackId}
                  maxBarSize={60}
                  // Line not loading for same data scenario with `url(${Color[series.color.value]}_${id})`
                  // fix later or wait for new Viz component
                  fill={
                    series.type == "line"
                      ? Color[series.color.value]
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  stroke={
                    series.type == "line"
                      ? Color[series.color.value]
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  type="monotone"
                  dot={!series.hideDot}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  strokeDasharray={
                    series.color.type === "dashed" ? series.color.dashed : ""
                  }
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      formatter={(value) =>
                        // series.format==
                        // "0.0a"
                        // ?
                        // "$" +  millify( value, { precision: 1, lowercase: false })
                        //   :
                        numeral(value).format(series.format)?.toUpperCase()
                      }
                    />
                  )}
                </Component>
              );
            })}
          {orientation === "vertical" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const yAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const xAxisId = series.axis;
              var Component = Bar; //default value
              switch (series.type) {
                case "bar":
                  Component = Bar;
                  break;
                case "line":
                  Component = Line;
                  break;
                case "area":
                  Component = Area;
                  break;
              }
              return (
                <Component
                  dataKey={newDataset.fields[series.dim]}
                  maxBarSize={60}
                  barSize={25}
                  // Line not loading for same data scenario with `url(${Color[series.color.value]}_${id})`
                  // fix later or wait for new Viz component
                  fill={
                    series.type == "line"
                      ? Color[series.color.value]
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  stroke={
                    series.type == "line"
                      ? Color[series.color.value]
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  type="monotone"
                  dot={!series.hideDot}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  strokeDasharray={
                    series.color.type === "dashed" ? series.color.dashed : ""
                  }
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      formatter={(value) =>
                        numeral(value).format(series.format)?.toUpperCase()
                      }
                    />
                  )}
                </Component>
              );
            })}
        </ComposedChart>
      )}
    </Wrapper>
  );
};
