import * as Bar from "./bar";
import * as Scatter from "./scatter";
import * as Heat from "./heat-table";
import * as customZSTableHeat from "./customZsTable-heat-table";
import * as Line from "./line";
import * as Area from "./area";
import * as TwoByTwo from "./two-by-two";
import * as Pie from "./pie";
import * as GeoScatter from "./geo-scatter";
import * as Composite from "./composite";
import * as Waterfall from "./waterfall";
import * as Funnel from "./funnel";
import * as Venn from "./venn";
import * as Table from "./table";
import * as BeeSwarm from "./bee-swarm";

export default {
  Bar,
  Scatter,
  customZSTableHeat,
  Heat,
  Line,
  Area,
  TwoByTwo,
  Pie,
  GeoScatter,
  Composite,
  Waterfall,
  Funnel,
  Venn,
  Table,
  BeeSwarm,
};
