/* eslint-disable */
import * as d3 from "d3";
import { Wrapper } from "../../components";
import { useEffect, Tooltip } from "react";
import { mockShapdata, newShapData } from "./mock-data";

const Color = [
  "#920912", //red
  "#acacac",
  "#15afd0", //2 original blue
  "#87c8d5",
  "#b4e4ed",
  "#427580",
  "#e8f3f6",
  "#FF5146",
  // "#15afd0",
  "#00ccff", //8 light blue
  "#05272e", //9 dark blue
  // faded red
];

export function pickHex(weight, c1, c2) {
  var w1 = weight;
  var w2 = 1 - w1;
  var color2 = hexToRgb(c2);
  var color1 = hexToRgb(c1);
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

export const getMaxMin = (array) => {
  return {
    max: Math.max(...array.filter((val) => val || 0)),
    min: Math.min(...array.filter((val) => val || 0)),
  };
};

export const getWeight = (maxMin, currentValue) => {
  return ((currentValue || 0) - maxMin.min) / (maxMin.max - maxMin.min);
};

let maindata = mockShapdata.data;
// d3.csv("https://martinheinz.github.io/charts/data/who_suicide_stats.csv")
//   .then(function (data) {
//     console.log("new data-> ", data);
//     maindata = data;
//     return true;
//   })
//   .catch(function (error) {
//     if (error) throw error;
//   });

export const BeeSwarm = (props) => {
  let height = 750;
  let width = 800;
  let margin = { top: 0, right: 40, bottom: 34, left: 40 };

  // Data structure describing chart scales
  let Scales = {
    lin: "scaleLinear",
    log: "scaleLog",
  };

  // Data structure describing volume of displayed data
  let Count = {
    total: "total",
    perCap: "perCapita",
  };

  // Data structure describing legend fields value
  let Legend = {
    total: "Total Deaths",
    perCap: "Per Capita Deaths",
  };

  let chartState = {};

  chartState.measure = Count.total;
  chartState.scale = Scales.lin;
  chartState.legend = Legend.total;
  // Colors used for circles depending on continent

  let nodeColors = d3
    .scaleOrdinal()
    .domain([
      "asia",
      "africa",
      "northAmerica",
      "europe",
      "southAmerica",
      "oceania",
    ])
    .range(Color);

  let xScale = d3.scaleLinear().range([margin.left, width - margin.right]);
  let dataSet = maindata;

  let newDataset = newShapData;
  const maxMin = getMaxMin(
    maindata.map((m) => {
      return m.population;
    })
  );
  const newMaxMin = getMaxMin(
    newShapData.map((m) => {
      return m["Market Cap"];
    })
  );

  function redraw() {
    let canvas = d3.select("#chart_canvas");
    // .append("svg")
    // .attr("id", "queen-bee")
    // .attr("width", width)
    // .attr("height", height);

    // svg
    //   .append("g")
    //   .attr("class", "x axis")
    //   .attr("transform", "translate(0," + (height - margin.bottom) + ")");

    // Create line that connects circle and X axis
    let xLine = canvas.select("#xline");
    // .attr("stroke", "rgb(96,125,139)")
    // .attr("stroke-dasharray", "1,2");

    // Create tooltip div and make it invisible
    let tooltip = d3.select("#tooltip");
    // .append("div")
    // .attr("class", "tooltip")
    // .style("opacity", 0);
    xScale.domain(
      d3.extent(dataSet, function (d) {
        return +d[chartState.measure];
      })
    );

    let xAxis;
    // Set X axis based on new scale. If chart is set to "per capita" use numbers with one decimal point

    xAxis = d3.axisBottom(xScale).ticks(10, ".1s").tickSizeOuter(0);

    d3.transition(canvas)
      .select(".x.axis")
      .transition()
      .duration(1000)
      .call(xAxis);

    // Create simulation with specified dataset
    let simulation = d3
      .forceSimulation(dataSet)
      // Apply positioning force to push nodes towards desired position along X axis
      .force(
        "x",
        d3
          .forceX(function (d) {
            // Mapping of values from total/perCapita column of dataset to range of SVG chart (<margin.left, margin.right>)
            return xScale(+d[chartState.measure]); // This is the desired position
          })
          .strength(2)
      ) // Increase velocity
      .force("y", d3.forceY(height / 2 - margin.bottom / 2)) // // Apply positioning force to push nodes towards center along Y axis
      .force("collide", d3.forceCollide(5)) // Apply collision force with radius of 9 - keeps nodes centers 9 pixels apart
      .stop(); // Stop simulation from starting automatically

    // Manually run simulation
    for (let i = 0; i < dataSet.length; ++i) {
      simulation.tick(10);
    }

    // Create country circles --- not understood
    let tempNodes = canvas.selectAll(".node").data(dataSet, function (d) {
      ///console.log("tempnodes--->", d, dataSet);
      return d.name;
    });

    tempNodes
      .exit()
      .transition()
      .duration(1000)
      .attr("cx", 0)
      .attr("cy", height / 2 - margin.bottom / 2)
      .remove();

    //       let weight = getWeight(maxMin, parseFloat(d.population));
    //       const color = pickHex(weight, Color[0], Color[8]);
    // fill={`rgba(${color[0]},${color[1]},${color[2]},1)`}

    tempNodes
      .enter()
      .append("circle")
      .attr("class", "node")
      .attr("cx", 0)
      .attr("cy", height / 2 - margin.bottom / 2)
      .attr("r", 6)
      .attr("fill", function (d) {
        let weight = getWeight(maxMin, parseFloat(d.population));
        const color = pickHex(weight, Color[8], Color[0]);
        return `rgba(${color[0]},${color[1]},${color[2]},1)`;
      })
      ///.merge(tempNodes) not understood
      .transition()
      .duration(2000)
      .attr("cx", function (d) {
        return d.x;
      })
      .attr("cy", function (d) {
        return d.y;
      });
    // console.log("allnodes->", canvas.selectAll(".node1"));

    //// Add effects to nodes
    // Create country circles
    let allNodes = canvas.selectAll(".node2");

    allNodes
      .exit()
      .transition()
      .duration(1000)
      .attr("cx", 0)
      .attr("cy", height / 2 - margin.bottom / 2)
      .remove();

    allNodes.enter().transition().duration(2000);

    // Show tooltip when hovering over circle (data for respective country)
    d3.selectAll(".node")
      .on("mousemove", function (event, d) {
        console.log("d--->", d);

        tooltip
          .html(
            `Country: <strong>${d.name}</strong><br>
                            X-Axis (Shap Val) :
                            <strong>${d3.format(",")(
                              d[chartState.measure]
                            )}</strong>
                            
                            <br>
                            Gradient (Feature Imp) :<strong>${d3.format(",")(
                              d.population
                            )}</strong>
                            </br>`
          )
          .style("top", event.pageY - 50 + "px")
          .style("left", event.pageX - 50 + "px")
          .style("opacity", 0.9)
          .transition();
        xLine
          .attr("x1", d3.select(this).attr("cx"))
          .attr("y1", d3.select(this).attr("cy"))
          .attr("y2", height - margin.bottom)
          .attr("x2", d3.select(this).attr("cx"))
          .attr("opacity", 1);
      })
      .on("mouseout", function (_) {
        tooltip.style("opacity", 0);
        xLine.attr("opacity", 0);
      });
  }

  function newSwarm() {
    let svg = d3.select("#chart_canvas");
    let tooltip = d3.select("#tooltip");
    let sectors = Array.from(new Set(newDataset.map((d) => d.Sector)));
    let xCoords = sectors.map((d, i) => 200 + i * 70);
    let yNewCords = sectors.map((d, i) => 80 + i * 40);

    let xScale = d3.scaleOrdinal().domain(sectors).range(xCoords);
    let yNewScale = d3.scaleOrdinal().domain(sectors).range(yNewCords);

    let yScale = d3
      .scaleLinear()
      .domain(d3.extent(newDataset.map((d) => +d["Return"])))
      .range([height - 100, 100]);

    let xNewScale = d3
      .scaleLinear()
      .domain(d3.extent(newDataset.map((d) => +d["Return"])))
      .range([50, width - 40]);

    let marketcapDomain = d3.extent(newDataset.map((d) => d["Market Cap"]));
    marketcapDomain = marketcapDomain.map((d) => Math.sqrt(d));
    let size = d3.scaleLinear().domain(marketcapDomain).range([4, 10]);

    //X-Axis
    let x_axis = d3.axisBottom().scale(xNewScale).ticks(8);
    let x_axisG = svg.selectAll(".x-axis").data([null]);
    x_axisG = x_axisG
      .enter()
      .append("g")
      .attr("class", "x-axis")
      .merge(x_axisG)
      .attr("transform", `translate(0,${height - 250})`);

    x_axisG.call(x_axis);

    //Y-Axis
    let y_axis = d3.axisLeft(yNewScale).tickValues(sectors);
    let y_axisG = d3.select("#main_canvas").selectAll(".y-axis").data([null]);
    y_axisG = y_axisG
      .enter()
      .append("g")
      .attr("class", "y-axis")
      .merge(y_axisG)
      .attr("transform", `translate(120,0)`);

    y_axisG.call(y_axis);
    // .attr("transform")

    svg
      .selectAll(".circ")
      .data(newDataset)
      .enter()
      .append("circle")
      .attr("class", "circ")

      .attr("fill", function (d) {
        let weight = getWeight(newMaxMin, parseFloat(d["Market Cap"]));
        const color = pickHex(weight, Color[8], Color[0]);
        return `rgba(${color[0]},${color[1]},${color[2]},0.9)`;
      })
      .attr("r", (d) => size(Math.sqrt(d["Market Cap"])))
      .attr("cx", (d) => xNewScale(d.Return))
      .attr("cy", (d) => yNewScale(d.Sector));

    let simulation = d3
      .forceSimulation(newDataset)
      .force(
        "x",
        d3
          .forceX((d) => {
            return xNewScale(d.Return);
          })
          .strength(2)
      )
      .force(
        "y",
        d3
          .forceY(function (d) {
            return yNewScale(d.Sector);
          })
          .strength(1)
      )
      .force(
        "collide",
        d3.forceCollide((d) => {
          return size(Math.sqrt(d["Market Cap"]));
        })
      )
      .alphaDecay(0)
      .alpha(0.3)
      .on("tick", tick);

    function tick() {
      d3.selectAll(".circ")
        .attr("cx", (d) => {
          return d.x;
        })
        .attr("cy", (d) => d.y);
    }

    let init_decay = setTimeout(function () {
      console.log("start alpha decay");
      simulation.alphaDecay(0.1);
    }, 3000);

    // Show tooltip when hovering over circle (data for respective country)
    d3.selectAll(".circ")
      .on("mousemove", function (event, d) {
        console.log("d--->", d);

        tooltip
          .html(
            `Node NAme: <strong>${d.Ticker}</strong><br>
                              X-Axis (Return) :
                              <strong>${d3.format(",")(d.Return)}</strong>
                              
                              <br>
                              Gradient (Feature Imp) :<strong>${d3.format(",")(
                                d["Market Cap"]
                              )}</strong>
                              </br>
                              Sector: <strong>${d.Sector}</strong><br>`
          )
          .style("top", event.pageY - 50 + "px")
          .style("left", event.pageX - 50 + "px")
          .style("opacity", 0.9)
          .transition();
      })
      .on("mouseout", function (_) {
        tooltip.style("opacity", 0);
      });
  }
  useEffect(() => {
    // Load and process data

    // Set chart domain max value to the highest total value in data set
    // xScale.domain(
    //   d3.extent(maindata, function (d) {
    //     return +d.total;
    //   })
    // );

    // redraw();

    newSwarm();
  }, []);

  return (
    <div
      id="svganchor"
      className="graph centered"
      style={{
        display: "table",
        // margin: "20px auto",
        width: "100%",
      }}
    >
      <div
        id="tooltip"
        className="tooltip"
        style={{
          opacity: 0,
          position: "absolute",
          textAlign: "left",
          color: "white",
          whiteSpace: "normal",
          padding: "6px",
          fontSize: "12px",
          background: " rgb(13, 14, 14)",

          borderRadius: "2px",
          pointerEvents: "none",
          cursor: "none",
          borderRadius: "10px",
        }}
      />
      <svg width={width + 50} height={height + 50} id={"main_canvas"}>
        <g className="y-axis" />
        <svg width={width} height={height} id={"chart_canvas"} />
        {/* {maindata &&
        maindata.map((d) => {
          let weight = getWeight(maxMin, parseFloat(d.population));
          const color = pickHex(weight, Color[0], Color[8]);

          return (
            <circle
              className="node1"
              r={6}
              cx={d.x}
              cy={d.y}
              fill={`rgba(${color[0]},${color[1]},${color[2]},1)`}
              onMouseMove={() => console.log("movement")}
            />
          );
        })} */}
      </svg>
    </div>
  );
};

export default ({ dataset, settings }) => {
  const { primaryDim, valueDim, legend } = settings;

  let transformedData = dataset.data.map((d) => ({
    sets: [d[dataset.fields[primaryDim]]],
    size: d[dataset.fields[valueDim]],
  }));

  transformedData[2] = {
    sets: transformedData[0].sets.concat(transformedData[1].sets),
    size: transformedData[2].size,
  };

  return (
    <Wrapper>
      {({ width, height }) => <BeeSwarm width={width} height={height} />}
    </Wrapper>
  );
};
