/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { LineChart, Line, LabelList, ReferenceLine } from "recharts";
import useCalculatedAxisDim from "../../useCalculatedAxisDim";
import {
  Color as DefaultColor,
  Color_Stack,
  Color_Stack_Bundles,
  ColorStacks,
  Blue,
} from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
  Tooltip,
} from "../../components";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  return (
    <svg x={cx - 3} y={cy - 3} width={8} height={8} fill="red" viewBox="0 0 8 8">
      <circle cx={4} cy={4} r={4} fill="#FFF" />
    </svg>
  );
};
const CustomizedDot2 = (props) => {
  const { cx, cy, stroke, payload, value,seriesColor } = props;
  console.log("dot props",props)
  return (
    <svg x={cx - 4} y={cy - 5} width={20} height={20} fill="red" viewBox="0 0 20 20">
     {/* <svg x={cx-4 } y={cy - 4} width={8} height={8} fill="red" viewBox="0 0 3 3"> */}
      {/* <div style={{ borderRadius: "50%", height: "2px", width: "2px", border: "2px solid blue" }}/> */}

        <circle cx={5} cy={5} r={4} fill={seriesColor} //"#c7c7c7" 
      />
     
    </svg>
  );
};

export default ({ id, dataset, settings }) => {
  const {
    orientation,
    primaryDim,
    tooltipConfig,
    primaryType,
    tickCount,
    legend,
    plot,
    axes,
    zeroReference,
    referencePoint,
    chartColor,
    colorSelect,
  } = settings;

  let Color = plot.aggregation === "Stacked" ? Color_Stack : DefaultColor;
  Color = colorSelect ? ColorStacks[colorSelect] : Color;

  let margin = {
    top: 15,
    bottom: 0,
    left: 15,
    right: 15,
  };

  if (orientation === "vertical") {
    margin = { top: 15, left: 25, right: 50, bottom: 15 };
  }

  if (legend.verticalAlign === "bottom") {
    margin.bottom = 15;
    margin.left = 25;
    margin.right = 25;
  }
  //to make dynamic series
  if (plot.seriesType && plot.seriesType.excludeColumns) {
    let {
      hideDot,
      color,
      axis,
      format,
      labelPosition,
      hideLabel,
      strokeWidth,
      highLight,
    } = plot.seriesType && plot.seriesType.typicalSeries;
    // let highLight = plot.seriesType.highLight;
    let newSeries = [];

    let colorCounter = plot.aggregation === "Stacked" ? 0 : 1;

    dataset.fields.forEach((x, index) => {
      if (!plot.seriesType.excludeColumns.includes(x)) {
        let defSeries = {
          hideDot: hideDot || false,
          dim: index,
          color: {
            type: (color && color.type) || "constant",
            value: (color && color.value) || colorCounter,
          },
          label:
            (plot.seriesType.alterFieldLabel &&
              plot.seriesType.alterFieldLabel(x)) ||
            x,
          axis: axis || 0,
          format: format || "0.0%",
          labelPosition: labelPosition || "top",
          hideLabel: hideLabel || true,
          strokeWidth: strokeWidth || 1,
          highlight: highLight, //? true: false,
        };

        colorCounter++;
        newSeries.push(defSeries);
      }
    });

    plot.series = [...newSeries];
    newSeries && newSeries.length == 1 ? (Color = Blue) : (Color = Color);

    // console.log("🚀 ~ file: chart.js ~ line 90 ~ newSeries", newSeries)
  }

  const calculatedPrimaryDim = useCalculatedAxisDim(axes.primary.dim, dataset);
  return (
    <Wrapper>
      {({ width, height }) => (
        <LineChart width={width} height={height} layout={orientation} data={dataset.data} margin={margin}>
          {Gradients({ chartId: id, orientation })}
          {plot.aggregation === "Overlay" &&
            plot.series.map((series, sIndex) =>
              PrimaryAxis({
                index: sIndex,
                orientation,
                dataKey: dataset.fields[primaryDim],
                show: sIndex === 0,
                label: axes.primary.label,
                type: primaryType,
                tickCount: tickCount,
              })
            )}
          {plot.aggregation !== "Overlay" &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: dataset.fields[primaryDim],
              show: true,
              type: primaryType,
              label: axes.primary.label,
              tickCount: tickCount,
            })}
          {axes.primary &&
            SecondaryAxis({
              index: 0,
              orientation,
              dataKey:
                dataset?.fields?.[
                  plot.aggregation === "Stacked"
                    ? undefined
                    : calculatedPrimaryDim
                ],
              show: axes.primary.show,
              squeeze: axes.primary.squeeze,
              hideTicks: axes.primary.hideTicks,
              format: axes.primary.format,
              equalize: axes.primary.equalize,
              tickCount: axes.primary.tickCount,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 1,
              orientation,
              dataKey: dataset.fields[axes.secondary.dim],
              show: axes.secondary?.show,
              squeeze: axes.secondary.squeeze,
              format: axes.secondary.format,
              //tickCount: tickCount,
              // equalize: axes.primary.equalize,
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => (
              <span style={{ color: "rgba(255, 255, 255, 0.66)" }}>
                {plot.series[index].label}
              </span>
            ),
          })}
          {zeroReference && ZeroLine({ orientation })}
          {referencePoint && <ReferenceLine x="70" stroke="#FFF" />}
          {Tooltip({
            formatter: (value, entry, props) => {
              const dim = dataset.fields.indexOf(props.dataKey);
              if (dim === -1) return value;
              const newValueDim =
                tooltipConfig &&
                tooltipConfig.find((a) => a?.dim == dim)?.replaceValueWith;
              const seriesElem = plot.series.find((s) => s.dim === dim);
              if (!seriesElem) return value;

              if (newValueDim >= 0) {
                return [
                  numeral(props?.payload[dataset.fields[newValueDim]])
                    .format(seriesElem.format)
                    ?.toUpperCase(),
                  seriesElem.label,
                ];
              }

              return [numeral(value).format(seriesElem.format)?.toUpperCase(), seriesElem.label];
            },
          })}
          {orientation === "horizontal" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const xAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const yAxisId = series.axis;
              return (
                <Line
                  type="monotone"
                  dot={(elem) => {
                    if (series.showAllDots) {
                      console.log("series printing", series);
                      return <CustomizedDot2 {...elem}  seriesColor={Color[series.color.value]}/>;
                    }
                    if (series.highlight) {
                      if (series.highlight.indexOf(elem.index) !== -1) {
                        //console.log("highlighting", elem);
                        if (elem.cx && elem.cy) return <CustomizedDot {...elem} />;
                      }
                    }

                    return series.hideDot;
                  }}
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset.fields[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  strokeWidth={series?.strokeWidth || 1}
                  stroke={Color[series.color.value]}>
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      formatter={(value) => numeral(value).format(series.format).toUpperCase()}
                    />
                  )}
                </Line>
              );
            })}
          {orientation === "vertical" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const yAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const xAxisId = series.axis;
              return (
                <Line
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset.fields[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  stroke={Color[series.color.value]}
                  dot={(elem) => {
                    if (series.highlight) {
                      if (series.highlight.indexOf(elem.index) !== -1) {
                        // console.log("highlighting", elem);
                        return <CustomizedDot {...elem} />;
                      }
                    }
                    return series.hideDot;
                  }}>
                  <LabelList
                    position={series.labelPosition}
                    formatter={(value) =>
                      numeral(value).format(series.format).toUpperCase()
                    }
                  />
                </Line>
              );
            })}
        </LineChart>
      )}
    </Wrapper>
  );
};
