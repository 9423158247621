export const mockShapdata = {
  data: [
    {
      name: "Node 1",
      total: 2,
      population: 95,
      perCapita: 5.295,
      continent: "africa",
    },
    {
      name: "Node 2",
      total: 9,
      population: 118,
      perCapita: 10.904,
      continent: "africa",
    },
    {
      name: "Node 3",
      total: 2,
      population: 117,
      perCapita: 2.06,
      continent: "northAmerica",
    },
    {
      name: "Node 4",
      total: 14,
      population: 114,
      perCapita: 8.496,
      continent: "northAmerica",
    },
    {
      name: "Node 5",
      total: 3,
      population: 116,
      perCapita: 1.566,
      continent: "africa",
    },
    {
      name: "Node 6",
      total: 19,
      population: 120,
      perCapita: 8.533,
      continent: "southAmerica",
    },
    {
      name: "Node 7",
      total: 49,
      population: 127,
      perCapita: 16.309,
      continent: "europe",
    },
    {
      name: "Node 8",
      total: 17,
      population: 124,
      perCapita: 5.558,
      continent: "northAmerica",
    },
    {
      name: "Node 9",
      total: 5,
      population: 115,
      perCapita: 1.433,
      continent: "northAmerica",
    },
    {
      name: "Node 10",
      total: 27,
      population: 114,
      perCapita: 7.34,
      continent: "northAmerica",
    },
    {
      name: "Node 11",
      total: 4,
      population: 93,
      perCapita: 1.071,
      continent: "asia",
    },
    {
      name: "Node 12",
      total: 22,
      population: 94,
      perCapita: 5.463,
      continent: "europe",
    },
    {
      name: "Node 13",
      total: 39,
      population: 98,
      perCapita: 9.195,
      continent: "northAmerica",
    },
    {
      name: "Node 14",
      total: 131,
      population: 110,
      perCapita: 26.607,
      continent: "southAmerica",
    },
    {
      name: "Node 15",
      total: 40,
      population: 90,
      perCapita: 7.802,
      continent: "europe",
    },
    {
      name: "Node 16",
      total: 210,
      population: 102,
      perCapita: 30.708,
      continent: "southAmerica",
    },
    {
      name: "Node 17",
      total: 93,
      population: 128,
      perCapita: 11.904,
      continent: "africa",
    },
    {
      name: "Node 18",
      total: 45,
      population: 121,
      perCapita: 5.535,
      continent: "europe",
    },
    {
      name: "Node 19",
      total: 98,
      population: 105,
      perCapita: 8.541,
      continent: "southAmerica",
    },
    {
      name: "Node 20",
      total: 5,
      population: 90,
      perCapita: 0.412,
      continent: "asia",
    },
    {
      name: "Node 21",
      total: 218,
      population: 124,
      perCapita: 17.537,
      continent: "europe",
    },
    {
      name: "Node 22",
      total: 383,
      population: 102,
      perCapita: 20.027,
      continent: "europe",
    },
    {
      name: "Node 23",
      total: 170,
      population: 122,
      perCapita: 8.729,
      continent: "europe",
    },
    {
      name: "Node 24",
      total: 446,
      population: 109,
      perCapita: 22.892,
      continent: "europe",
    },
    {
      name: "Node 25",
      total: 37,
      population: 94,
      perCapita: 1.733,
      continent: "asia",
    },
    {
      name: "Node 26",
      total: 1085,
      population: 112,
      perCapita: 38.659,
      continent: "europe",
    },
    {
      name: "Node 27",
      total: 67,
      population: 104,
      perCapita: 2.384,
      continent: "europe",
    },
    {
      name: "Node 28",
      total: 546,
      population: 104,
      perCapita: 17.253,
      continent: "southAmerica",
    },
    {
      name: "Node 29",
      total: 576,
      population: 115,
      perCapita: 17.123,
      continent: "europe",
    },
    {
      name: "Node 30",
      total: 130,
      population: 107,
      perCapita: 3.76,
      continent: "northAmerica",
    },
    {
      name: "Node 31",
      total: 241,
      population: 1,
      perCapita: 6.942,
      continent: "northAmerica",
    },
    {
      name: "Node 32",
      total: 41,
      population: 55,
      perCapita: 1.147,
      continent: "asia",
    },
    {
      name: "Node 33",
      total: 357,
      population: 24,
      perCapita: 9.75,
      continent: "asia",
    },
    {
      name: "Node 34",
      total: 692,
      population: 1,
      perCapita: 17.11,
      continent: "europe",
    },
    {
      name: "Node 35",
      total: 513,
      population: 52,
      perCapita: 12.422,
      continent: "oceania",
    },
    {
      name: "Node 36",
      total: 157,
      population: 79,
      perCapita: 3.745,
      continent: "europe",
    },
    {
      name: "Node 37",
      total: 487,
      population: 40,
      perCapita: 11.52,
      continent: "europe",
    },
    {
      name: "Node 38",
      total: 311,
      population: 67,
      perCapita: 7.154,
      continent: "northAmerica",
    },
    {
      name: "Node 39",
      total: 101,
      population: 15,
      perCapita: 2.127,
      continent: "asia",
    },
    {
      name: "Node 40",
      total: 554,
      population: 19,
      perCapita: 11.62,
      continent: "europe",
    },
    {
      name: "Node 41",
      total: 446,
      population: 13,
      perCapita: 8.898,
      continent: "asia",
    },
    {
      name: "Node 42",
      total: 616,
      population: 14,
      perCapita: 12.026,
      continent: "europe",
    },
    {
      name: "Node 43",
      total: 887,
      population: 26,
      perCapita: 17.272,
      continent: "europe",
    },
    {
      name: "Node 44",
      total: 610,
      population: 58,
      perCapita: 11.51,
      continent: "europe",
    },
    {
      name: "Node 45",
      total: 351,
      population: 12,
      perCapita: 6.589,
      continent: "northAmerica",
    },
    {
      name: "Node 46",
      total: 484,
      population: 11,
      perCapita: 8.555,
      continent: "northAmerica",
    },
    {
      name: "Node 47",
      total: 323,
      population: 73,
      perCapita: 5.582,
      continent: "southAmerica",
    },
    {
      name: "Node 48",
      total: 1197,
      population: 80,
      perCapita: 17.518,
      continent: "europe",
    },
    {
      name: "Node 49",
      total: 997,
      population: 59,
      perCapita: 14.409,
      continent: "asia",
    },
    {
      name: "Node 50",
      total: 721,
      population: 67,
      perCapita: 10.418,
      continent: "europe",
    },
    {
      name: "Node 51",
      total: 372,
      population: 20,
      perCapita: 5.148,
      continent: "asia",
    },
    {
      name: "Node 52",
      total: 1071,
      population: 80,
      perCapita: 13.943,
      continent: "europe",
    },
    {
      name: "Node 53",
      total: 1291,
      population: 51,
      perCapita: 15.978,
      continent: "europe",
    },
    {
      name: "Node 54",
      total: 1902,
      population: 25,
      perCapita: 21.346,
      continent: "europe",
    },
    {
      name: "Node 55",
      total: 1230,
      population: 77,
      perCapita: 13.63,
      continent: "europe",
    },
    {
      name: "Node 56",
      total: 2092,
      population: 42,
      perCapita: 22.175,
      continent: "europe",
    },
    {
      name: "Node 57",
      total: 1577,
      population: 37,
      perCapita: 15.87,
      continent: "europe",
    },
    {
      name: "Node 58",
      total: 1052,
      population: 52,
      perCapita: 10.536,
      continent: "europe",
    },
    {
      name: "Node 59",
      total: 533,
      population: 34,
      perCapita: 5.11,
      continent: "europe",
    },
    {
      name: "Node 60",
      total: 1895,
      population: 11,
      perCapita: 18.003,
      continent: "europe",
    },
    {
      name: "Node 61",
      total: 1499,
      population: 47,
      perCapita: 13.889,
      continent: "northAmerica",
    },
    {
      name: "Node 62",
      total: 455,
      population: 70,
      perCapita: 3.335,
      continent: "northAmerica",
    },
    {
      name: "Node 63",
      total: 676,
      population: 35,
      perCapita: 4.81,
      continent: "southAmerica",
    },
    {
      name: "Node 64",
      total: 3512,
      population: 74,
      perCapita: 23.082,
      continent: "europe",
    },
    {
      name: "Node 65",
      total: 1856,
      population: 156,
      perCapita: 11.673,
      continent: "europe",
    },
    {
      name: "Node 66",
      total: 1738,
      population: 173,
      perCapita: 10.7,
      continent: "southAmerica",
    },
    {
      name: "Node 67",
      total: 2407,
      population: 179,
      perCapita: 12.666,
      continent: "europe",
    },
    {
      name: "Node 68",
      total: 2608,
      population: 172,
      perCapita: 12.074,
      continent: "oceania",
    },
    {
      name: "Node 69",
      total: 1950,
      population: 189,
      perCapita: 7.266,
      continent: "asia",
    },
    {
      name: "Node 70",
      total: 618,
      population: 166,
      perCapita: 2.26,
      continent: "southAmerica",
    },
    {
      name: "Node 71",
      total: 4054,
      population: 153,
      perCapita: 12.169,
      continent: "northAmerica",
    },
    {
      name: "Node 72",
      total: 6218,
      population: 195,
      perCapita: 17.261,
      continent: "europe",
    },
    {
      name: "Node 73",
      total: 2987,
      population: 185,
      perCapita: 7.687,
      continent: "southAmerica",
    },
    {
      name: "Node 74",
      total: 2068,
      population: 190,
      perCapita: 4.749,
      continent: "southAmerica",
    },
    {
      name: "Node 75",
      total: 3873,
      population: 182,
      perCapita: 8.757,
      continent: "europe",
    },
    {
      name: "Node 76",
      total: 587,
      population: 200,
      perCapita: 1.218,
      continent: "africa",
    },
    {
      name: "Node 77",
      total: 14426,
      population: 185,
      perCapita: 29.9,
      continent: "asia",
    },
    {
      name: "Node 78",
      total: 4290,
      population: 190,
      perCapita: 7.46,
      continent: "europe",
    },
    {
      name: "Node 79",
      total: 9600,
      population: 174,
      perCapita: 16.024,
      continent: "europe",
    },
    {
      name: "Node 80",
      total: 4824,
      population: 179,
      perCapita: 8.028,
      continent: "europe",
    },
    {
      name: "Node 81",
      total: 3961,
      population: 175,
      perCapita: 6.518,
      continent: "asia",
    },
    {
      name: "Node 82",
      total: 1810,
      population: 182,
      perCapita: 2.59,
      continent: "europe",
    },
    {
      name: "Node 83",
      total: 2442,
      population: 182,
      perCapita: 3.46,
      continent: "asia",
    },
    {
      name: "Node 84",
      total: 10091,
      population: 169,
      perCapita: 13.059,
      continent: "europe",
    },
    {
      name: "Node 85",
      total: 149,
      population: 162,
      perCapita: 0.19,
      continent: "africa",
    },
    {
      name: "Node 86",
      total: 5711,
      population: 168,
      perCapita: 5.143,
      continent: "northAmerica",
    },
    {
      name: "Node 87",
      total: 25991,
      population: 188,
      perCapita: 21.567,
      continent: "asia",
    },
    {
      name: "Node 88",
      total: 28690,
      population: 191,
      perCapita: 21.297,
      continent: "europe",
    },
    {
      name: "Node 89",
      total: 10513,
      population: 155,
      perCapita: 5.608,
      continent: "southAmerica",
    },
    {
      name: "Node 90",
      total: 41143,
      population: 154,
      perCapita: 13.932,
      continent: "northAmerica",
    },
  ],
  columns: ["name", "total", "population", "perCapita", "continent"],
};

export const newShapData = [
  {
    Ticker: "MSFT",
    Return: 0.1637,
    "Market Cap": 400.23,
    Sector: "Technology",
  },
  {
    Ticker: "AAPL",
    Return: 0.086,
    "Market Cap": 26.7,
    Sector: "Technology",
  },
  {
    Ticker: "AMZN",
    Return: 0.3188,
    "Market Cap": 998.09,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "FB",
    Return: 0.1445,
    "Market Cap": 541.53,
    Sector: "Communication Services",
  },
  {
    Ticker: "GOOG",
    Return: 0.0549,
    "Market Cap": 546.79,
    Sector: "Communication Services",
  },
  {
    Ticker: "JNJ",
    Return: -0.0103,
    "Market Cap": 386.07,
    Sector: "Healthcare",
  },
  {
    Ticker: "BRK-B",
    Return: -0.2277,
    "Market Cap": 361.37,
    Sector: "Financial",
  },
  {
    Ticker: "V",
    Return: 0.0158,
    "Market Cap": 331.91,
    Sector: "Financial",
  },
  {
    Ticker: "JPM",
    Return: -0.3582,
    "Market Cap": 312.61,
    Sector: "Financial",
  },
  {
    Ticker: "PG",
    Return: -0.0985,
    "Market Cap": 288.88,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "INTC",
    Return: 0.0402,
    "Market Cap": 267.88,
    Sector: "Technology",
  },
  {
    Ticker: "UNH",
    Return: -0.0137,
    "Market Cap": 287.44,
    Sector: "Healthcare",
  },
  {
    Ticker: "MA",
    Return: -0.0123,
    "Market Cap": 267.85,
    Sector: "Financial",
  },
  {
    Ticker: "HD",
    Return: -0.0123,
    "Market Cap": 266.9,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "VZ",
    Return: -0.1189,
    "Market Cap": 230.01,
    Sector: "Communication Services",
  },
  {
    Ticker: "NVDA",
    Return: 0.5344,
    "Market Cap": 207.37,
    Sector: "Technology",
  },
  {
    Ticker: "T",
    Return: -0.2354,
    "Market Cap": 226.46,
    Sector: "Communication Services",
  },
  {
    Ticker: "DIS",
    Return: -0.184,
    "Market Cap": 210.03,
    Sector: "Communication Services",
  },
  {
    Ticker: "PFE",
    Return: -0.0429,
    "Market Cap": 210.89,
    Sector: "Healthcare",
  },
  {
    Ticker: "NFLX",
    Return: 0.3268,
    "Market Cap": 180.85,
    Sector: "Communication Services",
  },
  {
    Ticker: "MRK",
    Return: -0.1603,
    "Market Cap": 200.85,
    Sector: "Healthcare",
  },
  {
    Ticker: "CSCO",
    Return: -0.0638,
    "Market Cap": 193.06,
    Sector: "Technology",
  },
  {
    Ticker: "XOM",
    Return: -0.3608,
    "Market Cap": 190.21,
    Sector: "Energy",
  },
  {
    Ticker: "ADBE",
    Return: 0.1681,
    "Market Cap": 183.52,
    Sector: "Technology",
  },
  {
    Ticker: "BAC",
    Return: -0.3566,
    "Market Cap": 200.87,
    Sector: "Financial",
  },
  {
    Ticker: "PEP",
    Return: -0.0453,
    "Market Cap": 184.12,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "WMT",
    Return: 0.0462,
    "Market Cap": 175.13,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CMCSA",
    Return: -0.1385,
    "Market Cap": 179.21,
    Sector: "Communication Services",
  },
  {
    Ticker: "PYPL",
    Return: 0.3947,
    "Market Cap": 174.38,
    Sector: "Financial",
  },
  {
    Ticker: "KO",
    Return: -0.1864,
    "Market Cap": 181.24,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CVX",
    Return: -0.2509,
    "Market Cap": 171.5,
    Sector: "Energy",
  },
  {
    Ticker: "ABBV",
    Return: 0.0402,
    "Market Cap": 158.43,
    Sector: "Healthcare",
  },
  {
    Ticker: "ABT",
    Return: 0.0531,
    "Market Cap": 162.47,
    Sector: "Healthcare",
  },
  {
    Ticker: "CRM",
    Return: 0.0935,
    "Market Cap": 160.42,
    Sector: "Technology",
  },
  {
    Ticker: "BMY",
    Return: -0.053,
    "Market Cap": 140.01,
    Sector: "Healthcare",
  },
  {
    Ticker: "MCD",
    Return: -0.0668,
    "Market Cap": 141.86,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "TMO",
    Return: 0.0474,
    "Market Cap": 137.25,
    Sector: "Healthcare",
  },
  {
    Ticker: "AMGN",
    Return: -0.0607,
    "Market Cap": 132.99,
    Sector: "Healthcare",
  },
  {
    Ticker: "COST",
    Return: 0.029,
    "Market Cap": 136.57,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "MDT",
    Return: -0.1651,
    "Market Cap": 131.3,
    Sector: "Healthcare",
  },
  {
    Ticker: "LLY",
    Return: 0.1501,
    "Market Cap": 127.05,
    Sector: "Healthcare",
  },
  {
    Ticker: "ACN",
    Return: -0.0801,
    "Market Cap": 127.83,
    Sector: "Technology",
  },
  {
    Ticker: "NKE",
    Return: -0.0746,
    "Market Cap": 122.45,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UNP",
    Return: -0.0865,
    "Market Cap": 118.14,
    Sector: "Industrials",
  },
  {
    Ticker: "ORCL",
    Return: -0.0068,
    "Market Cap": 115.94,
    Sector: "Technology",
  },
  {
    Ticker: "NEE",
    Return: -0.0343,
    "Market Cap": 122.63,
    Sector: "Utilities",
  },
  {
    Ticker: "AVGO",
    Return: -0.1246,
    "Market Cap": 112.1,
    Sector: "Technology",
  },
  {
    Ticker: "TXN",
    Return: -0.1158,
    "Market Cap": 108.11,
    Sector: "Technology",
  },
  {
    Ticker: "PM",
    Return: -0.1721,
    "Market Cap": 114.16,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "IBM",
    Return: -0.1168,
    "Market Cap": 110.08,
    Sector: "Technology",
  },
  {
    Ticker: "LIN",
    Return: -0.1035,
    "Market Cap": 107.25,
    Sector: "Basic Materials",
  },
  {
    Ticker: "AMT",
    Return: 0.0549,
    "Market Cap": 114.12,
    Sector: "Real Estate",
  },
  {
    Ticker: "DHR",
    Return: 0.0311,
    "Market Cap": 103.06,
    Sector: "Healthcare",
  },
  {
    Ticker: "C",
    Return: -0.448,
    "Market Cap": 107.12,
    Sector: "Financial",
  },
  {
    Ticker: "HON",
    Return: -0.2137,
    "Market Cap": 105.24,
    Sector: "Industrials",
  },
  {
    Ticker: "WFC",
    Return: -0.5507,
    "Market Cap": 104.56,
    Sector: "Financial",
  },
  {
    Ticker: "GILD",
    Return: 0.1287,
    "Market Cap": 95.16,
    Sector: "Healthcare",
  },
  {
    Ticker: "QCOM",
    Return: -0.108,
    "Market Cap": 89.02,
    Sector: "Technology",
  },
  {
    Ticker: "SBUX",
    Return: -0.1172,
    "Market Cap": 92.65,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "LMT",
    Return: -0.0523,
    "Market Cap": 98.77,
    Sector: "Industrials",
  },
  {
    Ticker: "LOW",
    Return: 0.0208,
    "Market Cap": 98.02,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "MMM",
    Return: -0.1699,
    "Market Cap": 90.06,
    Sector: "Industrials",
  },
  {
    Ticker: "FIS",
    Return: -0.0211,
    "Market Cap": 84.94,
    Sector: "Technology",
  },
  {
    Ticker: "RTX",
    Return: -0.3625,
    "Market Cap": 98.29,
    Sector: "Industrials",
  },
  {
    Ticker: "CVS",
    Return: -0.1475,
    "Market Cap": 86.49,
    Sector: "Healthcare",
  },
  {
    Ticker: "CHTR",
    Return: 0.0574,
    "Market Cap": 83.73,
    Sector: "Communication Services",
  },
  {
    Ticker: "BLK",
    Return: 0.0211,
    "Market Cap": 81.74,
    Sector: "Financial",
  },
  {
    Ticker: "SPGI",
    Return: 0.1421,
    "Market Cap": 77.77,
    Sector: "Financial",
  },
  {
    Ticker: "INTU",
    Return: 0.098,
    "Market Cap": 73.72,
    Sector: "Technology",
  },
  {
    Ticker: "NOW",
    Return: 0.3742,
    "Market Cap": 71.2,
    Sector: "Technology",
  },
  {
    Ticker: "VRTX",
    Return: 0.3015,
    "Market Cap": 71.24,
    Sector: "Healthcare",
  },
  {
    Ticker: "MDLZ",
    Return: -0.0977,
    "Market Cap": 73.92,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "BA",
    Return: -0.5778,
    "Market Cap": 80.01,
    Sector: "Industrials",
  },
  {
    Ticker: "ANTM",
    Return: -0.0825,
    "Market Cap": 73.79,
    Sector: "Healthcare",
  },
  {
    Ticker: "MO",
    Return: -0.244,
    "Market Cap": 72.42,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CI",
    Return: -0.0757,
    "Market Cap": 74.81,
    Sector: "Healthcare",
  },
  {
    Ticker: "UPS",
    Return: -0.1738,
    "Market Cap": 69.8,
    Sector: "Industrials",
  },
  {
    Ticker: "BKNG",
    Return: -0.2057,
    "Market Cap": 68.94,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "AMD",
    Return: 0.203,
    "Market Cap": 60.45,
    Sector: "Technology",
  },
  {
    Ticker: "BDX",
    Return: -0.1203,
    "Market Cap": 71.11,
    Sector: "Healthcare",
  },
  {
    Ticker: "D",
    Return: -0.0279,
    "Market Cap": 68.8,
    Sector: "Utilities",
  },
  {
    Ticker: "CME",
    Return: -0.1092,
    "Market Cap": 64.59,
    Sector: "Financial",
  },
  {
    Ticker: "PLD",
    Return: -0.0178,
    "Market Cap": 68.61,
    Sector: "Real Estate",
  },
  {
    Ticker: "ISRG",
    Return: -0.048,
    "Market Cap": 66.07,
    Sector: "Healthcare",
  },
  {
    Ticker: "CAT",
    Return: -0.2384,
    "Market Cap": 66.68,
    Sector: "Industrials",
  },
  {
    Ticker: "ZTS",
    Return: -0.0169,
    "Market Cap": 65.66,
    Sector: "Healthcare",
  },
  {
    Ticker: "CCI",
    Return: 0.0988,
    "Market Cap": 70.09,
    Sector: "Real Estate",
  },
  {
    Ticker: "TJX",
    Return: -0.1253,
    "Market Cap": 65.46,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "SYK",
    Return: -0.1229,
    "Market Cap": 62.86,
    Sector: "Healthcare",
  },
  {
    Ticker: "AXP",
    Return: -0.2824,
    "Market Cap": 65.67,
    Sector: "Financial",
  },
  {
    Ticker: "DUK",
    Return: -0.0875,
    "Market Cap": 62.49,
    Sector: "Utilities",
  },
  {
    Ticker: "TGT",
    Return: -0.0836,
    "Market Cap": 59.94,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "FISV",
    Return: -0.1012,
    "Market Cap": 60.61,
    Sector: "Technology",
  },
  {
    Ticker: "CL",
    Return: 0.0277,
    "Market Cap": 61.67,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "ADP",
    Return: -0.209,
    "Market Cap": 62.58,
    Sector: "Industrials",
  },
  {
    Ticker: "EQIX",
    Return: 0.1479,
    "Market Cap": 61.05,
    Sector: "Real Estate",
  },
  {
    Ticker: "GS",
    Return: -0.2175,
    "Market Cap": 64,
    Sector: "Financial",
  },
  {
    Ticker: "SO",
    Return: -0.1432,
    "Market Cap": 59.64,
    Sector: "Utilities",
  },
  {
    Ticker: "ATVI",
    Return: 0.2299,
    "Market Cap": 53.76,
    Sector: "Communication Services",
  },
  {
    Ticker: "GE",
    Return: -0.4256,
    "Market Cap": 59.1,
    Sector: "Industrials",
  },
  {
    Ticker: "BIIB",
    Return: 0.0263,
    "Market Cap": 54.15,
    Sector: "Healthcare",
  },
  {
    Ticker: "GPN",
    Return: -0.0318,
    "Market Cap": 53.4,
    Sector: "Industrials",
  },
  {
    Ticker: "NEM",
    Return: 0.4511,
    "Market Cap": 47.95,
    Sector: "Basic Materials",
  },
  {
    Ticker: "CSX",
    Return: -0.0529,
    "Market Cap": 55.38,
    Sector: "Industrials",
  },
  {
    Ticker: "APD",
    Return: -0.0027,
    "Market Cap": 53.03,
    Sector: "Basic Materials",
  },
  {
    Ticker: "ILMN",
    Return: 0.0692,
    "Market Cap": 54.81,
    Sector: "Healthcare",
  },
  {
    Ticker: "AMAT",
    Return: -0.1089,
    "Market Cap": 50.43,
    Sector: "Technology",
  },
  {
    Ticker: "MMC",
    Return: -0.062,
    "Market Cap": 53.73,
    Sector: "Financial",
  },
  {
    Ticker: "ICE",
    Return: 0.0132,
    "Market Cap": 52.77,
    Sector: "Financial",
  },
  {
    Ticker: "BSX",
    Return: -0.2059,
    "Market Cap": 52.2,
    Sector: "Healthcare",
  },
  {
    Ticker: "HUM",
    Return: 0.0761,
    "Market Cap": 53.45,
    Sector: "Healthcare",
  },
  {
    Ticker: "MU",
    Return: -0.1644,
    "Market Cap": 51.42,
    Sector: "Technology",
  },
  {
    Ticker: "ECL",
    Return: 0.0389,
    "Market Cap": 52.93,
    Sector: "Basic Materials",
  },
  {
    Ticker: "NOC",
    Return: -0.0525,
    "Market Cap": 53.65,
    Sector: "Industrials",
  },
  {
    Ticker: "COP",
    Return: -0.3345,
    "Market Cap": 47.17,
    Sector: "Energy",
  },
  {
    Ticker: "ITW",
    Return: -0.0884,
    "Market Cap": 50.51,
    Sector: "Industrials",
  },
  {
    Ticker: "MS",
    Return: -0.215,
    "Market Cap": 51.61,
    Sector: "Financial",
  },
  {
    Ticker: "CB",
    Return: -0.2484,
    "Market Cap": 56,
    Sector: "Financial",
  },
  {
    Ticker: "USB",
    Return: -0.4446,
    "Market Cap": 51.87,
    Sector: "Financial",
  },
  {
    Ticker: "KMB",
    Return: 0.0159,
    "Market Cap": 48.24,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "SHW",
    Return: -0.0107,
    "Market Cap": 48.38,
    Sector: "Basic Materials",
  },
  {
    Ticker: "REGN",
    Return: 0.5178,
    "Market Cap": 58.09,
    Sector: "Healthcare",
  },
  {
    Ticker: "EW",
    Return: -0.0621,
    "Market Cap": 46.14,
    Sector: "Healthcare",
  },
  {
    Ticker: "AON",
    Return: -0.0784,
    "Market Cap": 46.47,
    Sector: "Financial",
  },
  {
    Ticker: "PNC",
    Return: -0.3447,
    "Market Cap": 50.72,
    Sector: "Financial",
  },
  {
    Ticker: "DG",
    Return: 0.1475,
    "Market Cap": 46.82,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "TFC",
    Return: -0.4089,
    "Market Cap": 51.1,
    Sector: "Financial",
  },
  {
    Ticker: "NSC",
    Return: -0.1167,
    "Market Cap": 46.95,
    Sector: "Industrials",
  },
  {
    Ticker: "DE",
    Return: -0.1879,
    "Market Cap": 48.14,
    Sector: "Industrials",
  },
  {
    Ticker: "BAX",
    Return: 0.0396,
    "Market Cap": 44.94,
    Sector: "Healthcare",
  },
  {
    Ticker: "PGR",
    Return: 0.0322,
    "Market Cap": 44.76,
    Sector: "Financial",
  },
  {
    Ticker: "ADSK",
    Return: 0.0672,
    "Market Cap": 44.49,
    Sector: "Technology",
  },
  {
    Ticker: "ADI",
    Return: -0.0655,
    "Market Cap": 40.82,
    Sector: "Technology",
  },
  {
    Ticker: "MCO",
    Return: 0.0818,
    "Market Cap": 42.94,
    Sector: "Financial",
  },
  {
    Ticker: "EL",
    Return: -0.1372,
    "Market Cap": 42.79,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "LHX",
    Return: -0.088,
    "Market Cap": 43.44,
    Sector: "Industrials",
  },
  {
    Ticker: "LRCX",
    Return: -0.1069,
    "Market Cap": 38.57,
    Sector: "Technology",
  },
  {
    Ticker: "WM",
    Return: -0.132,
    "Market Cap": 41.49,
    Sector: "Industrials",
  },
  {
    Ticker: "SCHW",
    Return: -0.3097,
    "Market Cap": 41.12,
    Sector: "Financial",
  },
  {
    Ticker: "AEP",
    Return: -0.1664,
    "Market Cap": 41.99,
    Sector: "Utilities",
  },
  {
    Ticker: "ROP",
    Return: 0.0362,
    "Market Cap": 39.61,
    Sector: "Industrials",
  },
  {
    Ticker: "CNC",
    Return: 0.0313,
    "Market Cap": 38.88,
    Sector: "Healthcare",
  },
  {
    Ticker: "TMUS",
    Return: 0.2216,
    "Market Cap": 37.43,
    Sector: "Communication Services",
  },
  {
    Ticker: "GIS",
    Return: 0.1195,
    "Market Cap": 37.37,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "DXCM",
    Return: 0.8527,
    "Market Cap": 32.98,
    Sector: "Healthcare",
  },
  {
    Ticker: "DD",
    Return: -0.2416,
    "Market Cap": 37.36,
    Sector: "Basic Materials",
  },
  {
    Ticker: "EXC",
    Return: -0.1917,
    "Market Cap": 37.13,
    Sector: "Utilities",
  },
  {
    Ticker: "EA",
    Return: 0.1145,
    "Market Cap": 35.38,
    Sector: "Communication Services",
  },
  {
    Ticker: "DLR",
    Return: 0.1342,
    "Market Cap": 38.34,
    Sector: "Real Estate",
  },
  {
    Ticker: "PSX",
    Return: -0.3137,
    "Market Cap": 34.86,
    Sector: "Energy",
  },
  {
    Ticker: "SRE",
    Return: -0.1827,
    "Market Cap": 35.87,
    Sector: "Utilities",
  },
  {
    Ticker: "EMR",
    Return: -0.2674,
    "Market Cap": 37.13,
    Sector: "Industrials",
  },
  {
    Ticker: "ROST",
    Return: -0.1936,
    "Market Cap": 35.5,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "EBAY",
    Return: 0.2033,
    "Market Cap": 33.78,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "ETN",
    Return: -0.1694,
    "Market Cap": 35.15,
    Sector: "Industrials",
  },
  {
    Ticker: "GD",
    Return: -0.2142,
    "Market Cap": 34.75,
    Sector: "Industrials",
  },
  {
    Ticker: "GM",
    Return: -0.2902,
    "Market Cap": 33.53,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "SBAC",
    Return: 0.2032,
    "Market Cap": 34.94,
    Sector: "Real Estate",
  },
  {
    Ticker: "XEL",
    Return: -0.0491,
    "Market Cap": 33.58,
    Sector: "Utilities",
  },
  {
    Ticker: "ALL",
    Return: -0.1568,
    "Market Cap": 31.79,
    Sector: "Financial",
  },
  {
    Ticker: "ORLY",
    Return: -0.0759,
    "Market Cap": 31.22,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "EOG",
    Return: -0.3758,
    "Market Cap": 30.01,
    Sector: "Energy",
  },
  {
    Ticker: "KMI",
    Return: -0.2721,
    "Market Cap": 30.43,
    Sector: "Energy",
  },
  {
    Ticker: "WBA",
    Return: -0.3284,
    "Market Cap": 31.32,
    Sector: "Healthcare",
  },
  {
    Ticker: "BK",
    Return: -0.3145,
    "Market Cap": 31.51,
    Sector: "Financial",
  },
  {
    Ticker: "MSCI",
    Return: 0.309,
    "Market Cap": 27.35,
    Sector: "Financial",
  },
  {
    Ticker: "CTSH",
    Return: -0.1704,
    "Market Cap": 29.35,
    Sector: "Technology",
  },
  {
    Ticker: "STZ",
    Return: -0.0919,
    "Market Cap": 29.31,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "COF",
    Return: -0.4149,
    "Market Cap": 31.86,
    Sector: "Financial",
  },
  {
    Ticker: "HCA",
    Return: -0.2782,
    "Market Cap": 29.01,
    Sector: "Healthcare",
  },
  {
    Ticker: "KLAC",
    Return: -0.029,
    "Market Cap": 27.58,
    Sector: "Technology",
  },
  {
    Ticker: "FDX",
    Return: -0.2262,
    "Market Cap": 31.3,
    Sector: "Industrials",
  },
  {
    Ticker: "VLO",
    Return: -0.2987,
    "Market Cap": 27.51,
    Sector: "Energy",
  },
  {
    Ticker: "PSA",
    Return: -0.1244,
    "Market Cap": 30.02,
    Sector: "Real Estate",
  },
  {
    Ticker: "WEC",
    Return: -0.066,
    "Market Cap": 27.89,
    Sector: "Utilities",
  },
  {
    Ticker: "INFO",
    Return: -0.1107,
    "Market Cap": 27.73,
    Sector: "Industrials",
  },
  {
    Ticker: "APH",
    Return: -0.161,
    "Market Cap": 28.23,
    Sector: "Technology",
  },
  {
    Ticker: "DOW",
    Return: -0.34,
    "Market Cap": 28.67,
    Sector: "Basic Materials",
  },
  {
    Ticker: "TROW",
    Return: -0.0554,
    "Market Cap": 28.22,
    Sector: "Financial",
  },
  {
    Ticker: "SYY",
    Return: -0.395,
    "Market Cap": 28.49,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "YUM",
    Return: -0.1338,
    "Market Cap": 27.51,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "AZO",
    Return: -0.0574,
    "Market Cap": 27.31,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "MNST",
    Return: 0.0812,
    "Market Cap": 27,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CMG",
    Return: 0.2611,
    "Market Cap": 25.4,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "IQV",
    Return: -0.0751,
    "Market Cap": 26.33,
    Sector: "Healthcare",
  },
  {
    Ticker: "TEL",
    Return: -0.2046,
    "Market Cap": 27.72,
    Sector: "Technology",
  },
  {
    Ticker: "ZBH",
    Return: -0.1756,
    "Market Cap": 26.29,
    Sector: "Healthcare",
  },
  {
    Ticker: "VRSK",
    Return: 0.0532,
    "Market Cap": 27.45,
    Sector: "Industrials",
  },
  {
    Ticker: "WLTW",
    Return: -0.017,
    "Market Cap": 26.4,
    Sector: "Financial",
  },
  {
    Ticker: "MET",
    Return: -0.3459,
    "Market Cap": 28.46,
    Sector: "Financial",
  },
  {
    Ticker: "KR",
    Return: 0.1166,
    "Market Cap": 25.61,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "ES",
    Return: -0.0777,
    "Market Cap": 26.21,
    Sector: "Utilities",
  },
  {
    Ticker: "CLX",
    Return: 0.2934,
    "Market Cap": 25.69,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "HPQ",
    Return: -0.1727,
    "Market Cap": 22.25,
    Sector: "Technology",
  },
  {
    Ticker: "AFL",
    Return: -0.344,
    "Market Cap": 26.84,
    Sector: "Financial",
  },
  {
    Ticker: "IDXX",
    Return: 0.1286,
    "Market Cap": 26.22,
    Sector: "Healthcare",
  },
  {
    Ticker: "A",
    Return: -0.0039,
    "Market Cap": 26.84,
    Sector: "Healthcare",
  },
  {
    Ticker: "SLB",
    Return: -0.5627,
    "Market Cap": 25.98,
    Sector: "Energy",
  },
  {
    Ticker: "MAR",
    Return: -0.3987,
    "Market Cap": 24.82,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "TWTR",
    Return: 0.0178,
    "Market Cap": 24.43,
    Sector: "Communication Services",
  },
  {
    Ticker: "CMI",
    Return: -0.1337,
    "Market Cap": 25.98,
    Sector: "Industrials",
  },
  {
    Ticker: "CDNS",
    Return: 0.2406,
    "Market Cap": 24.74,
    Sector: "Technology",
  },
  {
    Ticker: "TRV",
    Return: -0.2691,
    "Market Cap": 27.8,
    Sector: "Financial",
  },
  {
    Ticker: "SNPS",
    Return: 0.2292,
    "Market Cap": 25.89,
    Sector: "Technology",
  },
  {
    Ticker: "PEG",
    Return: -0.1851,
    "Market Cap": 25.64,
    Sector: "Utilities",
  },
  {
    Ticker: "AIG",
    Return: -0.4372,
    "Market Cap": 26.99,
    Sector: "Financial",
  },
  {
    Ticker: "MPC",
    Return: -0.4096,
    "Market Cap": 22.81,
    Sector: "Energy",
  },
  {
    Ticker: "ROK",
    Return: -0.0024,
    "Market Cap": 24.96,
    Sector: "Industrials",
  },
  {
    Ticker: "MSI",
    Return: -0.1672,
    "Market Cap": 23.25,
    Sector: "Technology",
  },
  {
    Ticker: "PCAR",
    Return: -0.135,
    "Market Cap": 25.46,
    Sector: "Industrials",
  },
  {
    Ticker: "WMB",
    Return: -0.1716,
    "Market Cap": 24.03,
    Sector: "Energy",
  },
  {
    Ticker: "MCK",
    Return: 0.0802,
    "Market Cap": 25.32,
    Sector: "Healthcare",
  },
  {
    Ticker: "ED",
    Return: -0.2243,
    "Market Cap": 24.3,
    Sector: "Utilities",
  },
  {
    Ticker: "ANSS",
    Return: 0.0484,
    "Market Cap": 23.51,
    Sector: "Technology",
  },
  {
    Ticker: "RMD",
    Return: 0.0412,
    "Market Cap": 22.63,
    Sector: "Healthcare",
  },
  {
    Ticker: "ALXN",
    Return: -0.0615,
    "Market Cap": 25.34,
    Sector: "Healthcare",
  },
  {
    Ticker: "PRU",
    Return: -0.3996,
    "Market Cap": 24.86,
    Sector: "Financial",
  },
  {
    Ticker: "PPG",
    Return: -0.2795,
    "Market Cap": 24.22,
    Sector: "Basic Materials",
  },
  {
    Ticker: "XLNX",
    Return: -0.1144,
    "Market Cap": 22.44,
    Sector: "Technology",
  },
  {
    Ticker: "VRSN",
    Return: 0.1092,
    "Market Cap": 22.01,
    Sector: "Technology",
  },
  {
    Ticker: "FAST",
    Return: 0.0468,
    "Market Cap": 23,
    Sector: "Industrials",
  },
  {
    Ticker: "JCI",
    Return: -0.2746,
    "Market Cap": 24.29,
    Sector: "Industrials",
  },
  {
    Ticker: "HLT",
    Return: -0.2932,
    "Market Cap": 22.7,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "MCHP",
    Return: -0.1615,
    "Market Cap": 22.76,
    Sector: "Technology",
  },
  {
    Ticker: "FE",
    Return: -0.1638,
    "Market Cap": 23.06,
    Sector: "Utilities",
  },
  {
    Ticker: "AVB",
    Return: -0.2672,
    "Market Cap": 22.16,
    Sector: "Real Estate",
  },
  {
    Ticker: "AWK",
    Return: -0.0174,
    "Market Cap": 22.26,
    Sector: "Utilities",
  },
  {
    Ticker: "OTIS",
    Return: 0.1428,
    "Market Cap": 20.63,
    Sector: "Industrials",
  },
  {
    Ticker: "PH",
    Return: -0.1892,
    "Market Cap": 22.92,
    Sector: "Industrials",
  },
  {
    Ticker: "PAYX",
    Return: -0.2055,
    "Market Cap": 22.94,
    Sector: "Industrials",
  },
  {
    Ticker: "F",
    Return: -0.3925,
    "Market Cap": 22.81,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "CERN",
    Return: -0.0833,
    "Market Cap": 22.29,
    Sector: "Healthcare",
  },
  {
    Ticker: "STT",
    Return: -0.3006,
    "Market Cap": 22.67,
    Sector: "Financial",
  },
  {
    Ticker: "MKC",
    Return: 0.0126,
    "Market Cap": 21.03,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "BLL",
    Return: -0.0025,
    "Market Cap": 23.11,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "FLT",
    Return: -0.1685,
    "Market Cap": 21.26,
    Sector: "Technology",
  },
  {
    Ticker: "FTNT",
    Return: 0.3361,
    "Market Cap": 19.12,
    Sector: "Technology",
  },
  {
    Ticker: "EQR",
    Return: -0.2907,
    "Market Cap": 21.22,
    Sector: "Real Estate",
  },
  {
    Ticker: "EIX",
    Return: -0.2517,
    "Market Cap": 20.77,
    Sector: "Utilities",
  },
  {
    Ticker: "SWKS",
    Return: -0.0319,
    "Market Cap": 19.7,
    Sector: "Technology",
  },
  {
    Ticker: "CTAS",
    Return: -0.1057,
    "Market Cap": 20.9,
    Sector: "Industrials",
  },
  {
    Ticker: "DTE",
    Return: -0.2137,
    "Market Cap": 20.66,
    Sector: "Utilities",
  },
  {
    Ticker: "ADM",
    Return: -0.2367,
    "Market Cap": 21.75,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "PPL",
    Return: -0.2851,
    "Market Cap": 21.09,
    Sector: "Utilities",
  },
  {
    Ticker: "HSY",
    Return: -0.1138,
    "Market Cap": 20.14,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "ETR",
    Return: -0.1823,
    "Market Cap": 20.5,
    Sector: "Utilities",
  },
  {
    Ticker: "KEYS",
    Return: -0.0056,
    "Market Cap": 19.37,
    Sector: "Technology",
  },
  {
    Ticker: "AME",
    Return: -0.1388,
    "Market Cap": 20.8,
    Sector: "Industrials",
  },
  {
    Ticker: "SWK",
    Return: -0.267,
    "Market Cap": 19.1,
    Sector: "Industrials",
  },
  {
    Ticker: "TT",
    Return: -0.3729,
    "Market Cap": 21.66,
    Sector: "Industrials",
  },
  {
    Ticker: "DLTR",
    Return: -0.1313,
    "Market Cap": 23.11,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CTVA",
    Return: -0.1698,
    "Market Cap": 20.17,
    Sector: "Basic Materials",
  },
  {
    Ticker: "KHC",
    Return: -0.0678,
    "Market Cap": 18.82,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "BBY",
    Return: -0.1239,
    "Market Cap": 17.43,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "WELL",
    Return: -0.4153,
    "Market Cap": 20.78,
    Sector: "Real Estate",
  },
  {
    Ticker: "APTV",
    Return: -0.243,
    "Market Cap": 18.77,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "VFC",
    Return: -0.4417,
    "Market Cap": 19.29,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "TDG",
    Return: -0.32,
    "Market Cap": 21.41,
    Sector: "Industrials",
  },
  {
    Ticker: "MKTX",
    Return: 0.2722,
    "Market Cap": 18.21,
    Sector: "Financial",
  },
  {
    Ticker: "EFX",
    Return: 0.0493,
    "Market Cap": 18.51,
    Sector: "Industrials",
  },
  {
    Ticker: "ARE",
    Return: -0.0903,
    "Market Cap": 19.03,
    Sector: "Real Estate",
  },
  {
    Ticker: "MTD",
    Return: -0.0567,
    "Market Cap": 19.29,
    Sector: "Healthcare",
  },
  {
    Ticker: "TSN",
    Return: -0.3498,
    "Market Cap": 18.47,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CPRT",
    Return: -0.0516,
    "Market Cap": 18.27,
    Sector: "Industrials",
  },
  {
    Ticker: "O",
    Return: -0.2917,
    "Market Cap": 19.12,
    Sector: "Real Estate",
  },
  {
    Ticker: "CHD",
    Return: 0.0226,
    "Market Cap": 18.1,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "DHI",
    Return: 0.0248,
    "Market Cap": 18.78,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "FTV",
    Return: -0.2338,
    "Market Cap": 18.14,
    Sector: "Technology",
  },
  {
    Ticker: "INCY",
    Return: 0.138,
    "Market Cap": 17.76,
    Sector: "Healthcare",
  },
  {
    Ticker: "RSG",
    Return: -0.0949,
    "Market Cap": 17.86,
    Sector: "Industrials",
  },
  {
    Ticker: "SPG",
    Return: -0.6335,
    "Market Cap": 18.36,
    Sector: "Real Estate",
  },
  {
    Ticker: "AEE",
    Return: -0.0909,
    "Market Cap": 17.98,
    Sector: "Utilities",
  },
  {
    Ticker: "LVS",
    Return: -0.3207,
    "Market Cap": 16.42,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "ALGN",
    Return: -0.1265,
    "Market Cap": 17.89,
    Sector: "Healthcare",
  },
  {
    Ticker: "AJG",
    Return: -0.0434,
    "Market Cap": 17.35,
    Sector: "Financial",
  },
  {
    Ticker: "AMP",
    Return: -0.2048,
    "Market Cap": 18.24,
    Sector: "Financial",
  },
  {
    Ticker: "LH",
    Return: 0.0059,
    "Market Cap": 17.04,
    Sector: "Healthcare",
  },
  {
    Ticker: "FRC",
    Return: -0.1716,
    "Market Cap": 18.32,
    Sector: "Financial",
  },
  {
    Ticker: "GLW",
    Return: -0.2655,
    "Market Cap": 17.75,
    Sector: "Technology",
  },
  {
    Ticker: "TTWO",
    Return: 0.1477,
    "Market Cap": 14.99,
    Sector: "Communication Services",
  },
  {
    Ticker: "AKAM",
    Return: 0.1625,
    "Market Cap": 16.65,
    Sector: "Technology",
  },
  {
    Ticker: "TFX",
    Return: -0.0351,
    "Market Cap": 16.53,
    Sector: "Healthcare",
  },
  {
    Ticker: "LEN",
    Return: 0.0771,
    "Market Cap": 16.87,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "NTRS",
    Return: -0.3046,
    "Market Cap": 16.96,
    Sector: "Financial",
  },
  {
    Ticker: "CAG",
    Return: -0.0456,
    "Market Cap": 16.42,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CTXS",
    Return: 0.2357,
    "Market Cap": 16.26,
    Sector: "Technology",
  },
  {
    Ticker: "CMS",
    Return: -0.1154,
    "Market Cap": 16.43,
    Sector: "Utilities",
  },
  {
    Ticker: "CDW",
    Return: -0.2597,
    "Market Cap": 15.77,
    Sector: "Technology",
  },
  {
    Ticker: "LYB",
    Return: -0.3605,
    "Market Cap": 16.51,
    Sector: "Basic Materials",
  },
  {
    Ticker: "K",
    Return: -0.1022,
    "Market Cap": 16.28,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "MXIM",
    Return: -0.0876,
    "Market Cap": 15.28,
    Sector: "Technology",
  },
  {
    Ticker: "CAH",
    Return: 0.0595,
    "Market Cap": 15.92,
    Sector: "Healthcare",
  },
  {
    Ticker: "ESS",
    Return: -0.2142,
    "Market Cap": 16.27,
    Sector: "Real Estate",
  },
  {
    Ticker: "LUV",
    Return: -0.4652,
    "Market Cap": 17.16,
    Sector: "Industrials",
  },
  {
    Ticker: "AMCR",
    Return: -0.1144,
    "Market Cap": 16.23,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "PXD",
    Return: -0.3894,
    "Market Cap": 15.35,
    Sector: "Energy",
  },
  {
    Ticker: "CARR",
    Return: 0.5875,
    "Market Cap": 15.94,
    Sector: "Industrials",
  },
  {
    Ticker: "DGX",
    Return: 0.0566,
    "Market Cap": 15.68,
    Sector: "Healthcare",
  },
  {
    Ticker: "OKE",
    Return: -0.5216,
    "Market Cap": 15.33,
    Sector: "Energy",
  },
  {
    Ticker: "ODFL",
    Return: 0.2551,
    "Market Cap": 15.82,
    Sector: "Industrials",
  },
  {
    Ticker: "KSU",
    Return: -0.0386,
    "Market Cap": 15.1,
    Sector: "Industrials",
  },
  {
    Ticker: "DPZ",
    Return: 0.2666,
    "Market Cap": 14.18,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "COO",
    Return: -0.1054,
    "Market Cap": 15.52,
    Sector: "Healthcare",
  },
  {
    Ticker: "JKHY",
    Return: 0.2832,
    "Market Cap": 13.55,
    Sector: "Technology",
  },
  {
    Ticker: "HOLX",
    Return: 0.0023,
    "Market Cap": 13.99,
    Sector: "Healthcare",
  },
  {
    Ticker: "WY",
    Return: -0.345,
    "Market Cap": 15.88,
    Sector: "Real Estate",
  },
  {
    Ticker: "ABC",
    Return: 0.0955,
    "Market Cap": 14.21,
    Sector: "Healthcare",
  },
  {
    Ticker: "IFF",
    Return: 0.0031,
    "Market Cap": 14.34,
    Sector: "Basic Materials",
  },
  {
    Ticker: "BR",
    Return: -0.0545,
    "Market Cap": 13.63,
    Sector: "Technology",
  },
  {
    Ticker: "EVRG",
    Return: -0.0782,
    "Market Cap": 14.23,
    Sector: "Utilities",
  },
  {
    Ticker: "PAYC",
    Return: 0.0173,
    "Market Cap": 14.07,
    Sector: "Technology",
  },
  {
    Ticker: "TIF",
    Return: -0.0642,
    "Market Cap": 13.7,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "STE",
    Return: 0.0332,
    "Market Cap": 13.83,
    Sector: "Healthcare",
  },
  {
    Ticker: "VMC",
    Return: -0.3066,
    "Market Cap": 14.57,
    Sector: "Basic Materials",
  },
  {
    Ticker: "OXY",
    Return: -0.6571,
    "Market Cap": 12.17,
    Sector: "Energy",
  },
  {
    Ticker: "HRL",
    Return: 0.0403,
    "Market Cap": 13.33,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CBRE",
    Return: -0.3425,
    "Market Cap": 14.75,
    Sector: "Real Estate",
  },
  {
    Ticker: "HPE",
    Return: -0.4218,
    "Market Cap": 12.42,
    Sector: "Technology",
  },
  {
    Ticker: "DOV",
    Return: -0.2212,
    "Market Cap": 14.09,
    Sector: "Industrials",
  },
  {
    Ticker: "FCX",
    Return: -0.3338,
    "Market Cap": 13.18,
    Sector: "Basic Materials",
  },
  {
    Ticker: "LDOS",
    Return: 0.0314,
    "Market Cap": 13.9,
    Sector: "Technology",
  },
  {
    Ticker: "NDAQ",
    Return: 0.0769,
    "Market Cap": 13.37,
    Sector: "Financial",
  },
  {
    Ticker: "DAL",
    Return: -0.612,
    "Market Cap": 14.75,
    Sector: "Industrials",
  },
  {
    Ticker: "MAS",
    Return: -0.0661,
    "Market Cap": 13.32,
    Sector: "Industrials",
  },
  {
    Ticker: "ZBRA",
    Return: -0.0466,
    "Market Cap": 13.92,
    Sector: "Technology",
  },
  {
    Ticker: "IP",
    Return: -0.2912,
    "Market Cap": 13.79,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "GWW",
    Return: -0.1347,
    "Market Cap": 13.51,
    Sector: "Industrials",
  },
  {
    Ticker: "KMX",
    Return: -0.0698,
    "Market Cap": 14.59,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "DFS",
    Return: -0.5229,
    "Market Cap": 15.53,
    Sector: "Financial",
  },
  {
    Ticker: "TSCO",
    Return: 0.197,
    "Market Cap": 14.07,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "MTB",
    Return: -0.4225,
    "Market Cap": 14.42,
    Sector: "Financial",
  },
  {
    Ticker: "MAA",
    Return: -0.1333,
    "Market Cap": 13.34,
    Sector: "Real Estate",
  },
  {
    Ticker: "HIG",
    Return: -0.3913,
    "Market Cap": 14.13,
    Sector: "Financial",
  },
  {
    Ticker: "FITB",
    Return: -0.4271,
    "Market Cap": 14.32,
    Sector: "Financial",
  },
  {
    Ticker: "WDC",
    Return: -0.3317,
    "Market Cap": 12.77,
    Sector: "Technology",
  },
  {
    Ticker: "BF-B",
    Return: -0.0417,
    "Market Cap": 12.01,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "SJM",
    Return: 0.0425,
    "Market Cap": 12.62,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "EXPD",
    Return: -0.0764,
    "Market Cap": 12.87,
    Sector: "Industrials",
  },
  {
    Ticker: "NUE",
    Return: -0.2985,
    "Market Cap": 12.96,
    Sector: "Basic Materials",
  },
  {
    Ticker: "HES",
    Return: -0.295,
    "Market Cap": 12.54,
    Sector: "Energy",
  },
  {
    Ticker: "ULTA",
    Return: -0.1285,
    "Market Cap": 13.94,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "ANET",
    Return: 0.0824,
    "Market Cap": 12.13,
    Sector: "Technology",
  },
  {
    Ticker: "VTR",
    Return: -0.4162,
    "Market Cap": 13.57,
    Sector: "Real Estate",
  },
  {
    Ticker: "GRMN",
    Return: -0.1402,
    "Market Cap": 13.14,
    Sector: "Technology",
  },
  {
    Ticker: "FMC",
    Return: -0.0653,
    "Market Cap": 12.67,
    Sector: "Basic Materials",
  },
  {
    Ticker: "DRE",
    Return: -0.0401,
    "Market Cap": 12.83,
    Sector: "Real Estate",
  },
  {
    Ticker: "QRVO",
    Return: -0.1264,
    "Market Cap": 11.72,
    Sector: "Technology",
  },
  {
    Ticker: "STX",
    Return: -0.1435,
    "Market Cap": 11.89,
    Sector: "Technology",
  },
  {
    Ticker: "WAT",
    Return: -0.201,
    "Market Cap": 12.78,
    Sector: "Healthcare",
  },
  {
    Ticker: "NLOK",
    Return: 0.4967,
    "Market Cap": 11.91,
    Sector: "Technology",
  },
  {
    Ticker: "ATO",
    Return: -0.121,
    "Market Cap": 12.16,
    Sector: "Utilities",
  },
  {
    Ticker: "PEAK",
    Return: -0.2878,
    "Market Cap": 12.57,
    Sector: "Real Estate",
  },
  {
    Ticker: "BXP",
    Return: -0.4341,
    "Market Cap": 12.63,
    Sector: "Real Estate",
  },
  {
    Ticker: "CXO",
    Return: -0.3625,
    "Market Cap": 10.97,
    Sector: "Energy",
  },
  {
    Ticker: "OMC",
    Return: -0.3609,
    "Market Cap": 12,
    Sector: "Communication Services",
  },
  {
    Ticker: "IEX",
    Return: -0.1253,
    "Market Cap": 11.76,
    Sector: "Industrials",
  },
  {
    Ticker: "EXR",
    Return: -0.1836,
    "Market Cap": 12.6,
    Sector: "Real Estate",
  },
  {
    Ticker: "GPC",
    Return: -0.2731,
    "Market Cap": 12.26,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "LNT",
    Return: -0.1524,
    "Market Cap": 11.76,
    Sector: "Utilities",
  },
  {
    Ticker: "CBOE",
    Return: -0.1724,
    "Market Cap": 11.42,
    Sector: "Financial",
  },
  {
    Ticker: "MLM",
    Return: -0.3574,
    "Market Cap": 12.29,
    Sector: "Basic Materials",
  },
  {
    Ticker: "XYL",
    Return: -0.2103,
    "Market Cap": 12.18,
    Sector: "Industrials",
  },
  {
    Ticker: "EXPE",
    Return: -0.2792,
    "Market Cap": 10.89,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "VAR",
    Return: -0.1873,
    "Market Cap": 11.27,
    Sector: "Healthcare",
  },
  {
    Ticker: "NVR",
    Return: -0.1771,
    "Market Cap": 11.23,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UDR",
    Return: -0.2231,
    "Market Cap": 11.26,
    Sector: "Real Estate",
  },
  {
    Ticker: "CHRW",
    Return: -0.0044,
    "Market Cap": 10.92,
    Sector: "Industrials",
  },
  {
    Ticker: "KEY",
    Return: -0.4694,
    "Market Cap": 11.92,
    Sector: "Financial",
  },
  {
    Ticker: "VIAC",
    Return: -0.5332,
    "Market Cap": 11.39,
    Sector: "Communication Services",
  },
  {
    Ticker: "WAB",
    Return: -0.2699,
    "Market Cap": 11.2,
    Sector: "Industrials",
  },
  {
    Ticker: "NTAP",
    Return: -0.2822,
    "Market Cap": 10.1,
    Sector: "Technology",
  },
  {
    Ticker: "HAL",
    Return: -0.5264,
    "Market Cap": 10.65,
    Sector: "Energy",
  },
  {
    Ticker: "IT",
    Return: -0.2368,
    "Market Cap": 10.81,
    Sector: "Technology",
  },
  {
    Ticker: "CE",
    Return: -0.3057,
    "Market Cap": 11.17,
    Sector: "Basic Materials",
  },
  {
    Ticker: "J",
    Return: -0.1417,
    "Market Cap": 11.43,
    Sector: "Industrials",
  },
  {
    Ticker: "PKI",
    Return: -0.038,
    "Market Cap": 10.96,
    Sector: "Healthcare",
  },
  {
    Ticker: "SYF",
    Return: -0.5043,
    "Market Cap": 12,
    Sector: "Financial",
  },
  {
    Ticker: "BKR",
    Return: -0.4101,
    "Market Cap": 10.65,
    Sector: "Energy",
  },
  {
    Ticker: "SIVB",
    Return: -0.2452,
    "Market Cap": 11.15,
    Sector: "Financial",
  },
  {
    Ticker: "IR",
    Return: -0.2219,
    "Market Cap": 9.92,
    Sector: "Industrials",
  },
  {
    Ticker: "FOXA",
    Return: -0.2501,
    "Market Cap": 10.15,
    Sector: "Communication Services",
  },
  {
    Ticker: "RF",
    Return: -0.4202,
    "Market Cap": 11.12,
    Sector: "Financial",
  },
  {
    Ticker: "URI",
    Return: -0.2171,
    "Market Cap": 10.36,
    Sector: "Industrials",
  },
  {
    Ticker: "ETFC",
    Return: -0.0928,
    "Market Cap": 10.34,
    Sector: "Financial",
  },
  {
    Ticker: "DRI",
    Return: -0.3062,
    "Market Cap": 9.67,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "CTL",
    Return: -0.2566,
    "Market Cap": 9.75,
    Sector: "Communication Services",
  },
  {
    Ticker: "CFG",
    Return: -0.476,
    "Market Cap": 10.74,
    Sector: "Financial",
  },
  {
    Ticker: "AAP",
    Return: -0.1666,
    "Market Cap": 9.6,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "XRAY",
    Return: -0.2536,
    "Market Cap": 10.44,
    Sector: "Healthcare",
  },
  {
    Ticker: "PFG",
    Return: -0.3285,
    "Market Cap": 10.54,
    Sector: "Financial",
  },
  {
    Ticker: "ALLE",
    Return: -0.2429,
    "Market Cap": 9.34,
    Sector: "Industrials",
  },
  {
    Ticker: "NRG",
    Return: -0.1014,
    "Market Cap": 9.22,
    Sector: "Utilities",
  },
  {
    Ticker: "JBHT",
    Return: -0.0712,
    "Market Cap": 10.16,
    Sector: "Industrials",
  },
  {
    Ticker: "EMN",
    Return: -0.1741,
    "Market Cap": 9.36,
    Sector: "Basic Materials",
  },
  {
    Ticker: "AVY",
    Return: -0.1863,
    "Market Cap": 9.36,
    Sector: "Industrials",
  },
  {
    Ticker: "ABMD",
    Return: 0.2085,
    "Market Cap": 9.76,
    Sector: "Healthcare",
  },
  {
    Ticker: "PKG",
    Return: -0.1697,
    "Market Cap": 9.58,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HAS",
    Return: -0.3685,
    "Market Cap": 9.37,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "MGM",
    Return: -0.5221,
    "Market Cap": 8.86,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HSIC",
    Return: -0.15,
    "Market Cap": 9,
    Sector: "Healthcare",
  },
  {
    Ticker: "AES",
    Return: -0.3849,
    "Market Cap": 8.5,
    Sector: "Utilities",
  },
  {
    Ticker: "NI",
    Return: -0.1706,
    "Market Cap": 8.89,
    Sector: "Utilities",
  },
  {
    Ticker: "HBAN",
    Return: -0.4632,
    "Market Cap": 9.45,
    Sector: "Financial",
  },
  {
    Ticker: "FBHS",
    Return: -0.0735,
    "Market Cap": 8.54,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "CNP",
    Return: -0.3795,
    "Market Cap": 8.98,
    Sector: "Utilities",
  },
  {
    Ticker: "UHS",
    Return: -0.2739,
    "Market Cap": 8.6,
    Sector: "Healthcare",
  },
  {
    Ticker: "FFIV",
    Return: -0.0168,
    "Market Cap": 8.49,
    Sector: "Technology",
  },
  {
    Ticker: "MYL",
    Return: -0.2219,
    "Market Cap": 8.86,
    Sector: "Healthcare",
  },
  {
    Ticker: "WYNN",
    Return: -0.4365,
    "Market Cap": 8.01,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HST",
    Return: -0.3946,
    "Market Cap": 8.64,
    Sector: "Real Estate",
  },
  {
    Ticker: "L",
    Return: -0.3921,
    "Market Cap": 8.65,
    Sector: "Financial",
  },
  {
    Ticker: "LW",
    Return: -0.3349,
    "Market Cap": 8.7,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "PNW",
    Return: -0.192,
    "Market Cap": 8.62,
    Sector: "Utilities",
  },
  {
    Ticker: "RJF",
    Return: -0.2711,
    "Market Cap": 8.74,
    Sector: "Financial",
  },
  {
    Ticker: "CPB",
    Return: -0.0217,
    "Market Cap": 8.35,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "CINF",
    Return: -0.4549,
    "Market Cap": 9.18,
    Sector: "Financial",
  },
  {
    Ticker: "WU",
    Return: -0.2901,
    "Market Cap": 8.53,
    Sector: "Financial",
  },
  {
    Ticker: "PHM",
    Return: -0.1415,
    "Market Cap": 8.75,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "RE",
    Return: -0.257,
    "Market Cap": 8.21,
    Sector: "Financial",
  },
  {
    Ticker: "JNPR",
    Return: -0.0597,
    "Market Cap": 7.95,
    Sector: "Technology",
  },
  {
    Ticker: "LKQ",
    Return: -0.2903,
    "Market Cap": 8.54,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "WRB",
    Return: -0.1831,
    "Market Cap": 8.42,
    Sector: "Financial",
  },
  {
    Ticker: "COG",
    Return: 0.0856,
    "Market Cap": 7.89,
    Sector: "Energy",
  },
  {
    Ticker: "PRGO",
    Return: 0.0507,
    "Market Cap": 7.49,
    Sector: "Healthcare",
  },
  {
    Ticker: "WHR",
    Return: -0.1886,
    "Market Cap": 7.62,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "SNA",
    Return: -0.2392,
    "Market Cap": 7.33,
    Sector: "Industrials",
  },
  {
    Ticker: "GL",
    Return: -0.3108,
    "Market Cap": 7.74,
    Sector: "Financial",
  },
  {
    Ticker: "ALB",
    Return: -0.0675,
    "Market Cap": 8.1,
    Sector: "Basic Materials",
  },
  {
    Ticker: "HII",
    Return: -0.2715,
    "Market Cap": 8.2,
    Sector: "Industrials",
  },
  {
    Ticker: "DVA",
    Return: 0.0237,
    "Market Cap": 7.12,
    Sector: "Healthcare",
  },
  {
    Ticker: "DISH",
    Return: -0.1666,
    "Market Cap": 7.97,
    Sector: "Communication Services",
  },
  {
    Ticker: "LNC",
    Return: -0.4069,
    "Market Cap": 7.78,
    Sector: "Financial",
  },
  {
    Ticker: "FANG",
    Return: -0.5376,
    "Market Cap": 6.92,
    Sector: "Energy",
  },
  {
    Ticker: "TAP",
    Return: -0.3167,
    "Market Cap": 7.17,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "RCL",
    Return: -0.6769,
    "Market Cap": 8.83,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "REG",
    Return: -0.346,
    "Market Cap": 7.45,
    Sector: "Real Estate",
  },
  {
    Ticker: "IRM",
    Return: -0.2416,
    "Market Cap": 7.34,
    Sector: "Real Estate",
  },
  {
    Ticker: "LYV",
    Return: -0.3495,
    "Market Cap": 6.92,
    Sector: "Communication Services",
  },
  {
    Ticker: "WRK",
    Return: -0.4092,
    "Market Cap": 7.39,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "IPG",
    Return: -0.2866,
    "Market Cap": 6.72,
    Sector: "Communication Services",
  },
  {
    Ticker: "DISCK",
    Return: -0.3977,
    "Market Cap": 6.69,
    Sector: "Communication Services",
  },
  {
    Ticker: "TXT",
    Return: -0.3966,
    "Market Cap": 7.14,
    Sector: "Industrials",
  },
  {
    Ticker: "AIZ",
    Return: -0.2267,
    "Market Cap": 6.27,
    Sector: "Financial",
  },
  {
    Ticker: "FLIR",
    Return: -0.1346,
    "Market Cap": 6.01,
    Sector: "Technology",
  },
  {
    Ticker: "BWA",
    Return: -0.3121,
    "Market Cap": 6.59,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "PNR",
    Return: -0.2047,
    "Market Cap": 6.61,
    Sector: "Industrials",
  },
  {
    Ticker: "CF",
    Return: -0.4221,
    "Market Cap": 6.38,
    Sector: "Basic Materials",
  },
  {
    Ticker: "AOS",
    Return: -0.1138,
    "Market Cap": 6.45,
    Sector: "Industrials",
  },
  {
    Ticker: "VNO",
    Return: -0.4865,
    "Market Cap": 6,
    Sector: "Real Estate",
  },
  {
    Ticker: "RHI",
    Return: -0.2492,
    "Market Cap": 6.05,
    Sector: "Industrials",
  },
  {
    Ticker: "IPGP",
    Return: 0.086,
    "Market Cap": 5.39,
    Sector: "Technology",
  },
  {
    Ticker: "CCL",
    Return: -0.7155,
    "Market Cap": 6.29,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "FRT",
    Return: -0.3903,
    "Market Cap": 5.61,
    Sector: "Real Estate",
  },
  {
    Ticker: "ROL",
    Return: 0.168,
    "Market Cap": 5.64,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UAL",
    Return: -0.7117,
    "Market Cap": 6.27,
    Sector: "Industrials",
  },
  {
    Ticker: "AIV",
    Return: -0.3049,
    "Market Cap": 5.53,
    Sector: "Real Estate",
  },
  {
    Ticker: "ZION",
    Return: -0.4185,
    "Market Cap": 5.81,
    Sector: "Financial",
  },
  {
    Ticker: "BEN",
    Return: -0.3114,
    "Market Cap": 5.34,
    Sector: "Financial",
  },
  {
    Ticker: "PBCT",
    Return: -0.3574,
    "Market Cap": 5.18,
    Sector: "Financial",
  },
  {
    Ticker: "MHK",
    Return: -0.3891,
    "Market Cap": 5.58,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "NOV",
    Return: -0.5174,
    "Market Cap": 4.94,
    Sector: "Energy",
  },
  {
    Ticker: "HWM",
    Return: -0.0629,
    "Market Cap": 5.14,
    Sector: "Industrials",
  },
  {
    Ticker: "NWL",
    Return: -0.3351,
    "Market Cap": 5.16,
    Sector: "Consumer Defensive",
  },
  {
    Ticker: "PWR",
    Return: -0.1587,
    "Market Cap": 5.2,
    Sector: "Industrials",
  },
  {
    Ticker: "DVN",
    Return: -0.5325,
    "Market Cap": 4.52,
    Sector: "Energy",
  },
  {
    Ticker: "HFC",
    Return: -0.3993,
    "Market Cap": 4.67,
    Sector: "Energy",
  },
  {
    Ticker: "CMA",
    Return: -0.5449,
    "Market Cap": 5.42,
    Sector: "Financial",
  },
  {
    Ticker: "MRO",
    Return: -0.5663,
    "Market Cap": 4.58,
    Sector: "Energy",
  },
  {
    Ticker: "NLSN",
    Return: -0.3305,
    "Market Cap": 5.12,
    Sector: "Industrials",
  },
  {
    Ticker: "SEE",
    Return: -0.2518,
    "Market Cap": 5.02,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "NBL",
    Return: -0.5934,
    "Market Cap": 4.58,
    Sector: "Energy",
  },
  {
    Ticker: "KIM",
    Return: -0.4529,
    "Market Cap": 4.86,
    Sector: "Real Estate",
  },
  {
    Ticker: "APA",
    Return: -0.5244,
    "Market Cap": 4.44,
    Sector: "Energy",
  },
  {
    Ticker: "DXC",
    Return: -0.5882,
    "Market Cap": 4.24,
    Sector: "Technology",
  },
  {
    Ticker: "FOX",
    Return: -0.2481,
    "Market Cap": 4.58,
    Sector: "Communication Services",
  },
  {
    Ticker: "NWSA",
    Return: -0.1853,
    "Market Cap": 4.44,
    Sector: "Communication Services",
  },
  {
    Ticker: "MOS",
    Return: -0.4658,
    "Market Cap": 4.25,
    Sector: "Basic Materials",
  },
  {
    Ticker: "AAL",
    Return: -0.6618,
    "Market Cap": 4.34,
    Sector: "Industrials",
  },
  {
    Ticker: "LEG",
    Return: -0.41,
    "Market Cap": 4.15,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "ALK",
    Return: -0.5421,
    "Market Cap": 4.24,
    Sector: "Industrials",
  },
  {
    Ticker: "TPR",
    Return: -0.4816,
    "Market Cap": 3.87,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HOG",
    Return: -0.3444,
    "Market Cap": 3.61,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "RL",
    Return: -0.3786,
    "Market Cap": 3.98,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HBI",
    Return: -0.3542,
    "Market Cap": 3.7,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "HRB",
    Return: -0.2785,
    "Market Cap": 3.37,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "DISCA",
    Return: -0.3801,
    "Market Cap": 3.45,
    Sector: "Communication Services",
  },
  {
    Ticker: "XRX",
    Return: -0.5395,
    "Market Cap": 3.12,
    Sector: "Technology",
  },
  {
    Ticker: "FLS",
    Return: -0.4937,
    "Market Cap": 3.43,
    Sector: "Industrials",
  },
  {
    Ticker: "FTI",
    Return: -0.6637,
    "Market Cap": 3.23,
    Sector: "Energy",
  },
  {
    Ticker: "SLG",
    Return: -0.5992,
    "Market Cap": 3.62,
    Sector: "Real Estate",
  },
  {
    Ticker: "PVH",
    Return: -0.5801,
    "Market Cap": 3.67,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UNM",
    Return: -0.4955,
    "Market Cap": 3.32,
    Sector: "Financial",
  },
  {
    Ticker: "NCLH",
    Return: -0.762,
    "Market Cap": 3.47,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "IVZ",
    Return: -0.5957,
    "Market Cap": 3.03,
    Sector: "Financial",
  },
  {
    Ticker: "LB",
    Return: -0.1678,
    "Market Cap": 3.65,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "KSS",
    Return: -0.6569,
    "Market Cap": 3.2,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "ADS",
    Return: -0.6252,
    "Market Cap": 2.01,
    Sector: "Financial",
  },
  {
    Ticker: "JWN",
    Return: -0.6174,
    "Market Cap": 1.9,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "GPS",
    Return: -0.5356,
    "Market Cap": 2.06,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UAA",
    Return: -0.6139,
    "Market Cap": 1.64,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "UA",
    Return: -0.6152,
    "Market Cap": 1.57,
    Sector: "Consumer Cyclical",
  },
  {
    Ticker: "NWS",
    Return: -0.1985,
    "Market Cap": 1.48,
    Sector: "Communication Services",
  },
  {
    Ticker: "COTY",
    Return: -0.6667,
    "Market Cap": 1.26,
    Sector: "Consumer Defensive",
  },
];
