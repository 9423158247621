/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { Funnel, FunnelChart, Tooltip, LabelList } from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
} from "../../components";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

function pickHex(weight, c1, c2) {
  var w1 = weight;
  var w2 = 1 - w1;
  var color2 = hexToRgb(c2);
  var color1 = hexToRgb(c1);
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export default ({ id, dataset, settings }) => {
  const { primaryDim, valueDim, legend } = settings;

  let margin = { top: 15, bottom: 0, left: 15, right: 15 };

  return (
    <Wrapper>
      {({ width, height }) => (
        <FunnelChart width={width} height={height} margin={margin}>
          <Tooltip />
          <Funnel
            data={dataset.data.map((d, i) => {
              const color = pickHex(
                (dataset.data.length - i) / dataset.data.length,
                Color[2],
                Color[0]
              );
              return {
                ...d,
                fill: `rgba(${color[0]},${color[1]},${color[2]},0.9)`,
                stroke: "none",
              };
            })}
            dataKey={dataset.fields[valueDim]}
            isAnimationActive
          >
            <LabelList
              position="right"
              fill="#FFF"
              stroke="none"
              dataKey={dataset.fields[primaryDim]}
            />
          </Funnel>
        </FunnelChart>
      )}
    </Wrapper>
  );
};
