import { MultilineChart } from "@styled-icons/material/MultilineChart";
import { ChartArea } from "@styled-icons/typicons/ChartArea";
import { Customize } from "@styled-icons/boxicons-regular/Customize";
import { TableChart } from "@styled-icons/material/TableChart";

import { PieChart } from "@styled-icons/feather/PieChart";
import { DoughnutChart } from "@styled-icons/boxicons-regular/DoughnutChart";
import { Radar } from "@styled-icons/boxicons-regular/Radar";
import { ScatterPlot } from "@styled-icons/material/ScatterPlot";

import { ReactComponent as Column } from "../../icons/column.svg";
import { ReactComponent as TwoByTwo } from "../../icons/2by2.svg";

export default [
  {
    name: "Cartesian",
    items: [
      {
        text: "Bar Chart",
        icon: Column,
        key: "Bar",
        compatible: ["Line", "Area"],
      },
      {
        text: "Line Chart",
        icon: MultilineChart,
        key: "Line",
        compatible: ["Bar", "Area"],
      },
      {
        text: "Area Chart",
        icon: ChartArea,
        key: "Area",
        compatible: ["Line", "Bar"],
      },
      {
        text: "Scatter",
        icon: ScatterPlot,
        key: "Scatter",
      },
      {
        text: "2x2",
        icon: TwoByTwo,
        key: "TwoByTwo",
      },
    ],
  },
  {
    name: "Polar / Radial",
    items: [
      {
        text: "Pie Chart",
        icon: PieChart,
        key: "Pie",
      },
      {
        text: "Donut Chart",
        icon: DoughnutChart,
        key: "Donut",
      },
      {
        text: "Radar",
        icon: Radar,
        key: "Radar",
      },
    ],
  },
  {
    name: "Other",
    items: [
      {
        text: "Heat Table",
        icon: TableChart,
        key: "Heat",
      },
      {
        text: "Geo Map",
        icon: Column,
        key: "Geo",
      },
      {
        text: "Funnel",
        icon: Column,
        key: "Funnel",
      },
      {
        text: "Build your own",
        icon: Customize,
        key: "Custom",
      },
    ],
  },
];
