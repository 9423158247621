/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { Button } from "antd";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { msalConfig, loginRequest, graphConfig } from "./config";
import "./index.css";
import { Result, Select, Popover, Space, Avatar, Skeleton } from "antd";
import * as AuthService from "@pai/services/auth";
import { InteractionStatus } from "@azure/msal-browser";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, CardMeta } from "@pai-ui/core";
import { Typography } from "@pai/library/base";
export const UserContext = React.createContext();

// import { Login } from "./login-page";
// import { StyledLink } from "../withPageTitle";
// import { axiosCoreInstance } from "../../api-core";

const initialState = {
  user: undefined,
  token: null,
  loading: true,
};

function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}
function handleDefaultLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

const userReducer = (state, action) => {
  switch (action.type) {
    case "metaData":{
      return {
        ...state,
        format: action.payload.format,
        status: action.payload.status,
        country: action.payload.country,
        currency: action.payload.currency,
        loading: false,
        error: undefined,
        token: action.payload.token,
      };}
      
    case "identity":
      console.log("user called",action)
      return {
        ...state,
        identity: {name:action.payload.user},
      };
  }
};

const ComponentForAuthentication = ({ children }) => {
  //   const [graphData, setGraphData] = useState(null);

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  const InitialApiCalls = React.useCallback(
    function InitialApiCalls() {
      const request = {
        ...loginRequest,
        //scopes:[""]
        account: accounts[0],
      };
      // console.log("accessToken");

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          console.log("Auth response", response, response?.accessToken);
          //FIXME
          let metaData = { token: response?.accessToken };
          window.localStorage.setItem("currUrl", instance?.config.auth.state);
          let apiData = await AuthService.getMetaData(response?.accessToken);
          metaData = { ...metaData, ...apiData };

          console.log("metadata", metaData, apiData,state);
          dispatch({
            type: "metaData",
            payload: metaData,
          });
          // setIsLoginSuccessful(isAuthenticated);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setIsLoginSuccessful(false);
          });
        });
    },
    [isAuthenticated]
  );

  useEffect(() => {
    if (isAuthenticated ) {
      dispatch({
        type: "identity",
        payload: {user:instance.getActiveAccount()?.name},
      })
      InitialApiCalls()}
  }, [isAuthenticated]);
  
  const activeAccount = instance.getActiveAccount();
  console.log("active account", activeAccount);
  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    setTimeout(() => {
      if (accounts.length === 0) {
         handleDefaultLogin(instance);
      }
    }, 100);
  }

  const Component = () => {
    console.log("loading component", InteractionStatus, isAuthenticated);
    var Item = null;
    if (isAuthenticated) Item = <div>{children}</div>;
    else if (!isAuthenticated) {
      Item = (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Card
              size="large"
              style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
              bordered={false}
              bodyStyle={{ backgroundColor: "transparent" }}
              // loading={true}
              actions={[
                <div key={1} align="center" style={{ color: "#15afd0" }}>
                  {inProgress === InteractionStatus.None
                    ? "Redirecting for login"
                    : "Fetching Details"}
                </div>,
              ]}
            >
              <div>
                <Skeleton.Avatar active size="large" />
              </div>
              <div style={{ marginTop: "20px" }}>
                {" "}
                <Skeleton
                className="pai-loader"
                  active
                  shape="round"
                  paragraph={{ rows: 2, width: 250 }}
                />
              </div>
            </Card>
          </div>
        </div>
      );
    } else {
      Item = (
        <div className="login-component-wrapper">
          <Result
            status="403"
            title="Personalize.AI-Triumph"
            subTitle="Authentication Failed. Please Retry"
            extra={
              <Button type="primary" onClick={() => handleLogin(instance)}>
                Login
              </Button>
            }
          />
        </div>
      );
    }

    return Item;
  };

  console.log("auth useEffect", { isAuthenticated, activeAccount, instance });
  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      <Component />
    </UserContext.Provider>
  );
};

export const withAuth = (Component) =>
  function Auth(props) {
    let newConfig = JSON.parse(JSON.stringify(msalConfig));
    newConfig.auth.state = window.location.pathname;
    const msalInstance = new PublicClientApplication(newConfig);
    console.log(
      "msalInstance",
      msalInstance.getAllAccounts(),
      msalInstance.getActiveAccount()
    );

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        msalInstance.setActiveAccount(event.payload.account);
      }
    });
    console.log("msalInstance 2", { msalInstance });
    return (
      <MsalProvider instance={msalInstance}>
        <ComponentForAuthentication instance={msalInstance}>
          <Component />
        </ComponentForAuthentication>
      </MsalProvider>
    );
  };
