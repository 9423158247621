/* eslint-disable */
import React, { useEffect } from "react";
import numeral from "numeral";
import * as Venn from "venn.js";
import * as d3 from "d3";
import { Wrapper } from "../../components";
import { Color } from "../../../color-select/styles";
import "./style.css";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

const VennChart = ({ width, height, data }) => {
  useEffect(() => {
    var chart = Venn.VennDiagram().width(width).height(height);

    let div_venn = d3.select("#venn");
    div_venn.datum(data).call(chart);
    const defs = d3.select("#venn svg").append("defs");
    const gradient1 = defs
      .append("linearGradient")
      .attr("id", "color_1")
      .attr("x1", "100%")
      .attr("y1", "0")
      .attr("x2", "0")
      .attr("y2", "0")
      .attr("spreadMethod", "pad");
    gradient1
      .append("stop")
      .attr("offset", "0")
      .attr("stop-color", `${Color[3]}BF`);
    gradient1
      .append("stop")
      .attr("offset", "1")
      .attr("stop-color", `${Color[3]}66`);
    const gradient2 = defs
      .append("linearGradient")
      .attr("id", "color_2")
      .attr("x1", "100%")
      .attr("y1", "0")
      .attr("x2", "0")
      .attr("y2", "0")
      .attr("spreadMethod", "pad");
    gradient2
      .append("stop")
      .attr("offset", "0")
      .attr("stop-color", `${Color[2]}BF`);
    gradient2
      .append("stop")
      .attr("offset", "1")
      .attr("stop-color", `${Color[2]}66`);

    d3.selectAll("#venn .venn-circle path")
      .style("fill", function (d, i) {
        return `url(#color_${i + 1})`;
      })
      .style("opacity", 1)
      .style("fill-opacity", 1);
    d3.selectAll("#venn text").text(function (d, i) {
      return numeral(d.size).format("0,0.0a");
    });
    d3.selectAll("#venn text").style("fill", "white").style("opacity", 0.55); //.style("fill", "rgba(255, 255, 255, 0.55)").style("font-size", "10px");

    // add a tooltip
    let tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "venn-tool-tip");

    // add listeners to all the groups to display tooltip on mouseover
    d3.select("#venn");

    div_venn
      .selectAll("g")
      .on("mouseover", function (event, d, i) {
        // sort all the areas relative to the current item
        Venn.sortAreas(div_venn, d);

        // Display a tooltip with the current size
        tooltip.transition().duration(400).style("opacity", 0.9);
        tooltip.text(
          (d.sets.length > 1
            ? d.sets.join(" AND ") + " : "
            : "Only " + d.sets[0] + ": ") + d.size
        );

        // highlight the current path
        var selection = d3.select(this).transition("tooltip").duration(400);
        selection
          .select("path")
          .style("fill-opacity", d.sets.length === 1 ? 0.8 : 0.5)
          .style("stroke-opacity", 1);
      })

      .on("mousemove", function (event) {
        tooltip
          .style("left", event.pageX + "px")
          .style("top", event.pageY - 28 + "px");
      })

      .on("mouseout", function (event, d, i) {
        tooltip.transition().duration(400).style("opacity", 0);
        var selection = d3.select(this).transition("tooltip").duration(400);
        selection
          .select("path")
          .style("fill-opacity", d.sets.length === 1 ? 1 : 0.0)
          .style("stroke-opacity", 0);
      });
  }, [data]);

  return (
    <>
      <div
        className="recharts-legend-wrapper"
        style={{ marginTop: -20, marginBottom: 10 }}
      >
        <ul
          className="recharts-default-legend"
          style={{ padding: 0, margin: 0, marginLeft: 18, textAlign: "left" }}
        >
          {data.slice(0, 2).map((d, i) => (
            <li
              className="recharts-legend-item legend-item-0"
              style={{ display: "inline-block", marginRight: 10 }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: 4,
                }}
              >
                <path
                  stroke="none"
                  fill={Color[i + 1]}
                  //fillOpacity="0.25"
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                ></path>
              </svg>
              <span className="recharts-legend-item-text">
                {d.sets.join(" ")}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div id="venn" className="recharts-wrapper"></div>
    </>
  );
};

export default ({ dataset, settings }) => {
  const { primaryDim, valueDim, legend } = settings;

  let transformedData = dataset.data.map((d) => ({
    sets: [d[dataset.fields[primaryDim]]],
    size: d[dataset.fields[valueDim]],
  }));

  transformedData[2] = {
    sets: transformedData[0].sets.concat(transformedData[1].sets),
    size: transformedData[2].size,
  };

  return (
    <Wrapper>
      {({ width, height }) => (
        <VennChart width={width} height={height - 30} data={transformedData} />
      )}
    </Wrapper>
  );
};
