/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import numeral from "numeral";
import Highlighter from "react-highlight-words";
import { Color } from "../../../color-select";
import { Wrapper } from "../../components";
// import { ConvertToVizData } from "../../../../../library/utils/yoy-transformer";
import ZsTable from "@pai/assets/generic-ant-d-components/zs-table";
import {
  ZsInput,
  ZsButton,
  ZsSearchOutlined,
} from "@pai/assets/generic-ant-d-components/quick-access-ant-d";
import { removeDelimiterAndToCamelCase } from '@pai/pages/transformer';
import "./style.css";
// import styled from "styled-components";
// import { css } from 'react-emotion';

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

function pickHex(weight, c1, c2) {
  var w1 = weight;
  var w2 = 1 - w1;
  var color2 = hexToRgb(c2);
  var color1 = hexToRgb(c1);
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

// const removeDelimiterAndToCamelCase = (text, delimiter = "_") => {
//   return text
//     ? text
//         .split(delimiter)
//         .map(
//           (eachText) =>
//             eachText.charAt(0).toUpperCase() +
//             eachText.slice(1, eachText.length).toLowerCase()
//         )
//         .join(" ")
//     : "";
// };

export default ({ id, dataset, settings }) => {
  // zs-table-customColumnSearch - start
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm && confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const GetColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div /* style={{ padding: 8 }} */>
        <ZsInput
          // ref={searchInput}
          autoFocus={true}
          placeholder={"Enter text here"}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(e.target.value ? e.target.value : "");
            setSearchedColumn(dataIndex);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onBlur={() => handleSearch(selectedKeys, confirm, dataIndex)}
          allowClear={true}
          // style={{ marginBottom: 8, display: 'block' }}
        />
        {/* <ZsSpace>
          <ZsButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 7 }}
          >
            Search
          </ZsButton>
          <ZsButton onClick={() => handleReset(clearFilters)} size="small"
            style={{ width: 90, }}>
            Reset
          </ZsButton>
        </ZsSpace> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <ZsSearchOutlined /* style={{ color: filtered ? '#1890ff' : undefined }} */
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => searchInput?.current?.select(), 100);
    //   }
    // },
    render: (item_Title, record, itemIndx) => {
      // default
      // Normal search filter
      return (
        <>
          {labelSettings &&
          labelSettings.yAxis &&
          labelSettings.yAxis.labelFunct
            ? labelSettings.yAxis.labelFunct(
                searchedColumn === dataIndex ? (
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={item_Title ? item_Title.toString() : ""}
                  />
                ) : (
                  item_Title
                ),
                record[yFieldToolTip]
              )
            : item_Title}
        </>
      );
    },
  });

  const {
    primaryDim,
    axes,
    format,
    heatRange,
    highlight,
    zoom,
    labelSettings,
    overallItemName,
    wrapperHeight,
    customZsTableScroll,
    ModalView,
  } = settings;

  const xField = dataset?.fields?.[axes.column.dim];
  const xFieldToolTip = dataset?.fields?.[axes.column.tooltipDim];

  const yField = dataset?.fields?.[axes.row.dim];
  const yFieldToolTip = dataset?.fields?.[axes.row.tooltipDim];

  const plotField = dataset?.fields?.[primaryDim];

  // const uniqueXValues = [...new Set(dataset?.data.map((r) => r[xField]))];
  // const uniqueXToolTipValues = [...new Set(dataset?.data.map((r) => r[xFieldToolTip]))];

  const uniqueXV = [
    ...new Map(
      dataset?.data
        ?.map((r) => {
          return { [xField]: r[xField], [xFieldToolTip]: r[xFieldToolTip] };
        })
        ?.map((item) => [item[xField], item])
    ).values(),
  ].sort();
  console.log("customZSTableHeat uniqueXV ", uniqueXV);

  // const uniqueYValues = [...new Set(dataset.data.map((r) => r[yField]))];
  // const uniqueYToolTipValues = [...new Set(dataset.data.map((r) => r[yFieldToolTip]))];

  const uniqueYV = [
    ...new Map(
      dataset?.data
        ?.map((r) => {
          return { [yField]: r[yField], [yFieldToolTip]: r[yFieldToolTip] };
        })
        ?.map((item) => [item[yField], item])
    ).values(),
  ];
  console.log("customZSTableHeat uniqueYV ", uniqueYV);

  const plotRange = [...new Set(dataset?.data?.map((r) => r[plotField]))];

  const minPlot = Math.min(...plotRange);
  const maxPlot = Math.max(...plotRange);

  // require css class using css-in-js --> for this require to use react-emotion
  // Ref : https://stackoverflow.com/a/51882282/4672396
  // const styledZsTable = styled(ZsTable)`
  // '& table': {
  //   borderCollapse: 'collapse'
  // },
  // '& thead > tr > th': {
  //   backgroundColor: 'darkblue',
  //   color: 'white',
  // },
  // '& thead > tr': {
  //   borderWidth: '2px',
  //   borderColor: 'yellow',
  //   borderStyle: 'solid'
  // }
  // `;

  let TableDataSet = [];
  let overallDataItem = null;

  uniqueYV.forEach((YVItem) => {
    let filteredData = dataset?.data?.filter(
      (item) => item[yField] == YVItem[yField]
    );
    let rowData = {
      [yField]: YVItem[yField],
      [yFieldToolTip]: YVItem[yFieldToolTip],
    };

    filteredData.forEach((dataItem) => {
      // rowData[dataItem[xField]] ={};
      rowData[dataItem[xField]] = dataItem[plotField];
    });
    if (rowData[yField] == overallItemName) {
      overallDataItem = rowData;
    } else {
      TableDataSet.push(rowData);
    }
  });

  // Sort data based on last column in descending order - expects numeral value ----- for string sort function needs to be changed accordingly
  let lastColForSort = uniqueXV?.[uniqueXV?.length - 1]?.[xField];
  TableDataSet = TableDataSet?.sort(
    (a, b) => b?.[lastColForSort] - a?.[lastColForSort]
  );

  // Ref : https://stackoverflow.com/a/61976933/4672396
  // for setting up fixed row item below table header

  console.log("customZSTableHeat TableDataSet ", TableDataSet);
  const TableColumns = [
    {
      title: removeDelimiterAndToCamelCase(yField),
      dataIndex: yField,
      key: yField,
      // align: "left",
      width: "13%",
      // render: (item_Title, record, itemIndx) => {
      //   return (
      //     <>
      //       {
      //         labelSettings &&
      //           labelSettings.yAxis &&
      //           labelSettings.yAxis.labelFunct
      //           ? labelSettings.yAxis.labelFunct(item_Title, record[yFieldToolTip])
      //           : item_Title
      //       }
      //     </>
      //   )
      // },
      // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      //   return (
      //     <>
      //       <ZsInput
      //         autoFocus={true}
      //         placeholder={"Enter text here"}
      //         value={selectedKeys[0]}
      //         onChange={(e) => {
      //           setSelectedKeys(e.target.value ? [e.target.value] : []);
      //           confirm({ closeDropdown: false });
      //         }}
      //         onPressEnter={() => { confirm() }}
      //         onBlur={() => { confirm() }}
      //         allowClear={true}
      //       />
      //       {/* <ZsButton type={'primary'} onClick={() => { confirm() }}>Search</ZsButton>
      //       <ZsButton type={'danger'} onClick={() => { clearFilters() }}>Reset</ZsButton> */}
      //     </>

      //   )
      // },
      // filterIcon: (filtered) => {
      //   return <ZsSearchOutlined />
      // },
      // filterSearch: true,
      // onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      children: [
        {
          title: overallDataItem ? overallDataItem[yField] : "",
          width: "13%",
          dataIndex: yField,
          key: yField,
          render: (item_Title, record, itemIndx) => {
            // default
            // Normal search filter
            return (
              <>
                {labelSettings &&
                labelSettings.yAxis &&
                labelSettings.yAxis.labelFunct
                  ? labelSettings.yAxis.labelFunct(
                      // searchedColumn === dataIndex
                      //   ?
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: "#ffc069",
                          padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={
                          item_Title ? item_Title.toString() : ""
                        }
                      />,
                      // :
                      // item_Title
                      record[yFieldToolTip]
                    )
                  : item_Title}
              </>
            );
          },
        },
      ],
      ...GetColumnSearchProps(yField),
    },
  ];
  uniqueXV.forEach((XVItem, indx) => {
    let col = {
      title: removeDelimiterAndToCamelCase(XVItem[xField]),
      dataIndex: XVItem[xField],
      key: XVItem[xField],
      // // width: '6%',
      // render: (value, record, itemIndx) => {
      //   return (
      //     <>
      //       {
      //         !value ? " - " : numeral(value).format(format)?.toUpperCase()
      //       }
      //     </>
      //   )
      // },
      children: [
        {
          title: overallDataItem
            ? numeral(overallDataItem[XVItem[xField]])
                .format(format)
                ?.toUpperCase()
            : "",
          dataIndex: XVItem[xField],
          key: XVItem[xField],
          render: (value, record, itemIndx) => {
            return (
              <>
                {!value ? " - " : numeral(value).format(format)?.toUpperCase()}
              </>
            );
          },
          // sorter: (a, b) => a[XVItem[xField]] - b[XVItem[xField]],
          // // defaultSortOrder: 'descend',
          // defaultSortOrder: indx == uniqueXV.length - 1 ? 'descend' : '',
        },
      ],
      sorter: (a, b) => a[XVItem[xField]] - b[XVItem[xField]],
      // defaultSortOrder: 'descend',
      defaultSortOrder: indx == uniqueXV.length - 1 ? "descend" : "",
    };

    TableColumns.push(col);
  });

  return (
    <Wrapper>
      {({ width, height }) => (
        <div
          className={"customZSTableHeat-Wrapper"}
          style={{
            padding: "0px 20px 15px",
            width,
            overflowX: "auto",
            height: ModalView ? height : wrapperHeight ? wrapperHeight : height,
            overflowX: "auto",
          }}
        >
          {/* {console.log('heat height', height)} */}
          <ZsTable
            // className={heatTableClass}
            size="small"
            bordered={false}
            showHeader={true}
            pagination={false}
            // // scroll={{ y: 515 }}
            scroll={{ y: ModalView ? height - 15 * 3.5 : customZsTableScroll }} // 15 corresponds to the paddingBottom from the wrapper div style.
            columns={TableColumns}
            dataSource={TableDataSet}
            style={{
              width: "100%",
              zoom: zoom ? zoom : 0.9,
            }}
          />
          {/* Below code is from heat-table component */}

          {/* <table
            width="100%"
            cellspacing={0}
            cellpadding={0}
            style={{
              // fontFamily: "SF Text",
              //fontWeight: 200,
              color: "rgba(255,255,255,0.66)",
              zoom: zoom ? zoom : 0.8,
            }}
          >
            <thead>
              <tr>
                <th
                  style={{ borderRight: "1px solid rgba(255,255,255,0.1)" }}
                ></th>
                {uniqueXValues.map((c, colIndex) => (
                  <th
                    style={{
                      padding: "10px",

                      border: "1px solid rgba(255,255,255,0.1)",
                      borderLeft:
                        colIndex === highlight ? "3px solid #FFF" : "0px",
                      borderRight:
                        colIndex === highlight ? "3px solid #FFF" : undefined,
                      borderTop:
                        colIndex === highlight ? "3px solid #FFF" : undefined,
                      fontSize:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.fontSize
                          ? labelSettings.xAxis.fontSize
                          : 11,
                      textAlign:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.textAlign
                          ? labelSettings.xAxis.textAlign
                          : "left",

                      zoom:
                        labelSettings &&
                        labelSettings.xAxis &&
                        labelSettings.xAxis.zoom,
                    }}
                  >
                    {labelSettings &&
                    labelSettings.xAxis &&
                    labelSettings.xAxis.labelFunct
                      ? labelSettings.xAxis.labelFunct(c, uniqueXToolTipValues[colIndex])
                      : c}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {uniqueYValues.map((r, rowIndex) => (
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid rgba(255,255,255,0.1)",
                      height: 50,
                      fontSize:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.fontSize
                          ? labelSettings.yAxis.fontSize
                          : 11,
                      textAlign:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.textAlign
                          ? labelSettings.yAxis.textAlign
                          : "left",

                      zoom:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.zoom,

                      fontWeight:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.fontWeight,
                      
                      worBreak:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.worBreak,

                      width:
                        labelSettings &&
                        labelSettings.yAxis &&
                        labelSettings.yAxis.tdWidth,

                    }}
                  >
                    {labelSettings &&
                    labelSettings.yAxis &&
                    labelSettings.yAxis.labelFunct
                      ? labelSettings.yAxis.labelFunct(r, uniqueYToolTipValues[rowIndex])
                      : r}
                  </td>

                  {uniqueXValues.map((c, colIndex) => {
                    const accessor = dataset.data.filter(
                      (d) => d[yField] === r && d[xField] === c
                    )[0];
                    if (!accessor || accessor[plotField] === null) {
                      return <td style={{textAlign:'center'}}>-</td>;
                    }

                    const value = accessor ? accessor[plotField] : 0;

                    const weight = (value - minPlot) / (maxPlot - minPlot);
                    const color = pickHex(
                      weight,
                      Color[heatRange.end],
                      Color[heatRange.start]
                    );

                    return (
                      <td
                        style={{
                          background: !accessor
                            ? `rgba(255,255,255,0.01)`
                            : `rgba(${color[0]},${color[1]},${color[2]},0.7)`,
                          padding: "10px",
                          textAlign: "center",
                          fontSize: 13,
                          border: "0px solid rgba(255,255,255,0.1)",
                          borderLeft:
                            colIndex === highlight ? "3px solid #FFF" : "0px",
                          borderRight:
                            colIndex === highlight
                              ? "3px solid #FFF"
                              : undefined,
                          borderBottom:
                            rowIndex == uniqueYValues.length - 1 &&
                            colIndex === highlight
                              ? "3px solid #FFF"
                              : undefined,
                          borderTop: "0px",
                          height: 50,
                        }}
                      >
                        {!accessor ? "-" : numeral(value).format(format)?.toUpperCase()}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      )}
    </Wrapper>
  );
};
