/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { AreaChart, Area, LabelList } from "recharts";
import { Color } from "../../../color-select";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
  Tooltip,
} from "../../components";

const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
};

export default ({ id, dataset, settings }) => {
  const {
    orientation,
    primaryDim,
    primaryLookup,
    legend,
    plot,
    axes,
    zeroReference,
  } = settings;

  let margin = { top: 15, bottom: 15, left: 15, right: 15 };

  if (orientation === "vertical") {
    margin = { top: 15, left: 25, right: 50, bottom: 15 };
  }

  if (axes.primary.show) {
    margin.right = 50;
    margin.bottom = 20;
  }
  return (
    <Wrapper>
      {({ width, height }) => (
        <AreaChart
          width={width}
          height={height}
          layout={orientation}
          data={dataset.data.map((d) => {
            let dOld = d[dataset.fields[primaryDim]];
            d[dataset.fields[primaryDim]] =
              primaryLookup && primaryLookup[dOld] ? primaryLookup[dOld] : dOld;
            return d;
          })}
          margin={margin}
        >
          {Gradients({ chartId: id, orientation })}
          {plot.aggregation === "Overlay" &&
            plot.series.map((series, sIndex) =>
              PrimaryAxis({
                index: sIndex,
                orientation,
                dataKey: dataset.fields[primaryDim],
                show: sIndex === 0,
              })
            )}
          {plot.aggregation !== "Overlay" &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: dataset.fields[primaryDim],
              show: true,
            })}
          {axes.primary &&
            SecondaryAxis({
              index: 0,
              orientation,
              dataKey: dataset.fields[axes.primary.dim],
              show: axes.primary.show,
              squeeze: axes.primary.squeeze,
              format: axes.primary.format,
              equalize: axes.primary.equalize,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 1,
              orientation,
              dataKey: dataset.fields[axes.secondary.dim],
              show: axes.secondary.show,
              squeeze: axes.secondary.squeeze,
              format: axes.secondary.format,
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => (
              <span style={{ color: "rgba(255, 255, 255, 0.66)" }}>
                {plot.series[index].label}
              </span>
            ),
          })}
          {zeroReference && ZeroLine({ orientation })}
          {Tooltip({
            formatter: (value, entry, props) => {
              const dim = dataset.fields.indexOf(props.dataKey);
              if (dim === -1) return value;

              const seriesElem = plot.series.find((s) => s.dim === dim);
              if (!seriesElem) return value;

              return [
                numeral(value).format(seriesElem.format)?.toUpperCase(),
                seriesElem.label,
              ];
            },
          })}
          {orientation === "horizontal" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const xAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const yAxisId = series.axis;
              return (
                <Area
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset.fields[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  stroke={Color[series.color.value]}
                  fill={`url(${Color[series.color.value]}_${id})`}
                  fillOpacity={0.75}
                  type={series.smooth ? "monotone" : "linear"}
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      formatter={(value) =>
                        numeral(value).format(series.format)?.toUpperCase()
                      }
                    />
                  )}
                </Area>
              );
            })}
          {orientation === "vertical" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const yAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const xAxisId = series.axis;
              return (
                <Area
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset.fields[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  stroke={Color[series.color.value]}
                  fill={`url(${Color[series.color.value]}_${id})`}
                  fillOpacity={0.75}
                  type={series.smooth ? "monotone" : null}
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      formatter={(value) =>
                        numeral(value).format(series.format)?.toUpperCase()
                      }
                    />
                  )}
                </Area>
              );
            })}
        </AreaChart>
      )}
    </Wrapper>
  );
};
