/* eslint-disable */
import React from "react";
import numeral from "numeral";
import { BarChart, Bar, LabelList, Cell, Tooltip } from "recharts";
import useCalculatedAxisDim from "../../useCalculatedAxisDim";
import {
  Color as DefaultColor,
  Color_Stack,
  Color_Stack_Bundles,
  ColorStacks,
  Blue,
  Color_segment,
} from "../../../color-select";
import { UserContext } from "@pai/providers/with-auth";
import {
  PrimaryAxis,
  SecondaryAxis,
  Legend,
  Gradients,
  Wrapper,
  ZeroLine,
} from "../../components";
import millify from "millify";
const getColor = (markers, data, colors) => {
  for (var i = 0; i < markers.length; i++) {
    if (data < markers[i]) {
      return colors[i];
    }
  }
  console.log("bar return color", colors[colors.length - 1]);
  return colors[colors.length - 1];
};

export default ({ id, dataset, settings, filterData, onClick }) => {
  const {
    orientation,
    primaryDim,
    toolTipDim,
    primaryLookup,
    legend,
    plot,
    axes,
    zeroReference,
    primaryWidth,
    primaryGap,
    primaryType,
    tickCount,
    barSize: customBarSize,
    colorSelect,
    useDBFormat,
    dbformat,
    tooltip
  } = settings;


  let Color = plot.aggregation === "Stacked" ? Color_Stack : DefaultColor;
  Color = colorSelect ? ColorStacks[colorSelect] : Color;
  // const userState = React.useContext(UserContext);
  const { format } = React.useContext(UserContext);
  const formatArray = format;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.8)",
            padding: "5px 5px 5px 5px",
            borderRadius: 8,
          }}
        >
          <div style={{ fontSize: "12px" }}>
            {`${payload[0]?.payload[toolTipDim ? dataset?.fields[toolTipDim] : dataset?.fields[primaryDim]]}`}
          </div>
          {plot?.series?.map(z => {
            return <div style={{ fontSize: "12px" }}>{`${z.label} : ${numeral(
              payload[0]?.payload[dataset?.fields[z?.dim]]
            ).format(z?.format)?.toUpperCase()}`}</div>
          })}
          {/* SS - Adding the below snippet to show additional metrices in the tooltip */}
          {tooltip?.additionalMetrices?.map(z => {
            return <div style={{ fontSize: "12px" }}>{`${z.DisplayText || dataset?.fields[z.columnIndex]} : ${numeral(
              payload[0]?.payload[dataset?.fields[z?.columnIndex]] //dataset.data[z.columnName] 
            ).format(z?.format)?.toUpperCase()}`}</div>
          })}
        </div>
      );
    }

    return null;
  };

  // console.log("bar chart", filterData, dataset);

  let margin = { top: 15, bottom: 0, left: 15, right: 15 };

  if (orientation === "vertical") {
    margin = { top: 10, left: 25, right: 60, bottom: 20 };
  }

  if (legend.verticalAlign === "bottom") {
    margin.bottom = 15;
  }

  if (legend.additionalPadding) {
    margin.bottom = 40;
  }

  if (axes.primary && axes.primary.show) {
    margin.right = 25;
  }

  //to make synamic series
  if (plot.seriesType && plot.seriesType.excludeColumns) {
    let { hideDot, color, axis, format, labelPosition, hideLabel } =
      plot.seriesType && plot.seriesType.typicalSeries;

    let newSeries = [];
    if (dbformat && dbformat.enabled && dbformat.columnName && dataset?.fields.includes(dbformat.columnName)) {
      var k = dbformat.columnName
      if (dataset?.data.length > 0) {
        format = dataset?.data[0]?.[k] ? formatArray[dataset?.data[0]?.[k]] : format
        if (axes) {
          if (axes.primary) {
            if (axes.primary.format) {
              axes.primary.format = dataset?.data[0]?.[k] ? formatArray[dataset?.data[0]?.[k]] : format;
            }
          }
        }
      }
    }

    let colorCounter = plot.aggregation === "Stacked" ? 0 : 1;

    dataset?.fields?.forEach((x, index) => {
      if (!plot.seriesType.excludeColumns.includes(x)) {
        let defSeries = {
          hideDot: hideDot || false,
          dim: index,
          color: {
            type: (color && color.type) || "constant",
            value: (color && color.value) || colorCounter,
          },
          label:
            (plot.seriesType.alterFieldLabel &&
              plot.seriesType.alterFieldLabel(x)) ||
            x,
          axis: axis || 0,

          format: format || "0.0%",
          labelPosition: labelPosition || "top",
          hideLabel: hideLabel,
        };

        colorCounter++;
        newSeries.push(defSeries);
      }
    });

    plot.series = [...newSeries];
    console.log("aayushnewseries", newSeries)
    if (newSeries && newSeries.length == 1 && plot && plot.aggregation === "Stacked")
      Color = Blue
  }

  const calculatedPrimaryDim = useCalculatedAxisDim(axes.primary.dim, dataset);

  return (
    <Wrapper>
      {({ width, height }) => (
        <BarChart
          width={width}
          height={height}
          layout={orientation}
          data={dataset?.data?.map((d) => {
            let dOld = d[dataset?.fields?.[primaryDim]];
            d[dataset?.fields?.[primaryDim]] =
              primaryLookup && primaryLookup[dOld] ? primaryLookup[dOld] : dOld;
            return d;
          })}
          margin={margin}
          barCategoryGap={primaryGap || "17%"}
        >
          {Gradients({ chartId: id, orientation })}
          {plot.aggregation === "Overlay" &&
            plot.series.map((series, sIndex) =>
              PrimaryAxis({
                index: sIndex,
                orientation,
                dataKey: dataset?.fields?.[primaryDim],
                show: sIndex === 0,
                width: primaryWidth,
                type: primaryType,
                tickCount,
              })
            )}
          {plot.aggregation !== "Overlay" &&
            PrimaryAxis({
              index: 0,
              orientation,
              dataKey: dataset?.fields?.[primaryDim],
              show: true,
              width: primaryWidth,
              type: primaryType,
              tickCount,
            })}
          {axes.primary &&
            SecondaryAxis({
              index: 0,
              equalize: axes.primary.equalize,
              orientation,
              dataKey:
                dataset?.fields?.[
                  plot.aggregation === "Stacked"
                    ? undefined
                    : calculatedPrimaryDim
                ],
              show: axes.primary.show,
              squeeze: axes.primary.squeeze,
              width: axes.width,
              format: axes.primary.format,
            })}
          {axes.secondary &&
            SecondaryAxis({
              index: 1,
              orientation,
              dataKey: dataset?.fields?.[axes.secondary.dim],
              show: axes.secondary.show,
              squeeze: axes.secondary.squeeze,
              width: axes.width,
            })}
          {Legend({
            legendSettings: legend,
            formatter: (value, entry, index) => (
              <span style={{ color: "rgba(255, 255, 255, 0.66)" }}>
                {plot.series[index].label}
              </span>
            ),
          })}
          <Tooltip
            cursor={{ stroke: "#616161", strokeDasharray: "5 5" }}
            content={<CustomTooltip />}
          />
          {zeroReference && ZeroLine({ orientation })}
          {orientation === "horizontal" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const xAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const yAxisId = series.axis;
              const barSize = customBarSize
                ? customBarSize
                : plot.aggregation === "Overlay"
                  ? 40 - sIndex * 10
                  : null;
              return (
                <Bar
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset?.fields?.[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  barSize={barSize}
                  maxBarSize={60}
                  fill={
                    plot.aggregation === "Stacked"
                      ? `${Color[series.color.value]}BF`
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  isAnimationActive={false}
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      valueAccessor={({ payload }) => {
                        //console.log(payload, payload[dataset.fields[series.dim]])
                        return payload[dataset?.fields?.[series.dim]];
                      }}
                      formatter={(value) => {
                        //if(plot.aggregation === "Stacked")
                        //console.log("filterData", value )
                        if (typeof series.format === "function") {
                          return numeral(value).format(
                            series.format(
                              dataset?.fields?.[series.dim],
                              filterData
                            )
                          );
                        }
                        // if(plot.aggregation === "Stacked")
                        //   console.log("filterData", value )

                        return series.format == "$0.0a"
                          ? "$" +
                          millify(
                            plot.aggregation === "Stacked" ? value : value,
                            { precision: 1, lowercase: false }
                          )
                          : numeral(
                            plot.aggregation === "Stacked" ? value : value
                          )
                            .format(series.format)
                            ?.toUpperCase();
                      }}
                    />
                  )}
                  {dataset.data &&
                    dataset.data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        onClick={() => {
                          onClick && onClick(entry);
                        }}
                        fill={
                          series.color.type === "constant"
                            ? plot.aggregation === "Stacked"
                              ? `${Color[series.color.value]}BF`
                              : `url(${Color[series.color.value]}_${id})`
                            : `url(${
                                Color[
                                  getColor(
                                    series.color.value.markers,
                                    entry[dataset?.fields?.[series.dim]],
                                    series.color.value.colors
                                  )
                                ]
                              }_${id})`
                        }
                      />
                    ))}
                </Bar>
              );
            })}
          {orientation === "vertical" &&
            plot &&
            plot.series &&
            plot.series.map((series, sIndex) => {
              const yAxisId = plot.aggregation === "Overlay" ? sIndex : 0;
              const xAxisId = series.axis;
              const barSize = customBarSize
                ? customBarSize
                : plot.aggregation === "Overlay"
                  ? 30 - sIndex * 10
                  : null;
              return (
                <Bar
                  stackId={plot.aggregation === "Stacked" ? `stack-1` : null}
                  dataKey={dataset?.fields?.[series.dim]}
                  xAxisId={xAxisId}
                  yAxisId={yAxisId}
                  barSize={barSize}
                  maxBarSize={60}
                  fill={
                    plot.aggregation === "Stacked"
                      ? `${Color[series.color.value]}BF`
                      : `url(${Color[series.color.value]}_${id})`
                  }
                  isAnimationActive={false}
                >
                  {!series.hideLabel && (
                    <LabelList
                      position={series.labelPosition}
                      valueAccessor={({ payload }) => {
                        return payload[dataset?.fields?.[series.dim]];
                      }}
                      formatter={(value) => {
                        if (typeof series.format === "function") {
                          return numeral(value).format(
                            series.format(
                              dataset?.fields?.[series.dim],
                              filterData
                            )
                          );
                        }
                        return numeral(value)
                          .format(series.format)
                          ?.toUpperCase();
                      }}
                    />
                  )}
                  {dataset.data &&
                    dataset.data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        onClick={() => {
                          onClick && onClick(entry);
                        }}
                        fill={
                          series.color.type === "constant"
                            ? plot.aggregation === "Stacked"
                              ? `${Color[series.color.value]}BF`
                              : `url(${Color[series.color.value]}_${id})`
                            : `url(${
                                Color[
                                  getColor(
                                    series.color.value.markers,
                                    entry[dataset?.fields?.[series.dim]],
                                    series.color.value.colors
                                  )
                                ]
                              }_${id})`
                        }
                      />
                    ))}
                </Bar>
              );
            })}
        </BarChart>
      )}
    </Wrapper>
  );
};
