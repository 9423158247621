import React from "react";
import styled from "styled-components";

const CheckBoxWrapper = styled.div`
  position: relative;
  height: 18px;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 18px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  margin: 0;
  pointer-events: none;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 32px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #15afd0;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 16px;
      transition: 0.2s;
    }
  }
`;

const Toggle = ({ checked, onChange, style }) => {
  return (
    <div style={style}>
      <CheckBoxWrapper>
        <CheckBox
          id="checkbox"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
    </div>
  );
};

export default Toggle;
